(function (angular) {
  'use strict';

  angular.module('farmx-directives', [])
    .service('$farmXApi', ["$rootScope", "$state", "$http", "$cookies", "$interval", "$farmXSensorInfo", "$timeout", "$q", "$log",
      function ($rootScope, $state, $http, $cookies, $interval, $farmXSensorInfo, $timeout, $q, $log) {
        var $baseUrl = "https://map.farmx.co/"; // "http://localhost:8000/"; //
        var $headers = {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        };
        var $headerXHR = {
          withCredentials: true
        };
        var refreshThread = null;

        this.$farmXSensorInfo = $farmXSensorInfo;

        this.getJWTInfo = _getJWTInfo;
        this.validateToken = _validateToken;
        this.login = _login;
        this.logout = _logout;
        this.logoutOldApi = _logoutOldApi;
        this.getEntities = _getEntities;
        this.getState = _getState;
        this.getRanchCapabilities = _getRanchCapabilities;
        this.getBlockState = _getBlockState;
        this.getBlocks = _getBlocks;
        this.getBlockCapabilities = _getBlockCapabilities;
        this.getStations = _getStations;
        this.getStationData = _getStationData;
        this.getSoilSummary = _getSoilSummary;
        this.getSummary = _getSummary;
        this.getETPredictions = _getETPredictions;
        this.getSWPPredictions = _getSWPPredictions;
        this.getChillPortions = _getChillPortions;
        this.getChillHours = _getChillHours;
        this.getFrostForecast = _getFrostForecast;
        this.getWeatherForecast = _getWeatherForecast;
        this.getGDD = _getGDD;
        this.getCumulativePrecip = _getCumulativePrecip;
        this.getVFDStatus = _getVFDStatus;
        this.getVFDState = _getVFDState;
        this.turnOnVFD = _turnOnVFD;
        this.turnOffVFD = _turnOffVFD;
        this.getRanchPrecip = _getRanchPrecip;
        this.getRanchData = _getRanchData;
        this.getBlockData = _getBlockData;
        this.getRanchDataCsvUrl = _getRanchDataCsvUrl;
        this.getBlockDataCsvUrl = _getBlockDataCsvUrl;
        this.getSatelliteDataBlock = _getSatelliteDataBlock;
        this.getSatelliteDataRanch = _getSatelliteDataRanch;
        this.getSatelliteDataDate = _getSatelliteDataDate;
        this.getAvailableSatelliteData = _getAvailableSatelliteData;
        this.getAvailableSatelliteDataV2 = _getAvailableSatelliteDataV2;
        this.getConfig = _getConfig;
        this.setConfig = _setConfig;
        this.deleteConfig = _deleteConfig;
        this.clearConfig = _clearConfig;
        this.getAllConfig = _getAllConfig;
        this.setAllConfig = _setAllConfig;
        this.updateRanchName = _updateRanchName;
        this.updateBlockName = _updateBlockName;
        this.updateSensorName = _updateSensorName;
        this.getFullState = _getFullState;
        this.getBlockSchedule = _getBlockSchedule;
        this.setBlockSchedule = _setBlockSchedule;
        this.updateBlockSchedule = _updateBlockSchedule;
        this.deleteBlockSchedule = _deleteBlockSchedule;
        this.getBlockScheduleStats = _getBlockScheduleStats;
        this.getBlockIrrigationStats = _getBlockIrrigationStats;
        this.getBlockAnomaly = _getBlockAnomaly;
        this.createAnomaly = _createAnomaly;
        this.updateAnomaly = _updateAnomaly;
        this.deleteAnomaly = _deleteAnomaly;
        this.loadBlockAnomalies = _loadBlockAnomalies;
        this.getRecentSoilData = _getRecentSoilData;
        this.getUserProfile = _getUserProfile;
        this.setUserProfile = _setUserProfile;
        this.changePassword = _changePassword;
        this.getBlock3D = _getBlock3D;
        this.getBlock3DData = _getBlock3DData;

        this.getYieldData = _getYieldData;
        this.getYieldDataTable = _getYieldDataTable;

        this.getBlockCurrentWaterStats = _getBlockCurrentWaterStats;
        this.getRanchCurrentWaterStats = _getRanchCurrentWaterStats;
        this.getRanchCurrentFlowStats = _getRanchCurrentFlowStats;
        this.getRanchCumulativeFlowStats = _getRanchCumulativeFlowStats;

        this.getSensorStatus = _getSensorStatus;

        function $call(url, type, params, resultFn, deferred) {
          var token = $cookies.get('auth');

          if (refreshThread === null) {
            refreshThread = $interval(function () {
              _validateToken().then(
                function (data) {
                  // Ignore
                },
                function (error) {
                  $rootScope.$broadcast('farmx.api.unauthorized', false);
                }
              );
            }, 270000);
          }

          if (deferred === undefined)
            deferred = $q.defer();

          if (token !== null && token !== undefined && token !== "") {
            var ajaxConfig = {
              type: type,
              url: url,
              headers: $headers,
              xhrFields: $headerXHR,
              crossDomain: true,
              beforeSend: function (xhr) {   //Include the bearer token in header
                xhr.setRequestHeader("Authorization", 'JWT ' + token);
              }
            };

            if (type === "PUT" || type === "POST" || type === "PATCH") {
              ajaxConfig.data = params;
              ajaxConfig.dataType = "json";
            }

            $.ajax(ajaxConfig).done(function (data) {
              var updatedData = data;

              if (resultFn !== undefined)
                updatedData = resultFn(data);

              deferred.resolve(updatedData);
            }).fail(function (error) {
              if (error.status === 401 && error.responseJSON.detail === "Signature has expired.") {
                _validateToken(token).then(
                  function (success) {
                    $call(args, url, resultFn, deferred);
                  }, function (failed) {
                    deferred.reject(false);
                  }
                );
              } else {
                deferred.reject(error);
              }
            });
          } else {
            $timeout(function () {
              deferred.reject(false);
            });
          }

          return deferred.promise;
        }

        function _validateToken(token) {
          var deferred = $q.defer();

          if (token === undefined)
            token = $cookies.get('authRefresh');

          if (token !== null && token !== undefined && token !== "") {
            var data = JSON.stringify({
              "refresh": token
            });


            $.ajax({
              type: "POST",
              url: $baseUrl + "api/token/refresh/",
              headers: $headers,
              data: data,
              xhrFields: $headerXHR,
              crossDomain: true,
            }).done(function (data) {
              $cookies.put("auth", data.access);
              $cookies.put("authRefresh", data.refresh);
              $rootScope.$broadcast("farmx.app.token.refresh.succeeded", true);
              deferred.resolve(true);
            }).fail(function (error) {
              $rootScope.$broadcast("farmx.app.token.refresh.failed", false);
              deferred.reject(false);
            });
          } else {
            $timeout(function () {
              $rootScope.$broadcast("farmx.app.token.refresh.failed", false);
              deferred.reject(false);
            }, 10);
          }

          return deferred.promise;
        }

        function $unixOffset(date) {
          // var dstOffset = moment("2017-06-01").utcOffset() - moment("2017-01-01").utcOffset();
          var result = date.unix() + (date.utcOffset() * 60);

          // if (date.isUTC() === false) {
          //   result += (dstOffset * 60);
          // }

          return result;
        }

        /* Public methods */
        function _login(username, password) {
          var deferred = $q.defer();
          var data = JSON.stringify({ "username": username, "password": password });

          $.ajax({
            type: "POST",
            url: $baseUrl + "api/token/login/",
            headers: $headers,
            data: data,
            xhrFields: $headerXHR,
            crossDomain: true,
          }).done(function (data) {
            $cookies.put("auth", data.access);
            $cookies.put("authRefresh", data.refresh);

            refreshThread = $interval(function () {
              _validateToken(data.token).then(
                function () { },
                function (error) {
                  $rootScope.$broadcast('farmx.api.unauthorized', false);
                }
              );
            }, 270000);

            deferred.resolve(data);
          }).fail(function (error) {
            deferred.reject(error);
          });

          return deferred.promise;
        }

        function _logoutOldApi() {
          var refreshToken = $cookies.get('authRefresh');
          if (refreshToken === undefined) {
            _logout();
          }
        }

        function _logout() {
          $cookies.remove("auth");

          $interval.cancel(refreshThread);
        }

        function _getJWTInfo() {
          var token = $cookies.get('auth');

          if (token !== undefined && token !== null) {
            return jwt_decode(token);
          }

          return undefined;
        }

        function _getBlockCurrentWaterStats(blockId, date) {
          var url = $baseUrl + "api/block/" + blockId + "/soil/currentwater/";
          if (date) {
            url += "?date=" + moment(date).toISOString();
          }
          return $call(url,
            "GET"
          );
        }

        function _getRanchCurrentWaterStats(ranchId, date) {
          var url = $baseUrl + "api/ranches/" + ranchId + "/soil/currentwater/"
          if (date) {
            url += "?date=" + moment(date).toISOString();
          }
          console.error(url);
          return $call(url,
            "GET"
          );
        }

        function _getRanchCurrentFlowStats(ranchId) {
          return $call($baseUrl + "api/ranches/" + ranchId + "/flow/current/",
            "GET"
          );
        }

        function _getRanchCumulativeFlowStats(ranchId, dateStart, dateEnd) {
          var url = $baseUrl + "api/ranches/" + ranchId + "/flow/cumulative/";
          if (dateStart && dateEnd) {
            url += "?dateStart=" + dateStart.toISOString() + "&dateEnd=" + dateEnd.toISOString();
          }
          return $call(url, "GET");
        }

        function _getBlockScheduleStats(blockId, dateStart, dateEnd) {
          return $call($baseUrl + "api/schedule/block/" + blockId + "/stats/?date_start=" + dateStart + "&date_end=" + dateEnd,
            "GET"
          );
        }

        function _getBlockIrrigationStats(blockId, dateStart, dateEnd) {
          return $call($baseUrl + "api/schedule/block/" + blockId + "/irrigation/stats/", //?date_start=" + dateStart + "&date_end=" + dateEnd,
            "GET"
          );
        }

        function _getBlock3D(blockId) {
          return $call($baseUrl + "api/data/3d/block/" + blockId + "/",
            "GET"
          );
        }

        function _getBlock3DData(blockId, startDate, endDate) {
          return $call($baseUrl + "api/data/3d/?block=" + blockId + "&startDate=" + startDate + "&endDate=" + endDate,
            "GET"
          );
        }

        function _getBlockSchedule(blockId) {
          return $call($baseUrl + "api/schedule/block/" + blockId + "/",
            "GET"
          );
        }

        function _setBlockSchedule(blockId, startTime, endTime) {
          return $call($baseUrl + "api/schedule/irrigation/",
            "POST",
            JSON.stringify({
              start_date: startTime,
              stop_date: endTime,
              block: blockId
            }));
        }

        function _updateBlockSchedule(id, blockId, startTime, endTime) {
          return $call($baseUrl + "api/schedule/irrigation/" + id + "/",
            "PUT",
            JSON.stringify({
              start_date: startTime,
              stop_date: endTime,
              block: blockId
            }));
        }

        function _deleteBlockSchedule(id) {
          return $call($baseUrl + "api/schedule/irrigation/" + id + "/",
            "DELETE"
          );
        }

        function _getBlockAnomaly(blockId) {
          return $call($baseUrl + "api/anomaly/block/" + blockId + "/",
            "GET"
          );
        }

        function _createAnomaly(anomaly) {
          return $call($baseUrl + "api/anomaly/create/",
            "POST",
            JSON.stringify(
              anomaly
            )
          );
        }

        function _updateAnomaly(anomaly) {
          return $call($baseUrl + "api/anomaly/edit/" + anomaly.id + "/",
            "PUT",
            JSON.stringify(anomaly)
          );
        }

        function _deleteAnomaly(anomaly) {
          return $call($baseUrl + "api/anomaly/edit/" + anomaly.id + "/",
            "DELETE"
          );
        }

        function _loadBlockAnomalies(block) {
          $log.log("Loading anomaly data " + block);
          return _getBlockAnomaly(block.id)
            .then(function (data) {
              $log.log("Loaded anomaly data (api) " + data);
              block.yieldData = _getYieldData(block.id);
              block.anomalies = data;
              angular.forEach(block.anomalies, function (anomaly) {
                anomaly.layerGeoJSON = {
                  "type": "Feature",
                  "properties": {
                    "name": anomaly.name,
                    "popupContent": anomaly.name
                  },
                  "geometry": anomaly.bounds
                };
                anomaly.markerGeoJSON = {
                  "type": "Feature",
                  "properties": {
                    "name": anomaly.name,
                    "popupContent": anomaly.name
                  },
                  "geometry": anomaly.center
                };
                anomaly.blockObject = block;
              });
              return data;
            });
        }

        function _getAllConfig() {
          return $call($baseUrl + "api/user/config/full/" + _getJWTInfo().user_id + "/",
            "GET"
          );
        }

        function _setAllConfig(config) {
          return $call($baseUrl + "api/user/config/full/" + _getJWTInfo().user_id + "/",
            "PUT",
            JSON.stringify(config)
          );
        }

        function _getConfig() {
          return $call($baseUrl + "api/user/config/",
            "GET"
          );
        }

        function _getUserProfile() {
          return $call($baseUrl + "api/user/settings",
            "GET"
          );
        }

        function _setUserProfile(profile) {
          return $call($baseUrl + "api/user/settings",
            "PUT",
            JSON.stringify(profile)
          );
        }

        function _changePassword(password) {
          return $call($baseUrl + "api/user/password/change",
            "PUT",
            JSON.stringify(password)
          );
        }

        function _setConfig(config) {
          return $call($baseUrl + "api/user/config/",
            "POST",
            JSON.stringify(config)
          );
        }

        function _clearConfig() {
          return $call($baseUrl + "api/user/config/delete/all/",
            "POST"
          );
        }

        function _deleteConfig(configId) {
          return $call($baseUrl + "api/user/config/delete/" + configId + "/",
            "DELETE"
          );
        }

        function _getEntities() {
          return $call($baseUrl + "api/v2/entities/full/",
            "GET"
          );
        }

        function _getState(ranch) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/state/",
            "GET",
            undefined,
            function (data) {
              ranch.state = data.state;
              ranch.weather = data.weather;

              // Definsive code
              if (ranch.state.state === undefined) {
                ranch.state.state = 0;
              }

              $rootScope.$broadcast("farmx.entitiesCache.internal.update.ranch.state", [ranch.id, ranch.state, ranch.weather]);
            }
          );
        }

        function applyState(data) {
          var filteredData = {};
          data.reduce(function (map, obj) {
            filteredData[obj.id] = obj;
            var ranches = {};
            obj.ranches.reduce(function (ranch_map, ranch) {
              if (ranch.bounds)
                ranches[ranch.id] = ranch;
              var blocks = {};
              ranch.blocks.reduce(function (block_map, block) {
                blocks[block.id] = block;
              }, {});
              ranch.blocks = blocks;
            }, {});
            obj.ranches = ranches;
          }, {});

          _getEntities().then(function (entitiesData) {
            angular.forEach(entitiesData, function (entity, iEntity) {
              var entityData = filteredData[entity.id];

              angular.forEach(entity.ranches, function (ranch, iRanch) {
                if (!ranch.bounds) return;
                var ranchData = entityData.ranches[ranch.id];
                ranch.state = ranchData.state;
                ranch.weather = ranchData.weather;

                // Defensive code
                if (ranch.state.state === undefined) {
                  ranch.state.state = 0;
                }

                // $rootScope.$broadcast("farmx.entitiesCache.internal.update.ranch.state", [ ranch.id, ranch.state, ranch.weather ]);

                angular.forEach(ranch.blocks, function (block, iBlock) {
                  if (!block.bounds) return;
                  var blockData = ranchData.blocks[block.id];
                  block.state = blockData.state;
                  // $rootScope.$broadcast("farmx.entitiesCache.internal.update.block.state", [ block.id, block.state ]);
                });
              });
            });

            $rootScope.$broadcast("farmx.entitiesCache.internal.update.state", entitiesData);
          });
        }

        function _getFullState() {
          return $call($baseUrl + "api/entities/state/",
            "GET",
            undefined
          );
        }

        function _getRanchCapabilities(ranch) {
          return $call($baseUrl + "api/ranch/" + ranch.id + "/capabilities/",
            "GET",
            undefined,
            function (data) {
              if (ranch.capabilities === undefined)
                ranch.capabilities = [];

              ranch.capabilities = angular.copy(data.capabilities);
            }
          );
        }

        function _getBlockState(block) {
          return $call($baseUrl + "api/block/" + block.id + "/state/",
            "GET",
            undefined,
            function (data) {
              block.state = data.state;

              $rootScope.$broadcast("farmx.entitiesCache.internal.update.block.state", [block.id, block.state]);
            }
          );
        }

        function _getBlocks(ranch) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/block/",
            "GET",
            undefined,
            function (data) {
              ranch.blocks = data;
            }
          );
        }

        function _getBlockCapabilities(block) {
          return $call($baseUrl + "api/block/" + block.id + "/capabilities/",
            "GET",
            undefined,
            function (data) {
              if (block.capabilities === undefined)
                block.capabilities = [];

              block.capabilities = angular.copy(data.capabilities);
            }
          );
        }

        function _getStations(block) {
          return $call($baseUrl + "api/block/" + block.id + "/stations/",
            "GET",
            undefined,
            function (data) {
              block.stations = data.stations;
              block.gateways = data.gateways;

              // This is a hack
              angular.forEach(block.stations, function (eStation, iStation) {
                eStation.sensorTypes = Object.keys($farmXSensorInfo.sensorInfo.byVariableType);
              });
            }
          );
        }

        function _getRanchPrecip(ranch) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/precip/");
        }

        var sensor_urls = {
          "gateway": "gateway",
          "boxer_station": "basestation",
          "weather_station": "weatherstation",
          "dendrometer": "dendrometer",
          "water_pressure": "pressure",
          "aquacheck_soil": "aquacheck",
          "farmx_dendrometer": "fxdendrometer",
          "boxer_soil": "basestationsoil",
          "pixl_soil": "pixl",
        };

        function _updateRanchName(ranch) {
          return $call($baseUrl + "api/ranch/name/" + ranch.id + "/",
            "PATCH",
            JSON.stringify({ "name": ranch.name })
          );
        }

        function _updateBlockName(block) {
          return $call($baseUrl + "api/block/name/" + block.id + "/",
            "PATCH",
            JSON.stringify({ "name": block.name })
          );
        }

        function _updateSensorName(sensor) {
          /*$call($baseUrl + "api/ranch/name/" + ranch.id + "/",
            "PATCH"
          );*/
          var url = sensor_urls[sensor.sensor_type];
          if (url === undefined) {
            $log.error("undefined sensor type");
            return;
          }

          url = $baseUrl + "api/sensors/" + url + "/" + sensor.uid + "/name/";
          return $call(url,
            "PATCH",
            JSON.stringify({ "name": sensor.name })
          );
        }

        function _getSatelliteDataBlock(blockId, startDate, endDate, dataType) {
          return $call($baseUrl + "api/raster_block/?block=" + blockId + "&DataType=" + dataType + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate),
            "GET"
          );
        }

        function _getSatelliteDataRanch(ranchId, startDate, endDate, dataType) {
          return $call($baseUrl + "api/raster_block/?ranch=" + ranchId + "&DataType=" + dataType + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate),
            "GET"
          );
        }

        function _getSatelliteDataDate(blockType, blockId, date, dataType) {
          const url = "api/data/satellite/?" + blockType + "=";
          return $call($baseUrl + url + blockId + "&DataType=" + dataType + "&date=" + $unixOffset(date),
            "GET"
          );
        }

        function _getAvailableSatelliteData(blockType, blockId) {
          const url = "api/data/satellite/available/?" + blockType + "=";
          return $call($baseUrl + url + blockId,
            "GET"
          );
        }

        function _getAvailableSatelliteDataV2(blockType, blockId) {
          const url = "api/data/satellite/available/v2/?" + blockType + "=";
          return $call($baseUrl + url + blockId,
            "GET"
          );
        }

        function _getRanchData(ranch, startDate, endDate, sensorType, sensors) {
          var result;

          if (sensors != null && sensors.length > 0) {
            var sensorString = "";
            var ctr = 0;

            sensors.forEach(function (sensor) {
              if (ctr > 0) {
                sensorString += ",";
              }

              sensorString += sensor.sensor_type + ":" + sensor.uid;
              ctr += 1;
            });

            result = $call($baseUrl + "api/data/ranch/?ranch=" + ranch.id + "&variable=" + sensorType.id + "&sensors=" + sensorString + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate), "GET");
          } else {
            result = $call($baseUrl + "api/data/ranch/?ranch=" + ranch.id + "&variable=" + sensorType.id + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate), "GET");
          }

          return result;
        }

        function _getBlockData(block, startDate, endDate, sensorType, sensors) {
          var result;

          if (sensors != null && sensors.length > 0) {
            var sensorString = "";
            var ctr = 0;

            sensors.forEach(function (sensor) {
              if (ctr > 0) {
                sensorString += ",";
              }

              sensorString += sensor.sensor_type + ":" + sensor.uid;
              ctr += 1;
            });

            result = $call($baseUrl + "api/data/block/?block=" + block.id + "&variable=" + sensorType.id + "&sensors=" + sensorString + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate), "GET");
          } else {
            result = $call($baseUrl + "api/data/block/?block=" + block.id + "&variable=" + sensorType.id + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate), "GET");
          }

          return result;
        }

        function _getBlockDataCsvUrl(block, startDate, endDate, variables) {
          var variablesStr = variables.map(function (obj) {
            return obj.id;
          }).join(',');

          return $baseUrl + "api/data/block/csv/?block=" + block.id + "&variables=" + variablesStr + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate);
        }

        function _getRanchDataCsvUrl(ranch, startDate, endDate, variables) {
          var variablesStr = variables.map(function (obj) {
            return obj.id;
          }).join(',');

          return $baseUrl + "api/data/ranch/csv/?ranch=" + ranch.id + "&variables=" + variablesStr + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate);
        }

        function _getStationData(station, startDate, endDate, sensorType) {
          return $call($baseUrl + "api/stations/graphdata/?variables=" + sensorType.id + "&startDate=" + $unixOffset(startDate) + "&endDate=" + $unixOffset(endDate) + "&stations=" + station.id,
            "GET"
          );
        }

        function _getSoilSummary(ranch) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/soilsummary/",
            "GET"
          );
        }

        function _getSummary(ranch) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/summary/",
            "GET"
          );
        }

        function _getETPredictions(ranch, deferred) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/etpredictions/",
            "GET"
          );
        }

        function _getSWPPredictions(ranch, deferred) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/swppredictions/",
            "GET"
          );
        }

        function _getChillPortions(ranch, deferred) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/chillportions/",
            "GET"
          );
        }

        function _getChillHours(ranch, deferred) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/chillhours/",
            "GET"
          );
        }

        function _getFrostForecast(ranch, deferred) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/frost/forecast/",
            "GET"
          );
        }

        function _getWeatherForecast(ranch, deferred) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/temp/forecast/",
            "GET"
          );
        }

        function _getGDD(ranch, lower, upper) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/gdd/?lower=" + lower + "&upper=" + upper,
            "GET"
          );
        }

        function _getCumulativePrecip(ranch, start, end) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/precip/cumulative/?start=" + start + "&end=" + end,
            "GET"
          );
        }

        function _getVFDStatus(ranch) {
          return $call($baseUrl + "api/ranches/" + ranch.id + "/vfd/",
            "GET"
          );
        }

        function _getVFDState(vfdId) {
          return $call($baseUrl + "api/sensors/vfd/" + vfdId + "/state/",
            "GET"
          );
        }

        function _turnOnVFD(vfdId, startDate, endDate) {
          var data = JSON.stringify({
            "vfd": vfdId,
            "start_date": startDate,
            "end_date": endDate
          });
          return $call($baseUrl + "api/sensors/vfd/on/",
            "POST",
            data
          );
        }

        function _turnOffVFD(vfdId) {
          var data = JSON.stringify({ "vfd": vfdId });
          return $call($baseUrl + "api/sensors/vfd/off/",
            "POST",
            data
          );
        }

        function _getRecentSoilData(sensorId) {
          return $call($baseUrl + "api/data/soil/recent/" + sensorId + "/gridded/",
            "GET"
          );
        }

        function _getSensorStatus(sensorType, sensorIdentifier) {
          const url = `api/sensor/status/?sensorType=${sensorType}&sensorIdentifier=${sensorIdentifier}`;
          return $call($baseUrl + url);
        }

        function _getYieldData(blockId) {
          let yieldData = {
            2016: {
              "May": [7, 16],
              "June": [8.5, 14.3],
              "July": [8.6, 13],
              "August": [9, 12.8],
              "September": [9, 11.5],
              "October": [8.9, 11.3],
              "Actual": 10.3
            },
            2017: {
              "May": [4, 8],
              "June": [4.2, 7.3],
              "July": [4.1, 6.8],
              "August": [3.8, 5.6],
              "September": [3.9, 5.2],
              "October": [3.8, 5.1],
              "Actual": 4.9
            },
            2018: {
              "May": [12, 18],
              "June": [13.2, 16.7],
              "July": [13.6, 16.4],
              "August": [13.8, 16.0],
              "September": [13.8, 15.9],
              "October": [null, "September Harvest"],
              "Actual": 15.8
            },
            2019: {
              "May": [11, 14],
              "June": [12.5, 13.5],
              "July": [12.6, 13.4],
              "August": [12.8, 13.3],
              "September": [12.8, 13.2],
              "October": null,
              "Actual": null
            },
          };
          if (blockId == 736199) {
            return yieldData;
          }
          return null;
        }

        function _getYieldDataTable(ranchId) {
          let yieldData = [
            {
              "year": 2016,
              "actualYield": 10.3,
              "data": [
                {
                  "month": "May",
                  "NDVI": 0.73,
                  "NDWI": 0.48,
                  "airTemp": 78,
                  "predictedYield": "7 - 16"
                },
                {
                  "month": "June",
                  "NDVI": 0.71,
                  "NDWI": 0.43,
                  "airTemp": 89,
                  "predictedYield": "8.5 – 14.3"
                },
                {
                  "month": "July",
                  "NDVI": 0.69,
                  "NDWI": 0.4,
                  "airTemp": 85,
                  "predictedYield": "8.6 - 13"
                },
                {
                  "month": "August",
                  "NDVI": 0.63,
                  "NDWI": 0.42,
                  "airTemp": 82,
                  "predictedYield": "9 – 12.8"
                },
                {
                  "month": "September",
                  "NDVI": 0.58,
                  "NDWI": 0.39,
                  "airTemp": 81,
                  "predictedYield": "9 – 11.5"
                },
                {
                  "month": "October",
                  "NDVI": 0.54,
                  "NDWI": 0.35,
                  "airTemp": 69,
                  "predictedYield": "8.9 – 11.3"
                }
              ]
            },
            {
              "year": 2017,
              "actualYield": 4.9,
              "data": [
                {
                  "month": "May",
                  "NDVI": 0.65,
                  "NDWI": 0.35,
                  "airTemp": 81,
                  "predictedYield": "4 - 8"
                },
                {
                  "month": "June",
                  "NDVI": 0.63,
                  "NDWI": 0.36,
                  "airTemp": 90,
                  "predictedYield": "4.2 – 7.3"
                },
                {
                  "month": "July",
                  "NDVI": 0.58,
                  "NDWI": 0.34,
                  "airTemp": 90,
                  "predictedYield": "4.1 – 6.8"
                },
                {
                  "month": "August",
                  "NDVI": 0.55,
                  "NDWI": 0.33,
                  "airTemp": 87,
                  "predictedYield": "3.8 – 5.6"
                },
                {
                  "month": "September",
                  "NDVI": 0.43,
                  "NDWI": 0.32,
                  "airTemp": 86,
                  "predictedYield": "3.9 – 5.2"
                },
                {
                  "month": "October",
                  "NDVI": 0.38,
                  "NDWI": 0.31,
                  "airTemp": 69,
                  "predictedYield": "3.8 – 5.1"
                }
              ]
            },
            {
              "year": 2018,
              "actualYield": 15.8,
              "data": [
                {
                  "month": "May",
                  "NDVI": 0.77,
                  "NDWI": 0.45,
                  "airTemp": 81,
                  "predictedYield": "12 - 18"
                },
                {
                  "month": "June",
                  "NDVI": 0.72,
                  "NDWI": 0.44,
                  "airTemp": 88,
                  "predictedYield": "13.2 – 16.7"
                },
                {
                  "month": "July",
                  "NDVI": 0.7,
                  "NDWI": 0.41,
                  "airTemp": 88,
                  "predictedYield": "13.6 – 16.4"
                },
                {
                  "month": "August",
                  "NDVI": 0.65,
                  "NDWI": 0.43,
                  "airTemp": 87,
                  "predictedYield": "13.8 – 16.0"
                },
                {
                  "month": "September",
                  "NDVI": 0.62,
                  "NDWI": 0.38,
                  "airTemp": 82,
                  "predictedYield": "13.8 – 15.9"
                },
                {
                  "month": "October",
                  "noData": true,
                  "message": "September Harvest"
                }
              ]
            },
            {
              "year": 2019,
              "actualYield": null,
              "data": [
                {
                  "month": "May",
                  "NDVI": 0.45,
                  "NDWI": 0.23,
                  "airTemp": 57,
                  "predictedYield": "12.6"
                },
                {
                  "month": "June",
                  "NDVI": 0.48,
                  "NDWI": 0.19,
                  "airTemp": 63,
                  "predictedYield": "12.9"
                },
                {
                  "month": "July",
                  "NDVI": 0.63,
                  "NDWI": 0.26,
                  "airTemp": 74,
                  "predictedYield": "13.1"
                },
                {
                  "month": "August",
                  "NDVI": 0.61,
                  "NDWI": 0.43,
                  "airTemp": 87,
                  "predictedYield": "13.0"
                },
                {
                  "month": "September",
                  "NDVI": 0.59,
                  "NDWI": 0.38,
                  "airTemp": 82,
                  "predictedYield": "12.9"
                },
                {
                  "month": "October",
                  "noData": true,
                  "message": ""
                }
              ]
            },
          ];
          if (ranchId == 73646) return {
            block: "Block 2",
            yieldData: yieldData,
          };
          return null;
        }

      }])
    .service('$farmXEntitiesCache', ["$rootScope", "$farmXApi", "$timeout", "$q", "$log", function ($rootScope, $farmXApi, $timeout, $q, $log) {
      var entities;
      var filteredEntities = [];
      var searchField;
      var sortedByState = false;
      var sortedByName = false;

      function $filterEntities() {
        var tempResult = [];

        var ranchOpen = {};
        angular.forEach(filteredEntities, function (eEntity, iEntity) {
          var ranches = eEntity.ranches;
          angular.forEach(ranches, function (eRanch, iRanch) {
            ranchOpen[eRanch.id] = eRanch.opened;
          });
        });

        if (searchField === undefined || searchField === null || searchField === "") {
          tempResult = angular.copy(entities);
        } else {
          var result = [];

          angular.forEach(entities, function (eEntity, iEntity) {
            var filtered = eEntity.ranches.filter(function (eRanch) {
              return (eRanch.name.toLowerCase().indexOf(searchField.toLowerCase()) > -1);
            });

            if (filtered.length > 0) {
              var addEntity = angular.copy(eEntity);
              addEntity.ranches = filtered;

              result.push(addEntity);
            }
          });

          tempResult = result;
        }

        angular.forEach(tempResult, function (eEntity, iEntity) {
          var ranches = eEntity.ranches;
          angular.forEach(ranches, function (eRanch, iRanch) {
            if (ranchOpen[eRanch.id] !== undefined) {
              eRanch.opened = ranchOpen[eRanch.id];
            }
          });
        });

        // Sort by state
        if (sortedByState) {
          angular.forEach(tempResult, function (eEntity, iEntity) {
            var ranches = eEntity.ranches.sort(function (eRanch1, eRanch2) {
              return eRanch1.state && eRanch1.state.state - eRanch2.state && eRanch2.state.state;
            });

            angular.forEach(ranches, function (eRanch, iRanch) {
              var blocks = eRanch.blocks.sort(function (eBlock1, eBlock2) {
                return eBlock1.state && eBlock1.state.state - eBlock2.state && eBlock2.state.state;
              });
              eRanch.blocks = blocks;
            });

            eEntity.ranches = ranches;
          });
        }

        // Sort by name
        if (sortedByName) {
          // Entity sort
          tempResult.sort(function (eEntity1, eEntity2) {
            return eEntity1.name.localeCompare(eEntity2.name, undefined, { numeric: true, sensitivity: 'base' });
          });

          angular.forEach(tempResult, function (eEntity, iEntity) {
            var ranches = eEntity.ranches.sort(function (eRanch1, eRanch2) {
              return eRanch1.name.localeCompare(eRanch2.name, undefined, { numeric: true, sensitivity: 'base' });
            });

            angular.forEach(ranches, function (eRanch, iRanch) {
              var blocks = eRanch.blocks.sort(function (eBlock1, eBlock2) {
                return eBlock1.name.localeCompare(eBlock2.name, undefined, { numeric: true, sensitivity: 'base' });
              });
              eRanch.blocks = blocks;
            });

            eEntity.ranches = ranches;
          });
        }

        filteredEntities = tempResult;

        $rootScope.$broadcast("farmx.entitiesCache.updated", filteredEntities);
      }

      // Function to prepare only the fullState object
      function $prepFullStateData(data) {
        var filteredData = {};
        var sensorStateData = {};
        var fullStateObj = {};
        data.reduce(function (map, obj) {
          filteredData[obj.id] = obj;
          var ranches = {};
          obj.ranches.reduce(function (ranch_map, ranch) {
            ranches[ranch.id] = ranch;
            var blocks = {};
            ranch.blocks.reduce(function (block_map, block) {
              blocks[block.id] = block;
              if (block.state && block.state.details && block.state.details.all_data) {
                block.state.details.all_data.reduce(function (sensor_map, sensor) {
                  sensorStateData[sensor.sensor] = sensor;
                }, {});
              }
            }, {});
            ranch.blocks = blocks;
          }, {});
          obj.ranches = ranches;
        }, {});
        fullStateObj['filteredData'] = filteredData;
        fullStateObj['sensorStateData'] = sensorStateData;
        return fullStateObj;
      }

      function $applyState(data) {
        var filteredData = data.filteredData;
        var sensorStateData = data.sensorStateData;

        if (!filteredData) return;

        angular.forEach(filteredEntities, function (entity, iEntity) {
          var entityData = filteredData[entity.id];
          if (!entityData) return;
          angular.forEach(entity.ranches, function (ranch, iRanch) {
            var ranchData = entityData.ranches[ranch.id];
            if (!ranchData) return;

            ranch.state = ranchData.state;
            ranch.weather = ranchData.weather;

            // Defensive code
            if (ranch.state.state === undefined) {
              ranch.state.state = 0;
            }

            angular.forEach(ranch.blocks, function (block, iBlock) {
              var blockData = ranchData.blocks[block.id];

              if (blockData !== undefined) {
                block.state = blockData.state;
              }

              angular.forEach(block.sensors, function (sensor, iSensor) {
                sensor.state = sensorStateData[sensor.identifier];
              });
            });
          });
        });
      }

      this.clearCache = function () {
        entities = undefined;
        filteredEntities = [];
        searchField = undefined;
        sortedByState = false;
        sortedByName = false;
      };

      this.getPersistedEntities = function () {
        // This code is still under development
        // Need to be tested in proper ubuntu machine
        entities = JSON.parse(localStorage.getItem('entities'));
        if (entities) {
          // These functions have to be reinserted as
          // JSON encoding and decoding will remove them
          var ranchMap = {};
          var blockMap = {};
          angular.forEach(entities, function (eEntity, iEntity) {
            angular.forEach(eEntity.ranches, function (eRanch, iRanch) {
              if (!eRanch.bounds) return;
              ranchMap[eRanch.id] = eRanch;
              angular.forEach(eRanch.blocks, function (eBlock, iBlock) {
                if (!eBlock.bounds) return;
                blockMap[eBlock.id] = eBlock;
                angular.forEach(eBlock.gateways, function (eGateway, iGateway) {
                  eGateway.getParent = function () { return eBlock; };
                  eBlock.getParent = function () { return eRanch; };
                  eBlock.getFirstChild = function () { return eBlock.stations[0]; };
                  eRanch.getParent = function () { return eEntity; };
                  eRanch.getFirstChild = function () { return eRanch.blocks[0]; };
                });

                angular.forEach(eBlock.stations, function (eStation, iStation) {
                  eStation.getParent = function () { return eBlock; };
                  eBlock.getParent = function () { return eRanch; };
                  eBlock.getFirstChild = function () { return eBlock.stations[0]; };
                  eRanch.getParent = function () { return eEntity; };
                  eRanch.getFirstChild = function () { return eRanch.blocks[0]; };
                });

                angular.forEach(eBlock.sensors, function (eSensor, iSensor) {
                  eSensor.getParent = function () { return eBlock; };
                  eBlock.getParent = function () { return eRanch; };
                  eBlock.getFirstChild = function () { return eBlock.sensors[0]; };
                  eRanch.getParent = function () { return eEntity; };
                  eRanch.getFirstChild = function () { return eRanch.blocks[0]; };
                });
              });
            });
          });

          $filterEntities();
          let fullState = localStorage.getItem('fullState');
          if (fullState) {
            $applyState(JSON.parse(fullState));
            $rootScope.$broadcast("farmx.entitiesCache.internal.update.state", filteredEntities);
            $rootScope.$broadcast("farmx.ranches.update", ranchMap);
            $rootScope.$broadcast("farmx.blocks.update", blockMap);
          };
        }

      };


      this.cacheInitialize = function () {
        $farmXApi.getEntities().then(function (data) {
          entities = data;
          var ranchMap = {};
          var blockMap = {};

          angular.forEach(entities, function (eEntity, iEntity) {
            angular.forEach(eEntity.ranches, function (eRanch, iRanch) {
              if (!eRanch.bounds) return;

              ranchMap[eRanch.id] = eRanch;
              eRanch.schedulingEnabled = false;
              eRanch.schedulingTableEnabled = false;
              eRanch.soilTableEnabled = false;
              eRanch.opened = false;
              eRanch.layerGeoJSON = {
                "type": "Feature",
                "properties": {
                  "name": eRanch.name,
                  "popupContent": eRanch.name
                },
                "geometry": eRanch.bounds
              };

              let markerLatLng = null;
              if (eRanch.bounds && eRanch.bounds.coordinates) {
                markerLatLng = L.latLngBounds(L.GeoJSON.coordsToLatLngs(eRanch.bounds.coordinates, 1)).getCenter()
              }

              eRanch.markerGeoJSON = {
                "type": "Feature",
                "properties": {
                  "name": eRanch.name,
                  "popupContent": eRanch.name
                },
                "geometry": {
                  "type": "Point",
                  "latLng": markerLatLng
                }
              };

              eRanch.state = { state: 0 };

              angular.forEach(eRanch.blocks, function (eBlock, iBlock) {
                if (!eBlock.bounds) return;

                blockMap[eBlock.id] = eBlock;
                eBlock.opened = false;
                if (eBlock.scheduling_enabled) eRanch.schedulingEnabled = true;
                if (eBlock.scheduling_table_enabled) eRanch.schedulingTableEnabled = true;
                eBlock.soilTableEnabled = false;

                eBlock.layerGeoJSON = {
                  "type": "Feature",
                  "properties": {
                    "name": eBlock.name,
                    "popupContent": eBlock.name
                  },
                  "geometry": eBlock.bounds
                };

                eBlock.markerGeoJSON = {
                  "type": "Feature",
                  "properties": {
                    "name": eBlock.name,
                    "popupContent": eBlock.name
                  },
                  "geometry": {
                    "type": "Point",
                    "latLng": L.latLngBounds(L.GeoJSON.coordsToLatLngs(eBlock.bounds.coordinates, 1)).getCenter()
                  }
                };

                eBlock.state = { state: 0 };

                const visibleGateways = [];
                angular.forEach(eBlock.gateways, function (eGateway, iGateway) {
                  if (eGateway.visible) {
                    visibleSensors.push(eGateway);
                  } else {
                    return;
                  }
                  eGateway.getParent = function () { return eBlock; };
                  eBlock.getParent = function () { return eRanch; };
                  eBlock.getFirstChild = function () { return eBlock.stations[0]; };
                  eRanch.getParent = function () { return eEntity; };
                  eRanch.getFirstChild = function () { return eRanch.blocks[0]; };

                  eGateway.markerGeoJSON = {
                    "type": "Feature",
                    "properties": {
                      "name": eGateway.identifier,
                      "popupContent": eGateway.identifier
                    },
                    "geometry": {
                      "type": "Point",
                      "latLng": new L.LatLng(eGateway.location.coordinates[1], eGateway.location.coordinates[0])
                    }
                  };
                });
                eBlock.gateways = visibleGateways;

                angular.forEach(eBlock.stations, function (eStation, iStation) {
                  eStation.getParent = function () { return eBlock; };
                  eBlock.getParent = function () { return eRanch; };
                  eBlock.getFirstChild = function () { return eBlock.stations[0]; };
                  eRanch.getParent = function () { return eEntity; };
                  eRanch.getFirstChild = function () { return eRanch.blocks[0]; };

                  eStation.markerGeoJSON = {
                    "type": "Feature",
                    "properties": {
                      "name": eStation.name,
                      "popupContent": eStation.name
                    },
                    "geometry": {
                      "type": "Point",
                      "latLng": new L.LatLng(eStation.location.coordinates[1], eStation.location.coordinates[0])
                    }
                  };
                });

                const visibleSensors = [];
                angular.forEach(eBlock.sensors, function (eSensor, iSensor) {
                  if (eSensor.visible) {
                    visibleSensors.push(eSensor);
                  } else {
                    return;
                  }
                  eSensor.getParent = function () { return eBlock; };
                  eBlock.getParent = function () { return eRanch; };
                  eBlock.getFirstChild = function () { return eBlock.sensors[0]; };
                  eRanch.getParent = function () { return eEntity; };
                  eRanch.getFirstChild = function () { return eRanch.blocks[0]; };
                  eSensor.markerGeoJSON = {
                    "type": "Feature",
                    "properties": {
                      "name": eSensor.node_id,
                      "popupContent": eSensor.node_id
                    },
                    "geometry": {
                      "type": "Point",
                      "latLng": new L.LatLng(eSensor.latitude, eSensor.longitude)
                    }
                  };

                  if (eSensor.sensor_type === "boxer_station") {
                    eSensor.data_category = "boxer";
                    eSensor.category = {
                      id: "Boxer",
                      name: "Boxer"
                    };
                  } else {
                    eSensor.category = {
                      id: eSensor.data_category.replace(/^\w/, c => c.toUpperCase()),
                      name: eSensor.data_category.replace(/^\w/, c => c.toUpperCase())
                    };
                  }

                  if (eSensor.sensor_type === "aquacheck_soil") {
                    eRanch.soilTableEnabled = true;
                    eBlock.soilTableEnabled = true;
                  }

                  if (eSensor.category.name === "Flow") {
                    eSensor.category.id = "Water";
                    eSensor.category.name = "Water";
                  }
                });
                eBlock.sensors = visibleSensors;
              });
            });
          });

          // This code is still under development
          // Need to be tested in proper ubuntu machine
          localStorage.setItem('entities', JSON.stringify(entities));
          $rootScope.$broadcast("farmx.ranches.update", ranchMap);
          $rootScope.$broadcast("farmx.blocks.update", blockMap);
          $rootScope.$broadcast('farmx.urlparams.read');

          $filterEntities();
          $farmXApi.getFullState().then(function (data) {
            var processedFullState = $prepFullStateData(data);
            localStorage.setItem('fullState', JSON.stringify(processedFullState));
            $rootScope.$broadcast("farmx.entitiesCache.fullstate", processedFullState);
            $applyState(processedFullState);
            $rootScope.$broadcast("farmx.entitiesCache.internal.update.state", filteredEntities);
          });
        }, function (error) {
          entities = undefined;
          $rootScope.$broadcast("farmx.api.unauthorized", error);
        });
      };

      this.setEntitiesSearchFilter = function (newValue) {
        searchField = newValue;
        if (entities !== undefined) {
          $filterEntities();
          $rootScope.$broadcast("farmx.entitiesCache.internal.update.state", filteredEntities);
        }
      };

      this.sortEntitiesByState = function (newValue, fullState) {
        sortedByState = newValue;
        if (entities !== undefined) {
          $filterEntities();
          if (Object.keys(fullState).length) {
            $applyState(fullState);
          }
          $rootScope.$broadcast("farmx.entitiesCache.internal.update.state", filteredEntities);
        }
      };

      this.sortEntitiesByName = function (newValue, fullState) {
        sortedByName = newValue;
        if (entities !== undefined) {
          $filterEntities();
          if (Object.keys(fullState).length) {
            $applyState(fullState);
          }
          $rootScope.$broadcast("farmx.entitiesCache.internal.update.state", filteredEntities);
        }
      };

      this.getEntities = function () {
        let fullState = localStorage.getItem('fullState');
        if (fullState) {
          $applyState(JSON.parse(fullState));
        }
        return filteredEntities;
      };

      this.findRanchById = function (id) {
        var result = null;
        var match = (typeof id === "string" ? parseInt(id) : id)

        angular.forEach(filteredEntities, function (eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function (eRanch, iRanch) {
            if (eRanch.id === match) {
              result = eRanch;
            }
          });
        });

        return result;
      };

      this.findBlockById = function (id) {
        var result = null;
        var match = (typeof id === "string" ? parseInt(id) : id)

        angular.forEach(filteredEntities, function (eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function (eRanch, iRanch) {
            angular.forEach(eRanch.blocks, function (eBlock, iBlock) {
              if (eBlock.id === match) {
                result = eBlock;
              }
            });
          });
        });

        return result;
      };

      this.findSensorByCategoryAndId = function (sensorType, sensorId) {
        var result = null;
        var match = (typeof sensorId === "string" ? parseInt(sensorId) : sensorId)

        angular.forEach(filteredEntities, function (eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function (eRanch, iRanch) {
            angular.forEach(eRanch.blocks, function (eBlock, iBlock) {
              angular.forEach(eBlock.sensors, function (eSensor, iSensor) {
                if (eSensor.sensor_type === sensorType && eSensor.uid === match) {
                  result = eSensor;
                }
              });
            });
          });
        });

        return result;
      };

      this.findSensor = function (sensorType, sensorIdentifier) {
        var result = null;

        angular.forEach(filteredEntities, function (eEntity, iEntity) {
          angular.forEach(eEntity.ranches, function (eRanch, iRanch) {
            angular.forEach(eRanch.blocks, function (eBlock, iBlock) {
              angular.forEach(eBlock.sensors, function (eSensor, iSensor) {
                if (eSensor.sensor_type === sensorType && eSensor.identifier === sensorIdentifier) {
                  result = eSensor;
                }
              });
            });
          });
        });

        return result;
      };

      this.fillSelected = function (selected) {
        if (selected.type === "Ranch") {
          selected.value[0] = this.findRanchById(selected.value[0]);
        } else if (selected.type === "Block") {
          selected.value[0] = this.findRanchById(selected.value[0]);
          selected.value[1] = this.findBlockById(selected.value[1]);
        }
      };
    }])
    .service('$farmXSensorInfo', ["$timeout", "$q", "$log", function ($timeout, $q, $log) {
      this.sensorInfo = {
        "byUnits": {
          "°F": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "height": 200,
                "type": "line"
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "°F"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "%": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "height": 200,
                "type": "line"
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "%"
                },
                "min": 0.25,
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "percent": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "height": 200,
                "type": "line"
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "%"
                },
                "min": 0.25,
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "bar": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "bar"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "in/day": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "in/day"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "mm": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "mm"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "mph": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "mph"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "°": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "°"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "in": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "in"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "V": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "V"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "portions": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "portions"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "hours": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "hours"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "psi": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "psi"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "mV": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "mV"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "W/m": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "hours"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "kwh": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "hours"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "cf/m": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "hours"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "gal": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "hours"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "#": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "hours"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          },
          "": {
            "chartConfig": {
              "chart": {
                "zoomType": null,
                "spacingBottom": 0,
                "spacingTop": 10,
                "spacingLeft": 0,
                "spacingRight": 1,
                "marginBottom": 10,
                "marginLeft": 15,
                "marginRight": 150,
                "borderColor": "#FFFFFF",
                "borderWidth": 0,
                "loading": true,
                "plotBorderWidth": 0,
                "type": "line",
                "height": 200
              },
              "legend": {
                "align": "right",
                "verticalAlign": "top",
                "layout": "vertical",
                "y": 20
              },
              "rangeSelector": {
                "enabled": false
              },
              "scrollbar": {
                "enabled": false
              },
              "credits": {
                "enabled": false
              },
              "xAxis": {
                "visible": true,
                "tickInterval": 24 * 3600 * 1000,
                "type": "datetime",
                "plotBands": []
              },
              "loading": {
                "hideDuration": 100,
                "showDuration": 100,
                "labelStyle": {
                  "fontWeight": "bold",
                  "position": "relative",
                  "top": "45%"
                },
                "style": {
                  "position": "absolute",
                  "backgroundColor": "#ffffff",
                  "opacity": 0.5,
                  "textAlign": "center"
                }
              },
              "title": {
                "text": ""
              },
              "useHighStocks": true,
              "labels": {
                "items": {
                  "html": null,
                  "style": null
                },
                "style": "background-color:green;"
              },
              "series": [],
              "yAxis": {
                "title": {
                  "text": "hours"
                },
                "minorGridLineWidth": 0,
                "gridLineWidth": 0,
                "alternateGridColor": null
              }
            },
            "sensorTypes": [
            ]
          }
        },
        "byVariableType": {
          "air_humidity": { "id": "air_humidity", "sensor_type": "weather_station", "title": "Air Humidity", "units": "%", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 1, "className": "graph-filter-series-color-default" },
          "air_temp": { "id": "air_temp", "sensor_type": "weather_station", "title": "Air Temperature", "units": "°F", "min": 0, "max": 212, "category": "Weather", "$sortIndex": 2, "className": "graph-filter-series-color-default" },
          "chill_hours_45": { "id": "chill_hours_45", "sensor_type": "weather_station", "title": "Chill Hours Below 45", "units": "hours", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 3, "className": "graph-filter-series-color-default" },
          "chill_hours_between": { "id": "chill_hours_between", "sensor_type": "weather_station", "title": "Chill Hours between 32 and 45", "units": "hours", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 4, "className": "graph-filter-series-color-default" },
          "chill_portions": { "id": "chill_portions", "sensor_type": "weather_station", "title": "Chill Portions", "units": "portions", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 5, "className": "graph-filter-series-color-default" },
          "dew_point": { "id": "dew_point", "sensor_type": "weather_station", "title": "Dew Point", "units": "°F", "min": 0, "max": 212, "category": "Weather", "$sortIndex": 6, "className": "graph-filter-series-color-default" },
          "et0": { "id": "et0", "sensor_type": "weather_station", "title": "Reference ET", "units": "in/day", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 7, "className": "graph-filter-series-color-default" },
          "precip": { "id": "precip", "sensor_type": "weather_station", "title": "Precipitation", "units": "in", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 8, "className": "graph-filter-series-color-default" },
          "rel_humidity": { "id": "rel_humidity", "sensor_type": "weather_station", "title": "Relative Humidity", "units": "%", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 9, "className": "graph-filter-series-color-default" },
          "solar_radiation": { "id": "solar_radiation", "sensor_type": "weather_station", "title": "Solar Radiation", "units": "W/m2", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 10, "className": "graph-filter-series-color-default" },
          "wind_dir": { "id": "wind_dir", "sensor_type": "weather_station", "title": "Wind Direction", "units": "°", "min": 0, "max": 100, "category": "Weather", "$sortIndex": 11, "className": "graph-filter-series-color-default" },
          "wind_gust": { "id": "wind_gust", "sensor_type": "weather_station", "title": "Wind Gust Speed", "units": "mph", "min": 0, "max": 200, "category": "Weather", "$sortIndex": 12, "className": "graph-filter-series-color-default" },
          "wind_speed": { "id": "wind_speed", "sensor_type": "weather_station", "title": "Wind Speed", "units": "mph", "min": 0, "max": 200, "category": "Weather", "$sortIndex": 13, "className": "graph-filter-series-color-default" },

          "soil_moisture_rootzone_vwc": { "id": "soil_moisture_rootzone_vwc", "sensor_type": "aquacheck_soil", "title": "Rootzone VWC", "units": "percent", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 1, "$grouped": "Rootzone Moisture", "className": "graph-filter-series-color-soil-moisture-rootzone" },
          "soil_moisture_rootzone_volume": { "id": "soil_moisture_rootzone_volume", "sensor_type": "aquacheck_soil", "title": "Rootzone Water", "units": "in", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 2, "$grouped": "Rootzone Moisture", "className": "graph-filter-series-color-soil-moisture-rootzone" },
          "soil_moisture_rootzone_percent": { "id": "soil_moisture_rootzone_percent", "sensor_type": "aquacheck_soil", "title": "Rootzone Current", "units": "percent", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 3, "$grouped": "Rootzone Moisture", "className": "graph-filter-series-color-soil-moisture-rootzone" },

          "soil_moisture_6": { "id": "soil_moisture_6", "sensor_type": "pixl_soil", "title": "Soil Moisture 6in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 1, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-8" },
          "soil_moisture_12": { "id": "soil_moisture_12", "sensor_type": "pixl_soil", "title": "Soil Moisture 12in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 3, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-12" },
          "soil_moisture_18": { "id": "soil_moisture_18", "sensor_type": "pixl_soil", "title": "Soil Moisture 18in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 5, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-16" },
          "soil_moisture_30": { "id": "soil_moisture_30", "sensor_type": "pixl_soil", "title": "Soil Moisture 30in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 7, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-32" },
          "soil_moisture_36": { "id": "soil_moisture_36", "sensor_type": "pixl_soil", "title": "Soil Moisture 36in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 9, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-36" },
          "soil_moisture_42": { "id": "soil_moisture_42", "sensor_type": "pixl_soil", "title": "Soil Moisture 42in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 11, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-40" },

          "soil_moisture_4": { "id": "soil_moisture_4", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 4in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 1, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-8" },
          "soil_moisture_8": { "id": "soil_moisture_8", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 8in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 2, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-8" },
          "soil_moisture_12_boxer": { "id": "soil_moisture_12_boxer", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 12in (g1)", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 3, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-12" },
          "soil_moisture_16": { "id": "soil_moisture_16", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 16in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 4, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-16" },
          "soil_moisture_24": { "id": "soil_moisture_24", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 24in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 6, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-24" },
          "soil_moisture_24_boxer": { "id": "soil_moisture_24_boxer", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 24in (g1)", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 6, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-24" },
          "soil_moisture_32": { "id": "soil_moisture_32", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 32in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 8, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-32" },
          "soil_moisture_36_boxer": { "id": "soil_moisture_36_boxer", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 36in (g1)", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 9, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-36" },
          "soil_moisture_40": { "id": "soil_moisture_40", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 40in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 10, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-40" },
          "soil_moisture_48": { "id": "soil_moisture_48", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 48in", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 12, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-48" },
          "soil_moisture_48_boxer": { "id": "soil_moisture_48_boxer", "sensor_type": "aquacheck_soil", "title": "Soil Moisture 48in (g1)", "units": "%", "min": 0, "max": 100, "category": "Soil", "$sortIndex": 12, "$grouped": "All Soil Moisture", "className": "graph-filter-series-color-soil-moisture-48" },
          "soil_temp_8": { "id": "soil_temp_8", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 8in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 13, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_16": { "id": "soil_temp_16", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 16in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 14, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_18": { "id": "soil_temp_18", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 18in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 15, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_18_boxer": { "id": "soil_temp_18_boxer", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 18in (g1)", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 16, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_24": { "id": "soil_temp_24", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 24in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 17, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_32": { "id": "soil_temp_32", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 32in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 18, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_40": { "id": "soil_temp_40", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 40in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 19, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_42": { "id": "soil_temp_42", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 42in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 20, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_42_boxer": { "id": "soil_temp_42_boxer", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 42in (g1)", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 21, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "soil_temp_48": { "id": "soil_temp_48", "sensor_type": "aquacheck_soil", "title": "Soil Temperature 48in", "units": "°F", "min": 0, "max": 212, "category": "Soil", "$sortIndex": 22, "$grouped": "All Soil Temperature", "className": "graph-filter-series-color-default" },
          "circumference": { "id": "circumference", "sensor_type": "farmx_dendrometer", "title": "Circumference", "units": "mm", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 1, "className": "graph-filter-series-color-default" },
          "dendro": { "id": "dendro", "sensor_type": "dendro", "title": "Dendrometer Cir.", "units": "mm", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 2, "className": "graph-filter-series-color-default" },
          "dendro_avg": { "id": "dendro_avg", "sensor_type": "dendro_avg", "title": "Dendro Avg.", "units": "V", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 3, "className": "graph-filter-series-color-default" },
          "dendro_diff": { "id": "dendro_diff", "sensor_type": "dendro_diff", "title": "Dendro Diff.", "units": "V", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 4, "className": "graph-filter-series-color-default" },
          "leaf_wetness": { "id": "leaf_wetness", "sensor_type": "leaf_wetness", "title": "Leaf Wetness", "units": "%", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 5, "className": "graph-filter-series-color-default" },
          "mds": { "id": "mds", "sensor_type": "farmx_dendrometer", "title": "Maximum Daily Shrinkage", "units": "mm", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 6, "className": "graph-filter-series-color-default" },
          "daily_growth": { "id": "daily_growth", "sensor_type": "daily_growth", "title": "Daily Growth", "units": "mm", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 7, "className": "graph-filter-series-color-default" },
          "par": { "id": "par", "sensor_type": "par", "title": "Photosynthetically active radiation", "units": "W/m", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 8, "className": "graph-filter-series-color-default" },
          "swp": { "id": "swp", "sensor_type": "swp", "title": "Stem Water Potential", "units": "bar", "min": 0, "max": 100, "category": "Plant", "$sortIndex": 9, "className": "graph-filter-series-color-default" },
          "water_pressure": { "id": "water_pressure", "sensor_type": "water_pressure", "title": "Water Pressure", "units": "psi", "min": 0, "max": 100, "category": "Water", "$sortIndex": 1, "className": "graph-filter-series-color-default" },
          "dendrometer_raw": { "id": "dendrometer_raw", "sensor_type": "dendrometer_raw", "title": "Dendrometer (raw)", "units": "mV", "min": 0, "max": 100000, "category": "Plant", "$sortIndex": 9, "className": "graph-filter-series-color-default" },
          "temperature_leaf": { "id": "temperature_leaf", "sensor_type": "temperature_leaf", "title": "Leaf Temperature", "units": "°F", "min": -10, "max": 212, "category": "Weather", "$sortIndex": 10, "className": "graph-filter-series-color-leaf-temp" },
          "temperature_bud": { "id": "temperature_bud", "sensor_type": "temperature_bud", "title": "Bud Temperature", "units": "°F", "min": -1, "max": 212, "category": "Weather", "$sortIndex": 11, "className": "graph-filter-series-color-bud-temp" },
          "leaf_temperature": { "id": "bud_temperature", "sensor_type": "leaf_temperature", "title": "Leaf Temperature", "units": "°F", "min": -10, "max": 212, "category": "Weather", "$sortIndex": 10, "className": "graph-filter-series-color-leaf-temp" },
          "bud_temperature": { "id": "leaf_temperature", "sensor_type": "bud_temperature", "title": "Bud Temperature", "units": "°F", "min": -1, "max": 212, "category": "Weather", "$sortIndex": 11, "className": "graph-filter-series-color-bud-temp" },
          "power": { "id": "power", "sensor_type": "power", "title": "Power", "units": "kwh", "min": 0, "max": 1000, "category": "Power", "$sortIndex": 2, "className": "graph-filter-series-color-default" },
          "flow_rate": { "id": "flow_rate", "sensor_type": "flow_rate", "title": "Flow Rate", "units": "cf/m", "min": 0, "max": 1000, "category": "Water", "$sortIndex": 2, "className": "graph-filter-series-color-default" },
          "flow": { "id": "flow", "sensor_type": "flow_rate", "title": "Flow Total", "units": "gal", "min": 0, "max": 10000, "category": "Water", "$sortIndex": 3, "className": "graph-filter-series-color-default" },
          "flow_count": { "id": "flow_count", "sensor_type": "flow_rate", "title": "Flow Count", "units": "#", "min": 0, "max": 2147483647, "category": "Water", "$sortIndex": 4, "className": "graph-filter-series-color-default" },
          "cwsi": { "id": "cwsi", "sensor_type": "farmx_infrared", "title": "CWSI", "units": "cwsi", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 9, "className": "graph-filter-series-color-default" },
          "daily_cwsi": { "id": "daily_cwsi", "sensor_type": "farmx_infrared", "title": "Daily CWSI", "units": "cwsi", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 10, "className": "graph-filter-series-color-default" },
          "infrared_voltage": { "id": "infrared_voltage", "sensor_type": "farmx_infrared", "title": "Infrared Voltage", "units": "mV", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 11, "className": "graph-filter-series-color-default" },
          "infrared_voltage_0": { "id": "infrared_voltage_0", "sensor_type": "farmx_infrared", "title": "Infrared Voltage +", "units": "mV", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 11, "className": "graph-filter-series-color-default" },
          "infrared_voltage_1": { "id": "infrared_voltage_1", "sensor_type": "farmx_infrared", "title": "Infrared Voltage -", "units": "mV", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 11, "className": "graph-filter-series-color-default" },
          "infrared_raw": { "id": "infrared_raw", "sensor_type": "infrared_raw", "title": "Infrared Raw", "units": "mV", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 12, "className": "graph-filter-series-color-default" },
          "infrared": { "id": "infrared", "sensor_type": "infrared", "title": "Infrared", "units": "mV", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 13, "className": "graph-filter-series-color-default" },
          "farmx_infrared": { "id": "farmx_infrared", "sensor_type": "farmx_infrared", "title": "Infrared", "units": "mV", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 13, "className": "graph-filter-series-color-default" },
          "farmx_frost": { "id": "farmx_frost", "sensor_type": "farmx_frost", "title": "Frost", "units": "mV", "min": 0, "max": 1000, "category": "Plant", "$sortIndex": 13, "className": "graph-filter-series-color-default" },
        }
      };

      this.sensorType = {
        "undefined": {
          "name": "Unknown"
        },
        "gateway": {
          "name": "Gateway"
        },
        "boxer_station": {
          "name": "Base Station",
        },
        "weather_station": {
          "name": "Weather Station",
        },
        "dendrometer": {
          "name": "Dendrometer",
        },
        "water_pressure": {
          "name": "Water Pressure",
        },
        "aquacheck_soil": {
          "name": "Soil Sensor",
        },
        "farmx_dendrometer": {
          "name": "Dendrometer",
        },
        "boxer_soil": {
          "name": "Soil Sensor",
        },
        "pixl_soil": {
          "name": "Pixl Sensor",
        },
        "valve": {
          "name": "Valve",
        },
        "vfd": {
          "name": "VFD",
        },
        "water_flow": {
          "name": "Flow Meter",
        },
        "water_flow_analog": {
          "name": "Flow Meter",
        },
        "farmx_infrared": {
          "name": "Infrared",
        },
        "farmx_frost": {
          "name": "Frost Sensor",
        }
      }

      this.soilType = {
        "clay": {
          "id": "clay",
          "name": "Clay"
        },
        "sandy-clay": {
          "id": "sandy-clay",
          "name": "Sandy Clay"
        },
        "clay-loam": {
          "id": "clay-loam",
          "name": "Clay Loam"
        },
        "silty-clay": {
          "id": "silty-clay",
          "name": "Silty Clay"
        },
        "silty-clay-loam": {
          "id": "silty-clay-loam",
          "name": "Silty Clay Loam"
        },
        "sandy-clay-loam": {
          "id": "sandy-clay-loam",
          "name": "Sandy Clay Loam"
        },
        "loam": {
          "id": "loam",
          "name": "Loam"
        },
        "silt-loam": {
          "id": "silt-loam",
          "name": "Silt Loam"
        },
        "sandy-loam": {
          "id": "sandy-loam",
          "name": "Sandy Loam"
        },
        "loamy-sand": {
          "id": "loamy-sand",
          "name": "Loamy Sand"
        },
        "silt": {
          "id": "silt",
          "name": "Silt"
        },
        "sand": {
          "id": "sand",
          "name": "Sand"
        }
      };

      this.cropType = {
        "pistachio": {
          "id": "pistachio",
          "name": "Pistachio",
          "icon": "images/croptypes/pistachios.png"
        },
        "almond": {
          "id": "almond",
          "name": "Almond",
          "icon": "images/croptypes/almonds.png"
        },
        "almonds": {
          "id": "almond",
          "name": "Almond",
          "icon": "images/croptypes/almonds.png"
        },
        "grape": {
          "id": "grape",
          "name": "Grape",
          "icon": "images/croptypes/grapes.png"
        },
        "walnut": {
          "id": "walnut",
          "name": "Walnut",
          "icon": "images/croptypes/walnuts.png"
        },
        "tomato": {
          "id": "tomato",
          "name": "Tomato",
          "icon": "images/croptypes/none.png"
        },
        "citrus": {
          "id": "citrus",
          "name": "Citrus",
          "icon": "images/croptypes/none.png"
        },
        "none": {
          "id": "none",
          "name": "Unknown",
          "icon": "images/croptypes/none.png"
        }
      };

      this.findVariableTypeById = function (id) {
        return this.sensorInfo.byVariableType[id];
      };

      this.getCapabilityInfo = function (capability) {
        var capabilityInfo = this.sensorInfo.byVariableType[capability];
        if (capabilityInfo !== undefined) {
          return capabilityInfo;
        }

        function titleCase(str) {
          str = str.toLowerCase().split(' ');
          for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
          }
          return str.join(' ');
        }

        return {
          "id": capability,
          "sensor_type": "weather_station",
          "title": titleCase(capability.replace("_", " ")),
          "units": "",
          "min": 0,
          "max": 100,
          "category": "Unknown",
          "$sortIndex": 100,
          "className": "graph-filter-series-color-default",
        }
      };

      this.getChartConfigForUnits = function (units) {
        var unitsConfig = this.sensorInfo.byUnits[units];
        if (unitsConfig !== undefined) {
          return angular.copy(unitsConfig.chartConfig);
        } else {
          return angular.copy(this.sensorInfo.byUnits[""].chartConfig);
        }
      }

      this.$init = function () {
        var si = this.sensorInfo;

        angular.forEach(si.byVariableType, function (eVariableType, iVariableType) {
          var units = si.byUnits[eVariableType.units];

          if (units === undefined) {
            units = angular.copy(si.byUnits[""]);
            si.byUnits[eVariableType.units] = units;
          }

          units.sensorTypes.push(eVariableType.id);
        });
      };

      this.$init();
    }])
    .service('$farmXUtilities', [function () {
      this.stateColorCode = [
        { // Blank
          "iconColor": "#aaaaaa",
          "markerColor": "white",
          "fillColor": "#ffffff",
          "textColor": "#000000"
        },
        { // Offline
          "iconColor": "#ffffff",
          "markerColor": "#cccccc",
          "fillColor": "#cccccc",
          "textColor": "#000000"
        },
        { // Bad
          "iconColor": "#ff98a4",
          "markerColor": "#e23549",
          "fillColor": "#e23549",
          "textColor": "#35e2ce"
        },
        { // Warning
          "iconColor": "#fff4cc",
          "markerColor": "#FFCC00",
          "fillColor": "#FFCC00",
          "textColor": "#0033ff"
        },
        { // Over
          "iconColor": "#a0ccff",
          "markerColor": "#429aff",
          "fillColor": "#429aff",
          "textColor": "#fa742"
        },
        { // Good
          "iconColor": "#4DFA90",
          "markerColor": "#3dc873",
          "fillColor": "#3dc873",
          "textColor": "#c83d92"
        },
      ];
      this.getStateIconColor = function (state) {
        if (state === 0) return "black";
        return "white";
      };
      this.iconForSensorType = function (sensorType) {
        if (sensorType === undefined) return 'icon-radio-waves';
        var icons = {
          "dendrometer": 'icon-leaf',
          "farmx_dendrometer": 'icon-leaf',
          "ir_plant": 'icon-camera',
          "weather_station": 'icon-ios-rainy',
          "water_pressure": 'icon-gauge',
          "water_flow": 'icon-water-meter',
          "water_flow_analog": 'icon-water-meter',
          "gateway": 'icon-radio-tower',
          "boxer_station": 'icon-radio-tower',
          "aquacheck_soil": 'icon-eyedropper',
          "pixl_soil": 'icon-eyedropper',
          "valve": 'icon-pipe-valve',
          "vfd": 'icon-water-pump',
        };

        var icon = icons[sensorType];
        if (icon === undefined) { return 'icon-radio-waves'; }
        return icon;
      };
    }])
    .service('$farmXSatelliteService', ["$rootScope", "$farmXApi", "$mdDialog", "$timeout",
      function ($rootScope, $farmXApi, $mdDialog, $timeout) {
        this.loadSatelliteBlockDataForDate = _loadSatelliteBlockDataForDate;
        this.loadSatelliteRanchDataForDate = _loadSatelliteRanchDataForDate;

        this.getSatelliteBlockDataForDate = _getSatelliteBlockDataForDate;
        this.getSatelliteRanchDataForDate = _getSatelliteRanchDataForDate;

        this.getAvailableDatesBlock = _getAvailableDatesBlock;
        this.getAvailableDatesRanch = _getAvailableDatesRanch;

        this.loadAvailableDataBlock = _loadAvailableDataBlock;
        this.loadAvailableDataRanch = _loadAvailableDataRanch;

        this.createAnomaly = _createAnomaly;

        /* data is stored in this cache under blockId, date, dataType */
        var satelliteDataCache = {
          block: {
            type: "block"
          },
          ranch: {
            type: "ranch"
          },
        };
        var availableDataDates = {
          block: {
            type: "block"
          },
          ranch: {
            type: "ranch"
          },
        };

        function _loadAvailableDataBlock(blockId) {
          return _loadAvailableData(availableDataDates.block, blockId);
        }

        function _loadAvailableDataRanch(ranchId) {
          return _loadAvailableData(availableDataDates.ranch, ranchId);
        }

        // loads available block data into object
        function _loadAvailableData(cache, blockId) {
          if (blockId == null) return;
          return $farmXApi.getAvailableSatelliteDataV2(cache.type, blockId)
            .then(function (data) {
              cache[blockId] = data;
              return data;
            }, function (error) {
            });
        }

        function _getAvailableDatesBlock(blockId, dataType) {
          return _getAvailableDates(availableDataDates.block, blockId, dataType);
        }

        function _getAvailableDatesRanch(blockId, dataType) {
          return _getAvailableDates(availableDataDates.ranch, blockId, dataType);
        }

        function _filterDatesForDataType(dates, dataType) {
          var filteredDates = dates.filter(function (el) {
            return el.datatypes.includes("satellite:" + dataType) || el.datatypes.includes("aerial:" + dataType)
          });

          return filteredDates.map(a => a.date);
        }

        function _tsToMomentDates(dates) {
          return dates.map(ts => moment.unix(ts));
        }

        function _getAvailableDates(cache, blockId, dataType) {
          const blockDates = cache[blockId];
          if (blockDates === undefined) {
            return _loadAvailableData(cache, blockId).then(function (data) {
              return data;
              // var filtered = _filterDatesForDataType(data, dataType);
              // return _tsToMomentDates(filtered);
            });
          } else {
            return new Promise((resolve, reject) => {
              resolve(blockDates);
              // var filtered = _filterDatesForDataType(blockDates, dataType);
              // resolve(_tsToMomentDates(filtered));
            });
          }
        }

        function _loadSatelliteBlockDataForDate(blockId, date, dataType) {
          return _loadSatelliteDataForDate(satelliteDataCache.block, blockId, date, dataType);
        }

        function _loadSatelliteRanchDataForDate(ranchId, date, dataType) {
          return _loadSatelliteDataForDate(satelliteDataCache.ranch, ranchId, date, dataType);
        }

        // Loads data into cache
        function _loadSatelliteDataForDate(cache, blockId, date, dataType) {
          _initCacheTraversal(cache, blockId, date);
          return $farmXApi.getSatelliteDataDate(cache.type, blockId, date, dataType)
            .then(function (data) {
              cache[blockId][date][dataType] = data;
              return data;
            }, function (error) { });
        }

        function _getSatelliteBlockDataForDate(blockId, date, dataType) {
          return _getSatelliteDataForDate(satelliteDataCache.block, blockId, date, dataType);
        }

        function _getSatelliteRanchDataForDate(blockId, date, dataType) {
          return _getSatelliteDataForDate(satelliteDataCache.ranch, blockId, date, dataType);
        }

        // Fetches data from cache, otherwise loads it
        function _getSatelliteDataForDate(cache, blockId, date, dataType) {
          _initCacheTraversal(cache, blockId, date);
          if (cache[blockId][date][dataType] === undefined) {
            _loadSatelliteDataForDate(cache, blockId, date, dataType)
              .then(function (data) {
                return data;
              });
          }
          return cache[blockId][date][dataType];
        }

        function _initCacheTraversal(cache, blockId, date) {
          if (cache[blockId] === undefined) cache[blockId] = {};
          if (cache[blockId][date] === undefined) cache[blockId][date] = {};
        }

        function _createAnomaly() {
          return $mdDialog.show({
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
            },
            template: '<md-dialog style="width: 256px; height: 204px;" layout="column" layout-align="start stretch">' +
              '  <md-toolbar>' +
              '    <div class="md-toolbar-tools">' +
              '      <h2>Create Anomaly</h2>' +
              '    </div>' +
              '  </md-toolbar>' +
              '  <md-dialog-content flex="100">' +
              '    <div class="md-dialog-content" style="padding: 10px">' +
              '      <div flex="100" layout="column" layout-align="start stretch">' +
              '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
              '          <div flex="100" layout="row" layout-align="start center">' +
              '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">Name</label>' +
              '            <input flex="100" ng-model="name">' +
              '          </div>' +
              '          <div flex="100" layout="row" layout-align="start center">' +
              '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">Type</label>' +
              '            <md-select ng-model="type" flex="100" style="margin: 10px 0px">' +
              '              <md-option style="font-size: 12px; height: 32px" value="unidentified">Unidentified</md-option>' +
              '              <md-option style="font-size: 12px; height: 32px" value="disease">Disease/Pest</md-option>' +
              '              <md-option style="font-size: 12px; height: 32px" value="missing">Missing tree</md-option>' +
              '              <md-option style="font-size: 12px; height: 32px" value="irrigation">Under-Irrigated</md-option>' +
              '            </md-select>' +
              '          </div>' +
              '        </div>' +
              '      </div>' +
              '    </div>' +
              '  </md-dialog-content>' +
              '  <md-dialog-actions layout="row">' +
              '    <span flex></span>' +
              '    <md-button ng-click="submit(true)">' +
              '      Create' +
              '    </md-button>' +
              '  </md-dialog-actions>' +
              '</md-dialog>',
            controller: CreateAnomalyController
          });
        };

        function CreateAnomalyController($window, $scope, $http, $q, $timeout, $mdDialog, $log) {
          $scope.name = "";
          $scope.type = "missing";

          $scope.submit = function () {
            $mdDialog.hide({
              "name": $scope.name,
              "type": $scope.type
            });
          };
        }
      }
    ]);
}(angular));
