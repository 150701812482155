module.exports = function($rootScope, $window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $farmXApi, $farmXEntitiesCache, $farmXSensorInfo, $farmXGraphService, $stateParams, $log, $state) {
  var ctrl = this;
  var scope = $scope;
  $state.validateUrlParam();

  ctrl.changeFilter = _changeFilter;
  ctrl.closeTab = _closeTab;
  ctrl.selectTab = _selectTab;
  ctrl.yAxisSpace = _yAxisSpace;
  ctrl.yAxisSpaceEnd = _yAxisSpaceEnd;
  ctrl.previousBlock = _previousBlock;
  ctrl.nextBlock = _nextBlock;
  ctrl.clearAllTabs = _clearAllTabs;
  ctrl.changeInterval = _changeInterval;
  ctrl.tabNameChanged = _tabNameChanged;

  ctrl.entities = undefined;
  ctrl.tabs = [];
  ctrl.activeTab = undefined;
  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  angular.element($window).bind('resize', function() {
    scope.$apply(function () {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;

      $updateGraphWindow();
    });
  });

  $scope.$on('farmx.map.selected', function(event, selected) {
    if (selected.type !== undefined)
      $addTab(selected);
  });

  $scope.$on('farmx.sidenav.selected', function(event, selected) {
    if (selected.type !== undefined)
      $addTab(selected);
  });

  $scope.$on('farmx.graph.updated', function(event, ignored) {
    $rootScope.$broadcast('farmx.graph.config.modified', 'changed');
  });

  $scope.$on('farmx.graph.config.modified', function(event, ignored) {
    var newQueue = [];

    $farmXApi.clearConfig().then(function() {
      var config = {
        "data_page_config" : [],
        "id": $farmXApi.getJWTInfo().user_id,
        "selected_data_tab": -1,
      };

      angular.forEach(ctrl.tabs, function(tabInfo, tabIndex) {
        var tabConfig = $generateSaveConfig(tabInfo, tabIndex);

        if (tabInfo.graphViewConfig.modified) {
          config.data_page_config.push(tabConfig);

          if (tabInfo.id === ctrl.activeTab) {
            config.selected_data_tab = tabConfig.id;
          }
        }
      });

      $farmXApi.setAllConfig(config).then(function(data) {
      }, function(error) {
      });
    }, function() {
    });
  });

  function _clearAllTabs() {
    $farmXApi.clearConfig().then(function() {
      ctrl.tabs = [];

      var parentSelected = scope.$parent.ctrl.selected;
      ctrl.entities = $farmXEntitiesCache.getEntities();

      if (ctrl.entities.length > 0) {
        if (parentSelected.type !== undefined) {
          $addTab(parentSelected);
        } else {
          $addTab({
            "type": "Ranch",
            "value": [
              ctrl.entities[0].ranches[0]
            ]
          });
        }
      }
    }, function() {
    });
  }

  function _selectTab(tabIndex) {
    $timeout(function() {
      if (tabIndex < ctrl.tabs.length) {
        angular.forEach(ctrl.tabs, function(eTab, iTab) {
          eTab.isActive = false;
        });

        if (ctrl.tabs[tabIndex] !== undefined && ctrl.tabs[tabIndex].graphViewConfig !== undefined) {
          ctrl.tabs[tabIndex].isActive = true;

          $rootScope.$broadcast("farmx.graph.redraw", ctrl.tabs[tabIndex].graphViewConfig.id);
          $rootScope.$broadcast("farmx.graph.config.modified", "changed");
          if (ctrl.tabs[tabIndex].tabFor.type === "Ranch") {
            $rootScope.$broadcast("farmx.graph.selected", {
              startTime: new Date().getTime(),
              type: "Ranch",
              value: [
                ctrl.tabs[tabIndex].ranch
              ]
            });
          } else {
            $rootScope.$broadcast("farmx.graph.selected", {
              startTime: new Date().getTime(),
              type: "Block",
              value: [
                ctrl.tabs[tabIndex].ranch,
                ctrl.tabs[tabIndex].ranch.blocks[0]
              ]
            });
          }
        }
      }
    }, 200);
  }

  function _closeTab(tab) {
    $timeout(function() {
      var index = ctrl.tabs.findIndex(function(eTab) {
        return eTab.id === tab.id;
      });

      if (ctrl.tabs.length > 1) {
        var newTabSelected = null;
        if (index > 0) {
          ctrl.activeTab = ctrl.tabs[index - 1].id;
          newTabSelected = ctrl.tabs[index - 1];
          newTabSelected.isActive = true;
        } else {
          ctrl.activeTab = ctrl.tabs[1].id;
          newTabSelected = ctrl.tabs[1];
          newTabSelected.isActive = true;
        }

        ctrl.tabs.splice(index, 1);
        $rootScope.$broadcast('farmx.graph.config.modified','changed');
        $rootScope.$broadcast("farmx.graph.redraw", newTabSelected.graphViewConfig.id);
      }
    }, 10);
  }

  function _tabNameChanged(name) {
    $rootScope.$broadcast('farmx.graph.config.modified','changed');
  }

  function _changeFilter(tab) {
    $farmXGraphService.showDateFilter(tab.graphViewConfig).then(
      function(config) {
        tab.graphViewConfig = config;

        tab.graphViewConfig.modified = true;

        $updateGraphWindow();
        $rootScope.$broadcast("farmx.graph.config.changed", tab.graphViewConfig.id);
      },
      function(error) {
      }
    );
  }

  function _changeInterval(tab, number, units) {
    tab.dateRange.shiftBy.number = number;
    tab.dateRange.shiftBy.units = units;

    tab.dateRange.endDate = moment().hour(23).minute(59).second(59).millisecond(999);
    if (units !== "months") {
      tab.dateRange.startDate = moment().subtract(number - 1, units).hour(0).minute(0).second(0).millisecond(0);
    } else {
      tab.dateRange.startDate = moment().subtract(number, units).hour(0).minute(0).second(0).millisecond(0);
    }

    tab.graphViewConfig.dateRange = tab.dateRange;
    tab.graphViewConfig.modified = true;

    $updateGraphWindow();
    $rootScope.$broadcast("farmx.graph.config.changed", tab.graphViewConfig.id);
  }

  function _previousBlock(tab) {
    tab.dateRange.endDate.subtract(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(23).minute(59).second(59).millisecond(999);
    tab.dateRange.startDate.subtract(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(0).minute(0).second(0).millisecond(0);

    tab.graphViewConfig.dateRange = tab.dateRange;
    tab.graphViewConfig.modified = true;

    $updateGraphWindow();
    $rootScope.$broadcast("farmx.graph.config.changed", tab.graphViewConfig.id);
  }

  function _nextBlock(tab) {
    tab.dateRange.endDate.add(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(23).minute(59).second(59).millisecond(999);
    tab.dateRange.startDate.add(tab.dateRange.shiftBy.number, tab.dateRange.shiftBy.units).hour(0).minute(0).second(0).millisecond(0);

    tab.graphViewConfig.dateRange = tab.dateRange;
    tab.graphViewConfig.modified = true;

    $updateGraphWindow();
    $rootScope.$broadcast("farmx.graph.config.changed", tab.graphViewConfig.id);
  }

  function _yAxisSpace(tab) {
    var width = 0;

    angular.forEach(tab.graphViewConfig.chartGroups, function(chartGroup, index) {
      var cid = chartGroup.id;

      if ($('#' + cid).length > 0) {
        var value = $('#' + cid + ' .highcharts-plot-bands-0 .highcharts-plot-band:first-child').attr('d');

        if (value !== undefined) {
          width = parseFloat(value.split(' ')[1]);
        }
      }
    });

    return width;
  }

  function _yAxisSpaceEnd(tab) {
    var width = 0;

    angular.forEach(tab.graphViewConfig.chartGroups, function(chartGroup, index) {
      var cid = chartGroup.id;

      if ($('#' + cid).length > 0) {
        if ($('#' + cid + ' .highcharts-plot-bands-0 .highcharts-plot-band:last-child').attr('d') !== undefined) {
          width = ($('#' + cid).width() - $('#' + cid + ' svg').width()) + (
            parseFloat($('#' + cid + ' .highcharts-plot-bands-0 .highcharts-plot-band:last-child').attr('d').split(' ')[6]) -
            parseFloat($('#' + cid + ' .highcharts-plot-bands-0 .highcharts-plot-band:last-child').attr('d').split(' ')[1])
          );
        } else {
          width = ($('#' + cid).width() - $('#' + cid + ' svg').width());
        }
      }
    });

    return width;
  }

  function $addExistingTab(config, isActive) {
    var tab = {
      "id": uuid.v4(),
      "isActive": isActive,
      "tabFor": config.block === null ? "Ranch" : "Block",
      "dateRange": {
        "startDate": moment(config.date_start),
        "endDate": moment(config.date_end),
        "shiftBy": {
          "number": (moment(config.date_end).valueOf() - moment(config.date_start).valueOf() + 1) / ( 24 * 60 * 60 * 1000),
          "units": 'days'
        }
      },
      "graphViewConfig": {},
    };

    if (tab.tabFor === "Ranch") {
      tab.ranch = angular.copy($farmXEntitiesCache.findRanchById(config.ranch));
    } else if (tab.tabFor === "Block") {
      var block = angular.copy($farmXEntitiesCache.findBlockById(config.block));
      if (!block) {
        return;
      }
      tab.ranch = angular.copy($farmXEntitiesCache.findRanchById(block.ranch));

      tab.ranch.blocks = [
        block
      ];
    }

    tab.name = config.name;
    if (tab.name === undefined || tab.name === null) tab.name = (tab.tabFor === "Block") ? tab.ranch.blocks[0].name : tab.ranch.name;

    var chartTypes = [];
    var heightFactor = [];
    var sensorList = [];

    angular.forEach(config.graphs, function(eGraph, iGraph) {
      var variableTokens = eGraph.variables.split(",");
      var sensorTokens = eGraph.sensors.split(",");
      var variableTypes = [];
      var sensors = [];

      angular.forEach(variableTokens, function(eToken, iToken) {
        variableTypes.push($farmXSensorInfo.getCapabilityInfo(eToken));
      });

      angular.forEach(sensorTokens, function(eToken, iToken) {
        var sensorParts = eToken.split(":");
        var sensorType = sensorParts[0];
        var sensorId = sensorParts[1];
        var sensor = $farmXEntitiesCache.findSensorByCategoryAndId(sensorType, sensorId);
        if (sensor) sensors.push(sensor);
      });

      // TODO: pass sensor info here

      chartTypes.push(variableTypes);
      heightFactor.push(eGraph.size);
      sensorList.push(sensors);
    });

    tab.graphViewConfig = $farmXGraphService.buildConfig(tab.ranch, tab.tabFor, tab.dateRange, chartTypes, heightFactor, sensorList);
    tab.graphViewConfig.modified = true;

    ctrl.tabs.push(tab);

    $timeout(function() {
      // ctrl.activeTab = tab.id;

      $updateGraphWindow();
    }, 0);

    return tab;
  }

  function $addTab(tabFor) {
    var tab = {
      "id": uuid.v4(),
      "isActive": true,
      "tabFor": tabFor,
      "dateRange": {
        "startDate": moment().subtract(6, 'days').hour(0).minute(0).second(0).millisecond(0),
        "endDate": moment().hour(23).minute(59).second(59).millisecond(999),
        "shiftBy" : {
          "number": 7,
          "units": 'days'
        }
      },
      "graphViewConfig": {},
    };

    if (tabFor.type === "Ranch") {
      tab.ranch = angular.copy(tabFor.value[0]);
      tab.name = tab.ranch.name;
      var sensors = [];
      angular.forEach(tab.ranch.blocks, function(eBlock, iBlock) {
        angular.forEach(eBlock.sensors, function(eSensor, iSensor) {
          if (eSensor.sensor_type == "aquacheck_soil" || eSensor.sensor_type == "pixl_soil") {
            sensors.push(eSensor);
          }
        });
      });
      tab.graphViewConfig = $farmXGraphService.buildConfigForSensors(tab.ranch, tabFor.type, tab.dateRange, sensors);
    } else if (tabFor.type === "Block") {
      tab.ranch = angular.copy(tabFor.value[0]);
      tab.ranch.blocks = [
        angular.copy(tabFor.value[1])
      ];
      tab.name = tab.ranch.blocks[0].name;
      var blockSensors = [];
      angular.forEach(tab.ranch.blocks[0].sensors, function(eSensor, iSensor) {
        if (eSensor.sensor_type == "aquacheck_soil" || eSensor.sensor_type == "pixl_soil") {
          blockSensors.push(eSensor);
        }
      });
      tab.graphViewConfig = $farmXGraphService.buildConfigForSensors(tab.ranch, tabFor.type, tab.dateRange, blockSensors);
      $log.debug("built config", tab.graphViewConfig);
    } else if (tabFor.type === "Sensor") {
      tab.ranch = angular.copy(tabFor.value[0]);
      tab.ranch.blocks = [
        angular.copy(tabFor.value[1])
      ];

      var variableTypeByUnits = {};

      // tabFor.value[2] is sensor information
      const sensorInfo = tabFor.value[2];
      tab.name = tab.ranch.blocks[0].name + ' - ' + sensorInfo.name;
      Object.keys($farmXSensorInfo.sensorInfo.byVariableType).forEach(function(key) {
        // exclude other rootzone variables
        if (key === 'soil_moisture_rootzone_volume'
          || key === 'soil_moisture_rootzone_percent'
          || key.includes('soil_temp')
        ) {
          return;
        }
        var variableType = $farmXSensorInfo.getCapabilityInfo(key);
        if (variableType.category === sensorInfo.category.id
            && (variableType.sensor_type === sensorInfo.sensor_type
            || (variableType.sensor_type.includes('soil') && sensorInfo.sensor_type.includes('soil')))
        ) {
          if (variableTypeByUnits[variableType.units] != null) {
            variableTypeByUnits[variableType.units].push(variableType);
          } else {
            variableTypeByUnits[variableType.units] = [];
            variableTypeByUnits[variableType.units].push(variableType);
          }
        }
      });

      tab.graphViewConfig = $farmXGraphService.buildConfig(tab.ranch, "Block", tab.dateRange, Object.values(variableTypeByUnits));

      tab.graphViewConfig.chartGroups.forEach(function(chartGroup) {
        chartGroup.sensors = [ tabFor.value[2] ];
      });

      var eSensor = sensorInfo;
      if (eSensor.sensor_type == 'aquacheck_soil' || eSensor.sensor_type == 'pixl_soil') {
        var pressureSensors = eSensor.getParent().sensors.filter((sensor) => sensor.sensor_type === 'water_pressure');
        var pressureVariables = [
          $farmXSensorInfo.sensorInfo.byVariableType.water_pressure,
        ];
        var graphForCapabilitiesByUnits = {};
        var graphForCapabilitiesByCategory = {};
        $farmXGraphService.buildCapabilities(tab.ranch, "Block", graphForCapabilitiesByUnits, graphForCapabilitiesByCategory);
        var pressureChartGroup = $farmXGraphService.buildChartGroupForSensors(pressureSensors, pressureVariables, graphForCapabilitiesByUnits, null);
        if (pressureChartGroup) {
          tab.graphViewConfig.chartGroups = tab.graphViewConfig.chartGroups.concat(pressureChartGroup);
        }
      }
    }

    angular.forEach(ctrl.tabs, function(eTab, iTab){
      eTab.isActive = false;
    });

    tab.isActive = true;

    if (ctrl.tabs.length > 0) {
      var lastTab = ctrl.tabs[ctrl.tabs.length - 1];

      if (lastTab.graphViewConfig.modified) {
        ctrl.tabs.push(tab);
      } else {
        ctrl.tabs[ctrl.tabs.length - 1] = tab;
      }
    } else {
      ctrl.tabs.push(tab);
    }

    ctrl.activeTab = tab.id;

    $timeout(function() {
      ctrl.activeTab = tab.id;

      $updateGraphWindow();
    }, 0);

    return tab;
  }

  function $updateGraphWindow() {
    angular.forEach(ctrl.tabs, function(tab, iTab) {
      var width = ctrl.width - 108;
      var dateWidth = width / tab.dateRange.shiftBy.number;
      var startTime = tab.dateRange.startDate.clone();
      var skipCount = 1;

      if (dateWidth < 50) {
        skipCount = Math.ceil(50 / dateWidth);
      }

      tab.dateRange.timeRanges = [];

      while(startTime.isSameOrBefore(tab.dateRange.endDate)) {
        var newStartDate = startTime.clone();
        var dateCount = tab.dateRange.endDate.diff(newStartDate, 'days') + 1;

        if (dateCount < skipCount) {
          tab.dateRange.timeRanges.push(
            {
              "date": startTime.clone(),
              "size": dateCount
            }
          );
        } else {
          tab.dateRange.timeRanges.push(
            {
              "date": startTime.clone(),
              "size": skipCount
            }
          );
        }

        startTime.add({'days': skipCount });
      }
    });
  }

  function $generateSaveConfig(tabInfo, tabIndex) {
    $log.debug("generating save config", tabInfo);
    var modifiedConfig = {
      "date_start": tabInfo.graphViewConfig.dateRange.startDate,
      "date_end": tabInfo.graphViewConfig.dateRange.endDate,
      "graphs": [],
      "id": ($farmXApi.getJWTInfo().user_id * 10000) + tabIndex,
      "name": tabInfo.name,
    };

    if (tabInfo.graphViewConfig.graphForType === "Ranch") {
      modifiedConfig.ranch = tabInfo.graphViewConfig.graphFor.id;
    } else if (tabInfo.graphViewConfig.graphForType === "Block") {
      modifiedConfig.block = tabInfo.graphViewConfig.graphFor.blocks[0].id;
    }

    angular.forEach(tabInfo.graphViewConfig.chartGroups, function(element, index) {
      $log.debug("chartGroup", element);
      modifiedConfig.graphs[index] = {
        "variables": "",
        "sensors": "",
        "order": index,
        "size": element.heightFactor
      };

      // encode sensors
      angular.forEach(element.sensors, function(eSensor, iSensor) {
        if (!eSensor) return;
        if (!eSensor.sensor_type || !eSensor.uid) return;

        if (modifiedConfig.graphs[index].sensors.length > 0) {
          modifiedConfig.graphs[index].sensors = modifiedConfig.graphs[index].sensors + ",";
        }
        var sensorString = eSensor.sensor_type + ':' + eSensor.uid;
        modifiedConfig.graphs[index].sensors = modifiedConfig.graphs[index].sensors + sensorString;
      });

      // encode variable types
      angular.forEach(element.variableTypes, function(eVariableType, iVariableType) {
        if (modifiedConfig.graphs[index].variables.length > 0) {
          modifiedConfig.graphs[index].variables = modifiedConfig.graphs[index].variables + ",";
        }

        modifiedConfig.graphs[index].variables = modifiedConfig.graphs[index].variables + eVariableType.id;
      });
    });

    return modifiedConfig;
  }

  function $setActiveTab(activeTab) {
    ctrl.activeTab = activeTab;
  }

  function $initialize() {
    var graphSelected = null;
    ctrl.entities = $farmXEntitiesCache.getEntities();

    if ($stateParams.sensorType != null) {
      graphSelected = {
        "type": "Sensor",
        "value": [
          $farmXEntitiesCache.findRanchById($stateParams.ranch),
          $farmXEntitiesCache.findBlockById($stateParams.block),
          $farmXEntitiesCache.findSensor($stateParams.sensorType, $stateParams.sensorId)
        ]
      };
    }

    $farmXApi.getAllConfig().then(function(config) {
      var parentSelected = null;

      try {
        parentSelected = $scope.$parent.ctrl.selected;
      } catch (e) {
        parentSelected = { type: undefined };
      }

      // if no saved configs
      if (config.data_page_config.length === 0) {
        if (ctrl.entities.length > 0) {
          if (parentSelected.type !== undefined) {
            $addTab(graphSelected != null ? graphSelected : parentSelected);
          } else {
            $addTab(graphSelected != null ? graphSelected : {
              "type": "Ranch",
              "value": [
                ctrl.entities[0].ranches[0]
              ]
            });
          }
        }
      }
      // else there is one or more saved configs
      else {
        var tStart = new Date().getTime();

        var config2 = config.data_page_config.filter(function(tabInfo) {
          return tabInfo.id >= ($farmXApi.getJWTInfo().user_id * 10000);
        });

        var tabs = config2.sort(function(tabInfoA, tabInfoB) {
          return tabInfoA.id - tabInfoB.id;
        });

        var activeTab;

        ctrl.tabs = [];
        angular.forEach(tabs, function(tabInfo, tabIndex) {
          var isActive;
          $log.debug("tab", tabInfo);
          if (config.selected_data_tab !== undefined && config.selected_data_tab !== null) {
            if (tabInfo.id === config.selected_data_tab) {
              isActive = true;
            }
          }

          var newTab = $addExistingTab(tabInfo, isActive);

          if (isActive) {
            activeTab = newTab.id;
          }
        });

        if (parentSelected.type !== undefined) {
          var newTab = $addTab(graphSelected != null ? graphSelected : parentSelected);

          activeTab = newTab.id;
        }

        if (activeTab === undefined) {
          activeTab = ctrl.tabs[0].id;
        }

        $timeout(function() {
          $setActiveTab(activeTab);
        }, 10);
      }
    }, function() {
      if (ctrl.entities.length > 0) {
        if (parentSelected.type !== undefined) {
          $addTab(graphSelected != null ? graphSelected : parentSelected);
        } else {
          $addTab(graphSelected != null ? graphSelected : {
            "type": "Ranch",
            "value": [
              ctrl.entities[0].ranches[0]
            ]
          });
        }
      }
    });
  }

  this.$onInit = function() {
    Highcharts.setOptions({
      global: {
        useUTC: false
      }
    });

    if ($scope.$parent.ctrl.isLoading() === false && Object.keys($scope.$parent.ctrl.selected).length) {
      $initialize();
    } else {
      $scope.$on('farmx.entitiesCache.updated', $initialize);
    }

    var userInfo = $farmXApi.getJWTInfo();
    mixpanel.track("Graph loaded", {"distinct_id": userInfo.user_id});
  };
};
