module.exports = function($window, $rootScope, $scope, $http, $q, $timeout, $log, leafletData, $farmXApi, $farmXEntitiesCache, $farmXSensorInfo, $mdMedia, $compile, $stateParams, $i18next, $state) {
  var ctrl = this;
  $state.validateUrlParam();

  ctrl.mapViewConfig = {
    controls: {
      hud: {
        control: HUDModeControl
      },
      home: {
        control: HomeControl
      },
      soilMode: {
        control: SoilModeControl
      },
      graph: {
        control: GraphControl
      },
      threedMode: {
        control: ThreeDControl
      },
      zoomMode: {
        control: ZoomControl
      },
      satelliteBrowser: {
        control: SatelliteBrowserControl
      }
    }
  };

  ctrl.getSelected = _getSelected;
  ctrl.isLoading = _isLoading;
  ctrl.isSatelliteBrowserOpen = _isSatelliteBrowserOpen;
  ctrl.toggleSatelliteBrowser = _toggleSatelliteBrowser;

  ctrl.showThreeD = false;

  ctrl.width = $window.innerWidth;
  ctrl.opacity = 80;
  ctrl.isSatelliteBrowser = true;
  ctrl.satelliteDate = moment();
  ctrl.satelliteViewerDataType = "NDVI";

  if (ctrl.width > 550) {
    ctrl.satelliteBrowserStyle = { width: '500px', left: 'calc(50% - 250px )' };
  } else {
    ctrl.satelliteBrowserStyle = { width: '300px' };
    ctrl.satelliteBrowserStyle.left = ((ctrl.width - 300) / 2) + "px";
  }

  angular.element($window).bind('resize', function() {
    $scope.$apply(function () {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;
    });
  });

  $scope.$on('farmx.tab.selected', function(event, tab) {
    $rootScope.$broadcast('farmx.sidenav.open', true);
  });

  function _getSelected() {
    return $scope.$parent.ctrl.selected;
  }

  function _isLoading() {
    return $scope.$parent.ctrl.isLoading();
  }

  function _isSatelliteBrowserOpen() {
    // && ctrl.showThreeD === false && (ctrl.isLoading() || ctrl.getSelected().type === 'Block' || ctrl.getSelected().type === 'Ranch')
    return ctrl.isSatelliteBrowser;
  }

  function _toggleSatelliteBrowser() {
    ctrl.isSatelliteBrowser = ctrl.isSatelliteBrowser ? false : true;

    $rootScope.$broadcast('farmx.internal.mapctrl.satelliteBrowser.state', ctrl.isSatelliteBrowser);
  }

  this.$onInit = function() {
    var userInfo = $farmXApi.getJWTInfo();
    mixpanel.track("Map loaded", {"distinct_id": userInfo.user_id});

    var mapSelected = null;

    if ($stateParams.block != null) {
      mapSelected = {
        "type": "Block",
        "value": [
          $farmXEntitiesCache.findRanchById($stateParams.ranch),
          $farmXEntitiesCache.findBlockById($stateParams.block),
        ]
      };
    } else if ($stateParams.ranch != null) {
      mapSelected = {
        "type": "Ranch",
        "value": [
          $farmXEntitiesCache.findRanchById($stateParams.ranch)
        ]
      };
    }

    if (mapSelected != null) {
      $rootScope.$broadcast("farmx.map.selected", mapSelected);
    }
  };

  function HomeControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control leaflet-clickable");
        $(container).data('control-id', 'home');
        $(container).append('<a><i class="fa fa-home"></i></a>');

        return container;
      },
    });


    function beforeRender(map) {
    }

    function afterRender(map) {
    }

    function onClick(map) {
      $rootScope.$broadcast('farmx.map.selected', {});
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }

  function ZoomControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control leaflet-clickable");
        $(container).data('control-id', 'zoomMode');

        $(container).append('<a class="leaflet-control-zoom-in" title="' + $i18next.t('Zoom in') + '">+</a>' +
          '<a class="leaflet-control-zoom-out" title="' + $i18next.t('Zoom out') + '">-</a>'
        );

        return container;
      }
    });

    function beforeRender(map) {
    }

    function afterRender(map) {
    }

    function onClick(map, event) {
      var element = $(event.originalEvent.srcElement);
      var parentId = element.parents("farmx-map-view").attr("id");

      if (element.hasClass("leaflet-control-zoom-in")) {
        leafletData.getMap(parentId).then(function(map) {
          map.zoomIn(1);
        });
      }

      if ($(event.originalEvent.srcElement).hasClass("leaflet-control-zoom-out")) {
        leafletData.getMap(parentId).then(function(map) {
          map.zoomOut(1);
        });
      }
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }

  function ViewModeControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control farmx-leaflet-control farmx-map-view-mode");
        $(container).data('control-id', 'viewMode');

        var viewMode = ctrl.getSelected().type === undefined ? "Ranch" : ctrl.getSelected().type;

        $(container).append('<div class="layout-row layout-align-center-center flex-100">' +
          '<div class="flex-100">' +
            '<div id="viewMode" class="layout-row layout-align-center-center flex-100">' +
              '<span id="currentViewMode" class="flex-100" style="padding: 5px 5px; cursor: default">' + viewMode + '</span>' +
            '</div>' +
          '</div>' +
        '</div>');

        return container;
      },
    });


    function beforeRender(map) {
    }

    function afterRender(map) {
      $scope.$on('farmx.map.viewmodechanged', function(event, value) {
        $('#currentViewMode').text(value);
      });
    }

    function onClick(map, leafLetEvent) {
      var target = $(leafLetEvent.originalEvent.target);
      if (target.closest('.dropup').length > 0) {
        if (target.closest('#viewMode').length > 0) {
          if (target.closest('.dropup').hasClass('open')) {
            target.closest('.dropup').removeClass('open');
          } else {
            target.closest('.dropup').addClass('open');
          }
        } else {
          target.closest('.dropup').removeClass('open');
          if (target.closest('.mode').length > 0) {
            var selected = ctrl.getSelected();
            var selectedChanged = false;
            var originalViewMode = selected.type === undefined ? "Ranch" : selected.type;
            var newViewMode = target.closest('.mode').data('mode');

            var eRanch;
            var eBlock;

            if (selected.value !== undefined) {
              if (newViewMode === "Ranch") {
                selected = {
                  "type": newViewMode,
                  "value": [ctrl.selected.value[0]]
                };
                selectedChanged = true;
              } else if (newViewMode === "Block") {
                eRanch = selected.value[0];

                if (eRanch.getFirstChild !== undefined) {
                  selected = {
                    "type": newViewMode,
                    "value": [ eRanch, eRanch.getFirstChild() ]
                  };
                  selectedChanged = true;
                } else {
                  selected = {};
                  selectedChanged = true;
                }
              } else if (newViewMode === "Station") {
                eRanch = selected.value[0];

                if (originalViewMode === "Block") {
                  eBlock = selected.value[1];

                  if (eBlock.getFirstChild !== undefined) {
                    selected = {
                      "type": newViewMode,
                      "value": [ eRanch, eBlock, eBlock.getFirstChild() ]
                    };
                    selectedChanged = true;
                  } else {
                    selected = {};
                    selectedChanged = true;
                  }
                } else {
                  if (eRanch.getFirstChild !== undefined) {
                    eBlock = eRanch.getFirstChild();

                    if (eBlock.getFirstChild !== undefined) {
                      selected = {
                        "type": newViewMode,
                        "value": [ eRanch, eBlock, eBlock.getFirstChild() ]
                      };
                      selectedChanged = true;
                    } else {
                      selected = {};
                      selectedChanged = true;
                    }
                  } else {
                    selected = {};
                    selectedChanged = true;
                  }
                }
              }
            }

            if (selectedChanged) {
              ctrl.selected = selected;
              $rootScope.$broadcast('farmx.map.selected', selected);
            }

            $rootScope.$broadcast('farmx.map.viewmodechanged', newViewMode);
          }
        }
      }
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }

  function SoilModeControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control farmx-leaflet-control farmx-map-soil-mode leaflet-clickable");
        $(container).data('control-id', 'soilMode');

        $(container).append('<div class="layout-row layout-align-space-between-center flex-100">' +
          '<input style="margin: 5px" type="checkbox" id="soilModeCheck">' +
          '<div style="margin-right: 5px">' +
            '<img width="18px" height="18px" style="margin-right: 10px;" src="images/soil.svg"/><span>' + $i18next.t('Soil') + '</span>' +
          '</div>' +
        '</div>');

        return container;
      }
    });

    function beforeRender(map) {
    }

    function afterRender(map) {
    }

    function onClick(map) {
      if ($('#soilModeCheck').prop('checked') === false) {
        if (ctrl.soilLayer !== undefined && ctrl.soilLayer !== null) {
          map.removeLayer(ctrl.soilLayer);
          ctrl.soilLayer = null;
        }
      } else {
        ctrl.soilLayer = new L.TileLayer('https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}', {
          "name":             "soil",
          "pane":             "overlays",
          "maxZoom":          19,
          "minZoom":          0,
          "showOnSelector":   false
        });
        ctrl.soilLayer.addTo(map);
      }
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }

  function GraphControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control leaflet-disabled leaflet-clickable");
        container.id = "leaflet-graph-control";
        $(container).data('control-id', 'graph');
        $(container).data('disabled', true);

        $(container).append('<a><i class="fa fa-bar-chart"></i></a>');

        return container;
      },
    });


    function beforeRender(map) {
    }

    function afterRender(map) {
      updateButtonStatus(ctrl.getSelected());

      $scope.$on('farmx.sidenav.selected', function(event, value) {
        updateButtonStatus(value);
      });

      $scope.$on('farmx.map.selected', function(event, value) {
        updateButtonStatus(value);
      });
    }

    function updateButtonStatus(selected) {
      var button = $('#leaflet-graph-control');

      if (selected.type === "Ranch" || selected.type === "Block") {
        if (button.data('disabled')) {
          button.data('disabled', false);
          button.removeClass('leaflet-disabled');
        }
      } else {
        if (button.data('disabled') === false) {
          button.data('disabled', true);
          button.addClass('leaflet-disabled');
        }
      }
    }

    function onClick(map) {
      var button = $('#leaflet-graph-control');
      var selected = $scope.ctrl.getSelected();

      if (button.data('disabled') === false) {
        if (selected.type === "Ranch") {
          $rootScope.$broadcast("farmx.header.tabSelected", { tabType: "graph", params: {ranch: selected.value[0].id, block: null, sensorType: null, sensorId: null }});
        } else {
          $rootScope.$broadcast("farmx.header.tabSelected", { tabType: "graph", params: {ranch: selected.value[0].id, block: selected.value[1], sensorType: null, sensorId: null }});
        }
      }
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }

  function HUDModeControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'topleft'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control");
        $(container).data('control-id', 'hudMode');

        $(container).append('<div id="hudMode" class="layout-row layout-align-center-center flex-100">' +
          '<span id="selected" style="padding: 0px 10px; cursor: default; line-height: 26px;">' + $i18next.t('All') + '</span>' +
          '<span id="cropType" style="padding: 0px 10px; cursor: default; line-height: 26px; border-left: 1px solid #CCCCCC; display: none"></span>' +
          '<span id="soilType" style="padding: 0px 10px; cursor: default; line-height: 26px; border-left: 1px solid #CCCCCC; display: none"></span>' +
        '</div>');

        container.style.height = '26px';
        container.style.backgroundColor = '#FFFFFF';

        return container;
      }
    });

    function beforeRender(map) {
    }

    function afterRender(map) {
      _update($scope.$parent.ctrl.selected);

      $scope.$on('farmx.sidenav.selected', function(event, value) {
        _update(value);
      });

      $scope.$on('farmx.map.selected', function(event, value) {
        _update(value);
      });
    }

    function onClick(map) {
    }

    function _update(value) {
      var cropType = "";

      if (value.type === "Ranch") {
        var ranch = value.value[0];
        $('#selected').text(ranch.name);

        if (ranch.crop_types !== undefined) {
          var crops = {};

          angular.forEach(ranch.crop_types, function(eCropType, iCropType) {
            if (crops[eCropType.identifier] === undefined) {
              if (eCropType.identifier !== "none") {
                if (cropType.length > 0) {
                  cropType = cropType + ", " + eCropType.name;
                } else {
                  cropType = eCropType.name;
                }
              }

              crops[eCropType.identifier] = eCropType;
            }
          });

          if (cropType.length === 0) {
            cropType = "Unknown";
          }

          $('#cropType').text(cropType);
          $('#cropType').css('display','block');
        } else {
          $('#cropType').text("Unknown");
          $('#cropType').css('display','block');
        }
      } else if (value.type === "Block") {
        var block = value.value[1];
        $('#selected').text(block.name);

        if (block.crop !== undefined) {
          if (block.crop === "none" || block.crop === undefined) {
            cropType = "Unknown";
          } else if ($farmXSensorInfo.cropType[block.crop] === undefined) {
            cropType = block.crop.charAt(0).toUpperCase() + block.crop.slice(1);
          } else {
            cropType = $farmXSensorInfo.cropType[block.crop].name;
          }

          $('#cropType').text(cropType);
          $('#cropType').css('display','block');
        } else {
          $('#cropType').text("Unknown");
          $('#cropType').css('display','block');
        }

        if (block.soil_type !== undefined && $farmXSensorInfo.soilType[block.soil_type] !== undefined) {
          $('#soilType').text($farmXSensorInfo.soilType[block.soil_type].name);
          $('#soilType').css("display","block");
        } else {
          $('#soilType').text("Unknown");
          $('#soilType').css("display","block");
        }
      } else if (value.type === undefined) {
      }

      $('#soilType').text($i18next.t($('#soilType').text()));
      $('#cropType').text($i18next.t($('#cropType').text()));
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }

  function ThreeDControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'topright'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control leaflet-clickable leaflet-threed");
        $(container).data('control-id', 'threedMode');
        $(container).append('<a><img width="18px" height="18px" src="images/3d.svg"></a>');

        $(container).css('display', 'none');

        return container;
      },
    });

    function beforeRender(map) {
    }

    function afterRender(map) {
      _update($scope.$parent.ctrl.selected);

      if ($scope.ctrl.isSatelliteBrowserOpen() === false) {
        $scope.ctrl.toggleSatelliteBrowser();
      }

      $scope.$on('farmx.sidenav.selected', function(event, value) {
        _update(value);
      });

      $scope.$on('farmx.map.selected', function(event, value) {
        _update(value);
      });
    }

    function _update(value) {
      if (value.type === "Block") {
        $('.leaflet-threed').css('display','block');
      } else {
        $('.leaflet-threed').css('display','none');
      }
    }

    function onClick(map) {
      ctrl.showThreeD = true;

      if ($scope.ctrl.isSatelliteBrowserOpen()) {
        $scope.ctrl.toggleSatelliteBrowser();
      }
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }

  function SatelliteBrowserControl(value) {
    var Control = L.Control.extend({
      options: {
        position: 'bottomright'
      },

      onAdd: function(map) {
        var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control leaflet-clickable leaflet-satelliteBrowser");
        $(container).data('control-id', 'satelliteBrowser');
        $(container).append('<a><img width="18px" height="18px" src="images/satellite.svg"></a>');

        $(container).css('display', 'none');

        return container;
      },
    });

    function beforeRender(map) {
    }

    function afterRender(map) {
      _update($scope.ctrl.isSatelliteBrowserOpen());

      $scope.$on('farmx.internal.mapctrl.satelliteBrowser.state', function(event, value) {
        _update(value);
      });
    }

    function _update(value) {
      if (value) {
        $('.leaflet-satelliteBrowser').css('display','none');
      } else {
        $('.leaflet-satelliteBrowser').css('display','block');
      }
    }

    function onClick(map) {
      $scope.ctrl.isSatelliteBrowser = true;

      _update($scope.ctrl.isSatelliteBrowser);
    }

    return {
      control: new Control(),
      beforeRender: beforeRender,
      afterRender: afterRender,
      onClick: onClick
    };
  }
};
