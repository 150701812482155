module.exports = function($rootScope, $scope, $window, $farmXApi, $farmXEntitiesCache, $farmXUtilities, $farmXGraphService, $farmXSensorInfo, $mdDialog, $q, $timeout, $log, $farmXDashboardService, $interval, $farmXDateSelectService, $filter, $state) {
  var ctrl = this;

  ctrl.ranch = null;
  ctrl.block = null;
  $state.validateUrlParam();

  ctrl.hideSensorFields = false;

  ctrl.mapViewConfig = {
    controls: {
    }
  };

  ctrl.graphViewConfig = {
  };

  ctrl.changeFilter = _changeFilter;
  ctrl.selectDateFromPicker = _selectDateFromPicker;
  ctrl.selectFlowSeasonDatesFromPicker = _selectFlowSeasonDatesFromPicker;
  ctrl.getIrrigationState = _getIrrigationState;
  ctrl.getSelected = _getSelected;
  ctrl.getStateColor = _getStateColor;
  ctrl.getYODPrecipitation = _getYODPrecipitation;
  ctrl.getSoilTypeName = _getSoilTypeName;

  ctrl.width = $window.innerWidth;
  ctrl.height = $window.innerHeight;

  ctrl.gddLowerBound = 50;
  ctrl.gddUpperBound = 86;
  ctrl.gdd_stale = true;

  ctrl.precipEndDate = moment();
  ctrl.precipStartDate = moment().startOf('year');
  ctrl.flowSeasonStartDate = moment().startOf('year');
  ctrl.flowSeasonEndDate = moment().endOf('year');

  ctrl.currentWaterTime = moment();
  ctrl.currentWaterTimeOptions = {
    locale: 'en',
  };

  var unitOptions = {
    flowRate: [
      {
        key: 'gph',
        name: 'GPH',
        decimals: 0,
      },
      {
        key: 'mgph',
        name: 'M GPH',
        decimals: 0,
      },
      {
        key: 'acrein/hr',
        name: 'Ac. in / hr',
        decimals: 2,
      },
      {
        key: 'acreft/hr',
        name: 'Ac. ft / hr',
        decimals: 1,
      },
      {
        key: 'feet3/hr',
        name: 'CFH',
        decimals: 0,
      },
      {
        key: 'feet3/min',
        name: 'CFM',
        decimals: 0,
      }
    ],
    pressure: [
      {
        key: 'psi',
        name: 'psi',
        decimals: 1,
      },
      {
        key: 'pascal',
        name: 'Pa',
        decimals: 0,
      }
    ],
    flow: [
      {
        key: 'gallons',
        name: 'Gal',
        decimals: 0,
      },
      {
        key: 'mgallons',
        name: 'M Gal',
        decimals: 1,
      },
      {
        key: 'acrein',
        name: 'Acre in',
        decimals: 1,
      },
      {
        key: 'acreft',
        name: 'Acre ft',
        decimals: 1,
      },
      {
        key: 'feet3',
        name: 'Cubic Ft',
        decimals: 0,
      }
    ]
  };

  ctrl.currentFlowTableUnits = {
    pressure: {
      options: unitOptions.pressure,
      selected: unitOptions.pressure[0],
    },
    flowRate: {
      options: unitOptions.flowRate,
      selected: unitOptions.flowRate[0],
    }
  };

  ctrl.cumulativeFlowTableUnits = {
    flow: {
      options: unitOptions.flow,
      selected: unitOptions.flow[0],
    }
  };

  function _convertPressure(psi, units) {
    switch(units) {
      case 'psi':
        return psi;
      case 'pascal':
        return psi * 6894.75729;
      default:
        return null;
    }
  }

  function _convertFlowRate(gph, units) {
    switch(units) {
      case 'gph':
        return gph;
      case 'mgph':
        return gph / 1000000;
      case 'acrein/hr':
        return 0.000036828 * gph;
      case 'acreft/hr':
        return 0.000003069 * gph;
      case 'feet3/hr':
        return 0.133681 * gph;
      case 'feet3/min':
        return 0.002228009259258553 * gph;
      default:
        return null;
    }
  }

  function _convertFlow(gallons, units) {
    switch(units) {
      case 'gallons':
        return gallons;
      case 'mgallons':
        return gallons / 1000000;
      case 'acrein':
        return 0.000036826598951644 * gallons;
      case 'acreft':
        return 0.0000030688832459704 * gallons;
      case 'feet3':
        return 0.133681 * gallons;
      default:
        return null;
    }
  }

  function _convertUnits(value, units, newUnits) {
    switch(units) {
      case 'psi':
        return _convertPressure(value, newUnits);
      case 'gph':
          return _convertFlowRate(value, newUnits);
      case 'gallons':
          return _convertFlow(value, newUnits);
      default:
        return null;
    }
  }

  ctrl.convertUnits = function (value, units, newUnits) {
    var newValue = _convertUnits(value, units, newUnits.key);
    return $filter('number')(newValue, newUnits.decimals);
  };

  angular.element($window).bind('resize', function() {
    $timeout(function() {
      ctrl.width = $window.innerWidth;
      ctrl.height = $window.innerHeight;
    });
  });

  $scope.$on('farmx.sidenav.selected', function(event, selected) {
    if (selected.type !== undefined) {
      ctrl.ranch = selected.value[0];
      if (ctrl.ranch.id == 73646) {
        ctrl.hideSensorFields = true;
      } else {
        ctrl.hideSensorFields = false;
      }
      if (selected.type == 'Block') {
        ctrl.block = selected.value[1];
      } else {
        ctrl.block = null;
      }
    } else {
      ctrl.ranch = null;
      ctrl.block = null;
    }
  });

  $scope.$watch('ctrl.ranch', function(newValue, oldValue) {
    if (newValue !== null) {
      $initializeData();
    }
  });

  $scope.$watch('ctrl.block', function(newValue, oldValue) {
    if (newValue !== null) {
      $initializeBlockData();
    }
  });

  $scope.$watch('ctrl.currentWaterTime', function(newValue, oldValue) {
    _initializeCurrentWaterData();
  });

  function _initializeCurrentWaterData() {
    if (ctrl.ranch) {
      $farmXApi.getRanchCurrentWaterStats(ctrl.ranch.id, ctrl.currentWaterTime).then(
        function(data) {
          ctrl.ranch.currentWaterStats = data.currentWaterStats;
        }
      );
    }
    if (ctrl.block) {
      $farmXApi.getBlockCurrentWaterStats(ctrl.block.id, ctrl.currentWaterTime).then(
        function(data) {
          ctrl.block.currentWaterStats = data.currentWaterStats;
        }
      );
    }
  }

  function _getSoilTypeName(soilType) {
    if (!soilType || !$farmXSensorInfo.soilType[soilType]) {
      return "No data";
    }
    var soilTypeName = $farmXSensorInfo.soilType[soilType].name;
    if (!soilTypeName)
      return "No data";
    return soilTypeName;
  }

  function _changeFilter() {
    $farmXGraphService.showDateFilter(ctrl.graphViewConfig).then(
      function(config) {
        graphViewConfig = config;
        $rootScope.$broadcast("farmx.graph.config.changed", true);
      },
      function(error) {
      }
    );
  }

  function _selectDateFromPicker() {
    $farmXDateSelectService.showMultiDateFilter(ctrl.precipStartDate, ctrl.precipEndDate).then(
      function(dates) {
        ctrl.precipStartDate = dates[0];
        ctrl.precipEndDate = dates[1];
        ctrl.getCumulativePrecip();
      },
      function(error) {
        console.log(error);
      }
    );
  }

  function _selectFlowSeasonDatesFromPicker() {
    $farmXDateSelectService.showMultiDateFilter(ctrl.flowSeasonStartDate, ctrl.flowSeasonEndDate).then(
      function(dates) {
        ctrl.flowSeasonStartDate = dates[0];
        ctrl.flowSeasonEndDate = dates[1];
        ctrl.getCumulativeFlow();
      },
      function(error) {
        console.log(error);
      }
    );
  }

  function _getSelected() {
    return $scope.$parent.ctrl.selected;
  }

  function _getStateColor() {
    return {
      "background-color": $farmXUtilities.stateColorCode[ctrl.ranch.state.state].fillColor
    };
  }

  function _getIrrigationState() {
    return ctrl.ranch.state.message;
  }

  function _getYODPrecipitation() {
    if (ctrl.ranch !== null) {
      return ctrl.ranch.precipitation[8];
    }

    return 0;
  }

  function $initializeBlockData() {
    $farmXApi.getBlockCurrentWaterStats(ctrl.block.id).then(
      function(data) {
        ctrl.block.currentWaterStats = data.currentWaterStats;
      }
    );
  }

  function $initializeData() {

    $farmXApi.getRanchCurrentWaterStats(ctrl.ranch.id).then(
      function(data) {
        ctrl.ranch.currentWaterStats = data.currentWaterStats;
      }
    );

    $farmXApi.getRanchCurrentFlowStats(ctrl.ranch.id).then(
      function(data) {
        ctrl.ranch.currentFlowStats = data.currentFlowStats;
      }
    );

    ctrl.getCumulativeFlow = function () {
      ctrl.cumulativeFlowStale = true;
      $farmXApi.getRanchCumulativeFlowStats(ctrl.ranch.id, ctrl.flowSeasonStartDate, ctrl.flowSeasonEndDate).then(
        function(data) {
          ctrl.ranch.cumulativeFlowStats = data.cumulativeFlowStats;
          ctrl.cumulativeFlowStale = false;
        }
      );
    };

    ctrl.getCumulativeFlow();

    $farmXApi.getSummary(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.eto_cimis = data.eto_cimis;
        ctrl.ranch.eto = data.eto;
        if (ctrl.ranch.eto_cimis == null) {
          ctrl.ranch.eto_cimis = ctrl.ranch.eto;
        }
        ctrl.ranch.kc = data.kc;
      },
      function(error) {
      }
    );

    $farmXApi.getETPredictions(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.etpredictions = data.predictions;
      },
      function(error) {
      }
    );

    $farmXApi.getSWPPredictions(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.swp = data.predictions;
      },
      function(error) {
      }
    );

    $farmXApi.getChillPortions(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.chill_portions = data.chill_portions;
      },
      function(error) {
      }
    );

    $farmXApi.getChillHours(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.chill_hours = data.chill_hours;
      },
      function(error) {
      }
    );

    $farmXApi.getWeatherForecast(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.temp_forecast = data.temp;
        $log.error(data.current);
        if (data.current.length) {
          ctrl.ranch.temp_summary = data.current[0].weather;
        } else {
          ctrl.ranch.temp_summary = null;
        }
      },
      function(error) {
      }
    );

    $farmXApi.getFrostForecast(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.frost_forecast = data.frost;
      },
      function(error) {
      }
    );

    ctrl.getGDD = function() {
      ctrl.gdd_stale = true;
      $farmXApi.getGDD(ctrl.ranch, ctrl.gddLowerBound, ctrl.gddUpperBound).then(
        function(data) {
          ctrl.ranch.gdd = data.gdd;
          ctrl.gdd_stale = false;
        },
        function(error) {
        }
      );
    };

    ctrl.getGDD();

    ctrl.getCumulativePrecip = function () {
      ctrl.precipStale = true;
      $farmXApi.getCumulativePrecip(ctrl.ranch, ctrl.precipStartDate, ctrl.precipEndDate).then(
        function(data) {
          console.error(data);
          ctrl.ranch.cumulativePrecip = data.precip;
          ctrl.precipStale = false;
        },
        function(error) {
        }
      );
    };

    ctrl.getCumulativePrecip();

    /*$farmXApi.getRanchData(ctrl.ranch, moment().subtract({months: 1}), moment(), $farmXSensorInfo.sensorInfo.byVariableType.air_temp).then(
      function(data) {
        if (data.air_temp[0].data.length > 0) {
          $log.log(data);
          ctrl.ranch.air_temp = data.air_temp[0].data[data.air_temp[0].data.length - 1][1];
        }
      }, function(error) {
      }
    );*/

    ctrl.ranch.yieldDataTable = $farmXApi.getYieldDataTable(ctrl.ranch.id);
    if (ctrl.ranch.yieldDataTable) {
      ctrl.ranch.yieldDataTable.selected = ctrl.ranch.yieldDataTable.yieldData[0];
    }

    $farmXApi.getRanchPrecip(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.precipitation = data.precip;
      },
      function(error) {
      }
    );

    ctrl.ranch.soil_moisture = undefined;

    $farmXApi.getSoilSummary(ctrl.ranch).then(
      function(data) {
        ctrl.ranch.soil_moisture = data.soil_summary;
        if (data == null || data.soil_summary == null) {
          ctrl.ranch.soil_moisture = null;
        }
      },
      function(error) {
      }
    );

    ctrl.getVFDStatus = function() {
      $farmXApi.getVFDStatus(ctrl.ranch).then(
        function(data) {
          ctrl.ranch.vfds = data.vfds;
          if (ctrl.vfdMessage == "Loading...") ctrl.vfdMessage = "Loaded Status";
        },
        function(error) {
        }
      );
    };
    ctrl.getVFDStatus();
    $interval(ctrl.getVFDStatus, 10000);

    ctrl.vfdStarting = null;
    ctrl.vfdStopping = null;

    ctrl.vfdMessage = "Loading...";

    ctrl.turnVFDOn = function(vfd) {
      if (ctrl.vfdStarting) return;
      var today = new Date();
      var endTime = new Date();
      endTime.setHours(today.getHours() + 4);
      var config = {
        startDate: today,
        endDate: endTime
      };
      $farmXDashboardService.showScheduleEvent(config).then(
        function(config) {
          if (config.action === "update") {
            ctrl.vfdMessage = "Start signal sent";
            ctrl.vfdStarting = true;
            $farmXApi.turnOnVFD(vfd.uid, config.data.startDate, config.data.endDate).then(
              function(success) {
                ctrl.vfdStopping = null;
                ctrl.vfdStarting = null;
                if (!success) {
                  ctrl.vfdMessage = "Start signal received";
                  $log.error(success);
                } else {
                  ctrl.vfdMessage = "Success: Start signal received";
                }
                ctrl.getVFDStatus();
              }, function(error) {
                //set error message
                $log.error(error);
                ctrl.vfdStopping = null;
                ctrl.vfdStarting = null;
                ctrl.vfdMessage = "Error starting vfd. Try again.";
              }
            );
          } else if (config.action === "none") {
          }
        }, function(ignored) {
        }
      );
    };

    ctrl.turnVFDOff = function(vfd) {
      if (ctrl.vfdStopping) return;

      $farmXDashboardService.showStopVFDConfirm().then(
        function(config) {
          if (config.action === "update") {
            ctrl.vfdStopping = true;
            ctrl.vfdMessage = "Stop signal sent";
            $farmXApi.turnOffVFD(vfd.uid).then(
              function(success) {
                ctrl.vfdStopping = null;
                ctrl.vfdStarting = null;

                if (!success) {
                  ctrl.vfdMessage = "Stop signal received.";
                  $log.error(success);
                } else {
                  ctrl.vfdMessage = "Success: Stop signal received";

                  if (success.status) {
                    ctrl.ranch.vfds[0].status = success.status;
                  }
                }
                ctrl.getVFDStatus();
              }, function(error) {
                //set error message
                ctrl.vfdStopping = null;
                ctrl.vfdStarting = null;
                ctrl.vfdMessage = "Error stopping VFD. Try again.";
                $log.error(error);
              }
            );
          } else if (config.action === "none") {
          }
        }, function(ignored) {
        }
      );
    };

    ctrl.getDateWeekdayPlain = function(dateStr) {
      var day = moment(dateStr).format('ddd');
      return day;
    };

    ctrl.getDateWeekday = function(dateStr) {
      day = ctrl.getDateWeekdayPlain(dateStr);
      return day + " night";
    };

    ctrl.getDateTime = function(dateStr) {
      var date = moment(dateStr);
      var fmt = 'h:mm A ddd';
      return date.format(fmt);
    };

    ctrl.getDateTimeSet = function(dateStr) {
      if (dateStr == null) return "Not set";
      return ctrl.getDateTime(dateStr);
    };

    ctrl.getTime = function(dateStr) {
      var date = moment(dateStr);
      var fmt = 'h:mm A';
      return date.format(fmt);
    };

    ctrl.formatPrecip = function(precip) {
      if (precip == null || precip === undefined) {
        return '-';
      } else {
        return parseFloat(precip).toFixed(2);
      }
    };

    ctrl.formatFlowRate = function(flowRate) {
      if (flowRate == null || flowRate === undefined) {
        return '-';
      } else {
        return (parseFloat(flowRate) * 7.48052).toFixed(2) + " gpm";
      }
    };

    ctrl.getTempClass = function(temp) {
      if (temp <= 0) {
        return "temp-warning";
      }
      return "temp-normal";
    };

    ctrl.getTempClassF = function(temp) {
      if (temp <= 32) {
        return "temp-warning";
      }
      return "temp-normal";
    };

    ctrl.c_to_f = function(temp) {
      return 9.0/5 * temp + 32;
    };

    ctrl.graphViewConfig = $farmXGraphService.buildConfig(ctrl.ranch, "Ranch", {
          "startDate": moment().subtract(7, 'days').hour(0).minute(0).second(0).millisecond(0),
          "endDate": moment().hour(23).minute(59).second(59).millisecond(999),
          "shiftBy" : {
            "number": 7,
            "units": 'days'
          }
        },
        [
          [
            $farmXSensorInfo.sensorInfo.byVariableType.air_temp
          ],
          [
            $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_rootzone_vwc,
          ]
        ]
    );

    $rootScope.$broadcast("farmx.graph.config.changed", true);
  }

  this.$onInit = function() {
    var userInfo = $farmXApi.getJWTInfo();
    mixpanel.track("Dashboard loaded", {"distinct_id": userInfo.user_id});

    if ($scope.$parent.ctrl.selected.type !== undefined) {
      ctrl.ranch = angular.copy($scope.$parent.ctrl.selected.value[0]);
    }

    //var el = angular.element( document.querySelector( '#currentWaterDatetimepickerRanch' ) );
    /*var el = document.querySelector( '#currentWaterDatetimepickerRanch' );
    var input = $(el.find('input')).datetimepicker(ctrl.currentWaterTimeOptions); 
    console.error(input);
    ctrl.datetimepicker = input.data("DateTimePicker");*/

  };
};
