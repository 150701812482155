(function(angular){
  'use strict';

  try {
    angular.module('farmx-directives-sidenav');
  } catch (err) {
    angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
  }

  angular.module('farmx-directives-sidenav')
  .service('$farmXGraphService', ["$rootScope", "$mdDialog", "$farmXSensorInfo", '$log', '$i18next',
    function($rootScope, $mdDialog, $farmXSensorInfo, $log, $i18next) {
      this.showDateFilter = function(config) {
        return $mdDialog.show({
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            "config": config,
          },
          template: '<md-dialog style="width: 256px; height: 324px;" layout="column" layout-align="start stretch">' +
                    '  <md-toolbar>' +
                    '    <div class="md-toolbar-tools">' +
                    '      <h2>' + $i18next.t('Select Dates') + '</h2>' +
                    '    </div>' +
                    '  </md-toolbar>' +
                    '  <md-dialog-content flex="100">' +
                    '    <div class="md-dialog-content" style="padding: 10px">' +
                    '      <div flex="100" layout="column" layout-align="start stretch">' +
                    '        <div flex="100" layout="column" style="margin-top: 10px; margin-bottom: 20px">' +
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">From</label>' +
                    '            <md-datepicker ng-model="startDate" md-placeholder="' + $i18next.t('Enter Start Date') + '" md-open-on-focus></md-datepicker>' +
                    '          </div>' +
                    '          <div flex="100" layout="row" layout-align="start center">' +
                    '            <label style="color: rgba(0,0,0,0.54); font-size: 11px; margin: 0px 10px 0px 2px; min-width: 30px">To</label>' +
                    '            <md-datepicker ng-model="endDate" md-placeholder="' + $i18next.t('Enter End Date') + '" md-open-on-focus></md-datepicker>' +
                    '          </div>' +
                    '        </div>' +
                    '        <div flex="100" layout="column" layout-align="start stretch">' +
                    '          <div flex="100" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="today()">' +
                    '              <span>' + $i18next.t('Today') + '</span>' +
                    '            </button>' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="yesterday()">' +
                    '              <span>' + $i18next.t('Yesterday') + '</span>' +
                    '            </button>' +
                    '          </div>' +
                    '          <div flex="50" layout="row" layout-align="start stretch" style="margin-bottom: 10px">' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px; margin-right: 5px;" ng-click="lastDays(6)">' +
                    '              <span>' + $i18next.t('Last 7 Days') + '</span>' +
                    '            </button>' +
                    '            <button type="button" flex="50" class="btn btn-default" style="font-size: 11px;" ng-click="lastDays(30)">' +
                    '              <span>' + $i18next.t('Last 30 Days') + '</span>' +
                    '            </button>' +
                    '          </div>' +
                    '        </div>' +
                    '      </div>' +
                    '    </div>' +
                    '  </md-dialog-content>' +
                    '  <md-dialog-actions layout="row">' +
                    '    <span flex></span>' +
                    '    <md-button ng-click="submit(true)">' +
                    '      ' + $i18next.t('Filter') +
                    '    </md-button>' +
                    '  </md-dialog-actions>' +
                    '</md-dialog>',
          controller: FarmXGraphSelectDate
        });
      };

      function FarmXGraphSelectDate($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $log, config) {
        $scope.$startDate = config.dateRange.startDate;
        $scope.$endDate = config.dateRange.endDate;
        $scope.startDate = config.dateRange.startDate.toDate();
        $scope.endDate = config.dateRange.endDate.toDate();

        $scope.submit = function() {
          config.dateRange.startDate = moment($scope.startDate);
          config.dateRange.endDate = moment($scope.endDate);
          config.dateRange.shiftBy.number = config.dateRange.endDate.diff(config.dateRange.startDate, 'days') + 1;
          config.dateRange.shiftBy.units = 'days';
          $mdDialog.hide(config);
        };

        $scope.today = function() {
          $scope.$startDate = moment().hour(0).minute(0).second(0).millisecond(0);
          $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
          $scope.startDate = $scope.$startDate.toDate();
          $scope.endDate = $scope.$endDate.toDate();
        };

        $scope.yesterday = function() {
          $scope.$startDate = moment().subtract(1, 'days').hour(0).minute(0).second(0).millisecond(0);
          $scope.$endDate = moment().subtract(1, 'days').hour(23).minute(59).second(59).millisecond(999);
          $scope.startDate = $scope.$startDate.toDate();
          $scope.endDate = $scope.$endDate.toDate();
        };

        $scope.lastDays = function(days) {
          $scope.$startDate = moment().subtract(days, 'days').hour(0).minute(0).second(0).millisecond(0);
          $scope.$endDate = moment().hour(23).minute(59).second(59).millisecond(999);
          $scope.startDate = $scope.$startDate.toDate();
          $scope.endDate = $scope.$endDate.toDate();
        };
      }

      this.addGraphVariable = function(graphForCapabilitiesByCategory, sensors) {
        return $mdDialog.show({
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            "graphForCapabilitiesByCategory": angular.copy(graphForCapabilitiesByCategory),
            "sensors": angular.copy(sensors)
          },
          template: '<md-dialog style="width: 400px; height: 324px;" layout="column" layout-align="start stretch">' +
                    '  <md-toolbar>' +
                    '    <div class="md-toolbar-tools">' +
                    '      <h2>' + $i18next.t('Select Chart Type') + '</h2>' +
                    '    </div>' +
                    '  </md-toolbar>' +
                    '  <md-dialog-content flex="100">' +
                    '    <div class="md-dialog-content">' +
                    '      <hierarchical-selector id="selectableCategories" placeholder="' + $i18next.t('Variable Category') + '" data="selectableCategories" multi-select="true" on-selection-changed="selectCategory(items)" no-button></hierarchical-selector>' +
                    '      <hierarchical-selector id="selectableVariableTypes" placeholder="' + $i18next.t('Variable Type') + '" data="selectableVariableTypes" multi-select="true" on-selection-changed="selectVariableType(items)" no-button></hierarchical-selector>' +
                    '      <hierarchical-selector id="selectableSensors" placeholder="' + $i18next.t('Sensors') + '" data="selectableSensors" multi-select="true" on-selection-changed="selectSensor(items)" no-button></hierarchical-selector>' +
                    '    </div>' +
                    '  </md-dialog-content>' +
                    '  <md-dialog-actions layout="row" style="padding-left: 8px">' +
                    '    <md-button ng-click="clear()">' +
                    '      ' + $i18next.t('Clear') +
                    '    </md-button>' +
                    '    <span flex></span>' +
                    '    <md-button ng-click="submit()">' +
                    '      ' + $i18next.t('Select') +
                    '    </md-button>' +
                    '  </md-dialog-actions>' +
                    '</md-dialog>',
          controller: FarmXGraphAddGraph
        });
      };

      function FarmXGraphAddGraph($window, $scope, $http, $q, $timeout, $mdMedia, $mdDialog, $log, graphForCapabilitiesByCategory, sensors) {
        $scope.allSensors = [];
        $scope.allCategories = [];
        $scope.allVariableTypes = [];

        $scope.selectableSensors = [];
        $scope.selectableCategories = [];
        $scope.selectableVariableTypes = [];

        $scope.selectedCategories = [];
        $scope.selectedVariableTypes = [];
        $scope.selectedSensors = [];

        $scope.submit = function() {
          var result = {
            "variableTypes": [],
            "sensors": []
          }

          $log.log("-----");
          $log.log("selectableCategories " + JSON.stringify($scope.selectableCategories));
          $log.log("selectableVariableTypes " + $scope.selectableVariableTypes);
          $log.log("selectableSensors " + $scope.selectableSensors);
          $log.log("-----");
          $log.log("selectedVariableTypes " + $scope.selectedVariableTypes);
          $log.log("selectedCategories " + JSON.stringify($scope.selectedCategories));
          $log.log("selectedSensors " + $scope.selectedSensors);

          if ($scope.selectedVariableTypes.length > 0) {
            angular.forEach($scope.selectedVariableTypes, function(eType, iType) {
              if (eType.$sortIndex !== 0) {
                result.variableTypes.push(eType);
              } else {
                result.variableTypes = result.variableTypes.concat(eType.children);
              }
            });

            if ($scope.selectedSensors.length > 0) {
              result.sensors = $scope.selectedSensors;
            } else {
              result.sensors = $scope.selectableSensors;
            }

            $mdDialog.hide(result);
          } else if ($scope.selectedSensors.length > 0) {
            angular.forEach($scope.selectableVariableTypes, function(eType, iType) {
              if (eType.$sortIndex !== 0) {
                result.variableTypes.push(eType);
              } else {
                result.variableTypes = result.variableTypes.concat(eType.children);
              }
            });

            result.sensors = $scope.selectedSensors;

            $mdDialog.hide(result);
          } else if ($scope.selectedCategories.length > 0) {
            angular.forEach($scope.selectableVariableTypes, function(eType, iType) {
              if (eType.$sortIndex !== 0) {
                result.variableTypes.push(eType);
              } else {
                result.variableTypes = result.variableTypes.concat(eType.children);
              }
            });

            if ($scope.selectedSensors.length > 0) {
              result.sensors = $scope.selectedSensors;
            } else {
              result.sensors = $scope.selectableSensors;
            }

            $mdDialog.hide(result);
          } else {
            $mdDialog.cancel();
          }
        };

        $scope.clear = function() {
          var selectableCategories = $('#selectableCategories');
          $scope.$broadcast("clearSelection", "");

          $scope.allSensors = [];
          $scope.allCategories = [];
          $scope.allVariableTypes = [];

          $scope.selectableSensors = [];
          $scope.selectableCategories = [];
          $scope.selectableVariableTypes = [];

          $scope.selectedCategories = [];
          $scope.selectedVariableTypes = [];
          $scope.selectedSensors = [];

          $scope.$onInit();

          $log.log("-----");
          $log.log("selectableCategories " + JSON.stringify($scope.selectableCategories));
          $log.log("selectableVariableTypes " + $scope.selectableVariableTypes);
          $log.log("selectableSensors " + $scope.selectableSensors);
          $log.log("-----");
          $log.log("selectedVariableTypes " + $scope.selectedVariableTypes);
          $log.log("selectedCategories " + JSON.stringify($scope.selectedCategories));
          $log.log("selectedSensors " + $scope.selectedSensors);
        }

        $scope.selectCategory = function(items) {
          $scope.selectedCategories = items === undefined ? [] : items;

          if ($scope.selectedCategories.length > 0) {
            $scope.selectableSensors = [];
            $scope.selectableVariableTypes = [];
          } else {
            $scope.selectableSensors = $scope.allSensors;
            $scope.selectableVariableTypes = $scope.allVariableTypes;
          }

          $scope.selectedCategories.forEach(function(categoryObject) {
            var category = categoryObject.id;

            $scope.allSensors.forEach(function(sensor) {
              if (sensor.category.id === category) {
                $scope.selectableSensors.push(sensor);
              } else if (sensor.children.length > 0) {
                sensor.children.forEach(function(child) {
                  if (child.category.id === category) {
                    $scope.selectableSensors.push(child);
                  }
                });
              }
            });

            $scope.allVariableTypes.forEach(function(variableType) {
              if (variableType.category.id === category) {
                $scope.selectableVariableTypes.push(variableType);
              }
            });
          });

          var selectedSensors = $scope.selectedSensors;
          $scope.selectedSensors = [];
          $scope.selectableSensors.forEach(function(sensor) {
            selectedSensors.forEach(function(selectedSensor) {
              if (sensor.uid === selectedSensor.uid) {
                $scope.selectedSensors.push(selectedSensor);
              }
            });
          });

          var selectedVariableTypes = $scope.selectedVariableTypes;
          $scope.selectedVariableTypes = [];
          $scope.selectableVariableTypes.forEach(function(variableType) {
            selectedVariableTypes.forEach(function(selectedVariableType) {
              if (selectedVariableType.id === variableType.id) {
                $scope.selectedVariableTypes.push(variableType);
              } else if (variableType.children.length > 0) {
                variableType.children.forEach(function(child) {
                  if (selectedVariableType.id === child.id) {
                    $scope.selectedVariableTypes.push(child);
                  }
                });
              }
            });
          });

          $log.log("-----");
          $log.log("selectableCategories " + JSON.stringify($scope.selectableCategories));
          $log.log("selectableVariableTypes " + $scope.selectableVariableTypes);
          $log.log("selectableSensors " + $scope.selectableSensors);
          $log.log("-----");
          $log.log("selectedVariableTypes " + $scope.selectedVariableTypes);
          $log.log("selectedCategories " + JSON.stringify($scope.selectedCategories));
          $log.log("selectedSensors " + $scope.selectedSensors);
        }

        $scope.selectVariableType = function(items) {
          $scope.selectedVariableTypes = items === undefined ? [] : items;

          if ($scope.selectedVariableTypes.length > 0) {
            $scope.selectableCategories = [];
            $scope.selectableSensors = [];
          } else {
            $scope.selectableCategories = $scope.allCategories;
            $scope.selectableSensors = $scope.allSensors;
          }

          $scope.selectedVariableTypes.forEach(function(variableType) {
            var alreadyExists = $scope.selectableCategories.find(function(element) {
              return element.id === variableType.category.id;
            });

            if (alreadyExists === undefined) {
              $scope.selectableCategories.push(variableType.category);
            }

            $scope.allSensors.forEach(function(sensor) {
              if (sensor.category.id === variableType.category.id && alreadyExists === undefined) {
                $scope.selectableSensors.push(sensor);
              } else if (sensor.children.length > 0) {
                sensor.children.forEach(function(child) {
                  if (child.category.id === variableType.category.id && alreadyExists === undefined) {
                    $scope.selectableSensors.push(child);
                  }
                });
              }
            });
          });

          var selectedCategories = $scope.selectedCategories;
          $scope.selectedCategories = [];
          $scope.selectableCategories.forEach(function(category) {
            selectedCategories.forEach(function(selectedCategory) {
              if (category.id === selectedCategory.id) {
                $scope.selectedCategories.push(selectedCategory);
              }
            });
          });

          var selectedSensors = $scope.selectedSensors;
          $scope.selectedSensors = [];
          $scope.selectableSensors.forEach(function(sensor) {
            selectedSensors.forEach(function(selectedSensor) {
              if (sensor.uid === selectedSensor.uid) {
                $scope.selectedSensors.push(selectedSensor);
              }
            });
          });

          $log.log("-----");
          $log.log("selectableCategories " + JSON.stringify($scope.selectableCategories));
          $log.log("selectableVariableTypes " + $scope.selectableVariableTypes);
          $log.log("selectableSensors " + $scope.selectableSensors);
          $log.log("-----");
          $log.log("selectedVariableTypes " + $scope.selectedVariableTypes);
          $log.log("selectedCategories " + JSON.stringify($scope.selectedCategories));
          $log.log("selectedSensors " + $scope.selectedSensors);
        }

        $scope.selectSensor = function(items) {
          $scope.selectedSensors = items === undefined ? [] : items;

          if ($scope.selectedSensors.length > 0) {
            $scope.selectableCategories = [];
            $scope.selectableVariableTypes = [];
          } else {
            $scope.selectableCategories = $scope.allCategories
            $scope.selectableVariableTypes = $scope.allVariableTypes;
          }

          $scope.selectedSensors.forEach(function(sensor) {
            var alreadyExists = $scope.selectableCategories.find(function(element) {
              return element.id === sensor.category.id;
            });

            if (alreadyExists === undefined) {
              $scope.selectableCategories.push(sensor.category);
            }

            $scope.allVariableTypes.forEach(function(variableType) {
              if (variableType.category.id === sensor.category.id && alreadyExists === undefined) {
                $scope.selectableVariableTypes.push(variableType);
              }
            });
          });

          var selectedCategories = $scope.selectedCategories;
          $scope.selectedCategories = [];
          $scope.selectableCategories.forEach(function(category) {
            selectedCategories.forEach(function(selectedCategory) {
              if (category.id === selectedCategory.id) {
                $scope.selectedCategories.push(selectedCategory);
              }
            });
          });

          var selectedVariableTypes = $scope.selectedVariableTypes;
          $scope.selectedVariableTypes = [];
          $scope.selectableVariableTypes.forEach(function(variableType) {
            selectedVariableTypes.forEach(function(selectedVariableType) {
              if (selectedVariableType.id === variableType.id) {
                $scope.selectedVariableTypes.push(variableType);
              } else if (variableType.children.length > 0) {
                variableType.children.forEach(function(child) {
                  if (selectedVariableType.id === child.id) {
                    $scope.selectedVariableTypes.push(child);
                  }
                });
              }
            });
          });

          $log.log("-----");
          $log.log("selectableCategories " + JSON.stringify($scope.selectableCategories));
          $log.log("selectableVariableTypes " + $scope.selectableVariableTypes);
          $log.log("selectableSensors " + $scope.selectableSensors);
          $log.log("-----");
          $log.log("selectedVariableTypes " + $scope.selectedVariableTypes);
          $log.log("selectedCategories " + JSON.stringify($scope.selectedCategories));
          $log.log("selectedSensors " + $scope.selectedSensors);
        }

        $scope.$onInit = function() {
          var allSensors = [];
          sensors.forEach(function(sensor) {
            if (!sensor.data_category) {
              sensor.data_category = "weather";
            }
            sensor.category = {
              id: sensor.data_category.replace(/^\w/, c => c.toUpperCase()),
              name: sensor.data_category.replace(/^\w/, c => c.toUpperCase())
            };

            if (sensor.category.id === "Flow") {
              sensor.category.id = "Water";
              sensor.category.name = $i18next.t("Water");
            }

            allSensors.push({
              "uid": sensor.uid,
              "name": sensor.name,
              "data-category": sensor["data-category"],
              "category": sensor.category,
              "sensor_type": sensor.sensor_type,
              "children": []
            });
          });

          $scope.allSensors.push({
            "uid": 0,
            "name": $i18next.t("All Sensors"),
            "data-category": "All",
            "sensor_type": "All",
            "category": {
              "id": "All",
              "name": $i18next.t("All"),
            },
            "children": allSensors
          });

          $scope.selectableSensors = $scope.allSensors;

          angular.forEach(graphForCapabilitiesByCategory, function(vCategory, kCategory) {
            $scope.allCategories.push({
              id: kCategory,
              name: $i18next.t(kCategory),
            });
          });
          $scope.selectableCategories = $scope.allCategories;

          $scope.allCategories.forEach(function(entry) {
            var toAdd = {};
            var kCategory = entry.id;

            angular.forEach(graphForCapabilitiesByCategory[kCategory], function(eVariableType, iVariableType) {
              var entry = {
                "id": eVariableType.id,
                "name": $i18next.t(eVariableType.title),
                "title": $i18next.t(eVariableType.title),
                "units": eVariableType.units,
                "min": eVariableType.min,
                "max": eVariableType.max,
                "category": {
                  "id": eVariableType.category,
                  "name": $i18next.t(eVariableType.category)
                },
                "$sortIndex": eVariableType.$sortIndex,
                "children": []
              };

              if (eVariableType.$grouped !== undefined) {
                var id = eVariableType.$grouped.toLowerCase().replace(new RegExp(' ','g'),'_');
                if (toAdd[id] === undefined) {
                  toAdd[id] = {
                    "id": id,
                    "name": $i18next.t(eVariableType.$grouped),
                    "title": $i18next.t(eVariableType.$grouped),
                    "units": eVariableType.units,
                    "min": eVariableType.min,
                    "max": eVariableType.max,
                    "category": {
                      "id": eVariableType.category,
                      "name": $i18next.t(eVariableType.category)
                    },
                    "$sortIndex": 0,
                    "children": [entry],
                  };
                } else {
                  toAdd[id].children.push(entry);
                }
              } else {
                toAdd[eVariableType.id] = entry;
              }
            });

            Object.keys(toAdd).forEach(function(variableEntryKey) {
              var variableEntry = toAdd[variableEntryKey];
              $log.log("Test");
              if (variableEntry.children != null) {
                variableEntry.children = variableEntry.children.sort(function(a, b) {
                  return a.$sortIndex - b.$sortIndex;
                });
              }
            });

            $scope.allVariableTypes = $scope.allVariableTypes.concat(Object.values(toAdd));
          });
          $scope.allVariableTypes = $scope.allVariableTypes.sort(function(a, b) {
            return a.$sortIndex - b.$sortIndex;
          });
          $scope.selectableVariableTypes = $scope.allVariableTypes;

          $log.log("-----");
          $log.log("selectableCategories " + JSON.stringify($scope.selectableCategories));
          $log.log("selectableVariableTypes " + $scope.selectableVariableTypes);
          $log.log("selectableSensors " + $scope.selectableSensors);
          $log.log("-----");
          $log.log("selectedVariableTypes " + $scope.selectedVariableTypes);
          $log.log("selectedCategories " + JSON.stringify($scope.selectedCategories));
          $log.log("selectedSensors " + $scope.selectedSensors);
        }

        $scope.$onInit();
      }

      this.buildCapabilities = function(graphFor, graphForType, graphForCapabilitiesByUnits, graphForCapabilitiesByCategory) {
        // TODO: unify this code
        if (graphForType === "Ranch" || graphForType === "Block") {
          angular.forEach(graphFor.capabilities, function(eCapability, iCapability) {
            var variableType = $farmXSensorInfo.getCapabilityInfo(eCapability);
            if (variableType === undefined) {
              // TODO: handle any unhandled capabilities
            } else {

              if (graphForCapabilitiesByUnits[variableType.units] === undefined) {
                graphForCapabilitiesByUnits[variableType.units] = [];
              }

              graphForCapabilitiesByUnits[variableType.units].push($farmXSensorInfo.getCapabilityInfo(eCapability));

              if (graphForCapabilitiesByCategory[variableType.category] === undefined) {
                graphForCapabilitiesByCategory[variableType.category] = [];
              }

              graphForCapabilitiesByCategory[variableType.category].push($farmXSensorInfo.getCapabilityInfo(eCapability));
            }
          });
        }
      };

      this.buildConfigForSensors = function(graphFor, graphForType, dateRange, sensors, heightFactor) {
        // TODO: rewrite this to work with any sensor type
        var variableTypes = [
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_rootzone_vwc,
          /*$farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_4,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_6,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_8,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_12,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_16,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_18,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_24,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_30,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_32,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_36,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_40,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_42,
          $farmXSensorInfo.sensorInfo.byVariableType.soil_moisture_48,*/
        ];

        var graphForCapabilitiesByUnits = {};
        var graphForCapabilitiesByCategory = {};
        this.buildCapabilities(graphFor, graphForType, graphForCapabilitiesByUnits, graphForCapabilitiesByCategory);

        var soilChartGroups = this.buildChartGroupForSensors(sensors, variableTypes, graphForCapabilitiesByUnits, heightFactor);
        var chartGroups = soilChartGroups;

        var config = {
          "id": uuid.v4(),
          "modified": false,
          "dateRange": dateRange,
          "graphFor": graphFor,
          "graphForType": graphForType,
          "graphForCapabilitiesByUnits": graphForCapabilitiesByUnits,
          "graphForCapabilitiesByCategory": graphForCapabilitiesByCategory,
          "chartGroups": chartGroups,
        };

        return config;
      };

      this.buildConfig = function(graphFor, graphForType, dateRange, variableTypesGroup, heightFactor, sensors) {
        var graphForCapabilitiesByUnits = {};
        var graphForCapabilitiesByCategory = {};

        this.buildCapabilities(graphFor, graphForType, graphForCapabilitiesByUnits, graphForCapabilitiesByCategory);

        var config = {
          "id": uuid.v4(),
          "modified": false,
          "dateRange": dateRange,
          "graphFor": graphFor,
          "graphForType": graphForType,
          "graphForCapabilitiesByUnits": graphForCapabilitiesByUnits,
          "graphForCapabilitiesByCategory": graphForCapabilitiesByCategory,
          "chartGroups": this.buildChartGroup(variableTypesGroup, graphForCapabilitiesByUnits, heightFactor, sensors)
        };

        return config;
      };

      var _buildChartGroupForSensors = function(sensors, variableTypes, graphForCapabilitiesByUnits, heightFactor) {
        var chartGroups = [];

        angular.forEach(sensors, function(eSensor, iSensor) {
          var units = variableTypes[0].units;
          var chartConfig = $farmXSensorInfo.getChartConfigForUnits(units);
          var chartGroup = {
            "availableVariableTypes" : angular.copy(graphForCapabilitiesByUnits[units] === undefined ? [] : graphForCapabilitiesByUnits[units]),
            "id": uuid.v4(),
            "expanded": false,
            "loading": true,
            "height": 0,
            "heightFactor": heightFactor !== undefined && heightFactor !== null ? heightFactor[iSensor] : 1,
            "units": units,
            "variableTypes": variableTypes,
            "chart": {
              "generatedChartConfig": chartConfig,
              "id": uuid.v4(),
            }
          };

          chartGroup.sensors = [eSensor];

          var variableTypesTitle = variableTypes[0].title;
          if (variableTypesTitle.includes('Soil Moisture') && variableTypes.length > 1) {
            variableTypesTitle = $i18next.t("Soil Moisture") + " (" + eSensor.name + ")";
          }

          if (variableTypesTitle.includes('Water Pressure') && variableTypes.length > 1) {
            variableTypesTitle = $i18next.t("Water Pressure") + " (" + eSensor.name + ")";
          }

          chartGroup.chart.generatedChartConfig.series.push([{"id":"air_temp-0308c863-0e7c-413e-819c-e0ac51f5857e","name": $i18next.t(variableTypes[0].title),"data":[],"pointStart":1522886400000,"pointInterval":86400000,"marker":{"enabled":false},"className":"graph-filter-series-color-default"}])
          chartGroup.chart.generatedChartConfig.yAxis.title.text = variableTypesTitle + " (" + variableTypes[0].units + ")";
          //chartGroup.chart.generatedChartConfig.yAxis.title.textAlign = 'left';
          //chartGroup.chart.generatedChartConfig.yAxis.title.rotation = 0;
          chartGroup.chart.generatedChartConfig.yAxis.title.x = 10;
          //chartGroup.chart.generatedChartConfig.yAxis.title.x = 55;
          //chartGroup.chart.generatedChartConfig.yAxis.title.y = 79;
          chartGroup.chart.generatedChartConfig.yAxis.title.reserveSpace = false;
          // chartGroup.chart.generatedChartConfig.yAxis.title.zIndex = 1000001;
          chartGroup.chart.generatedChartConfig.yAxis.labels = {};
          chartGroup.chart.generatedChartConfig.yAxis.labels.align = 'left';
          chartGroup.chart.generatedChartConfig.yAxis.labels.x = 10;
          // chartGroup.chart.generatedChartConfig.yAxis.labels.zIndex = 100;
          chartGroup.chart.generatedChartConfig.yAxis.min = 0;
          chartGroup.chart.generatedChartConfig.yAxis.max = 0;
          chartGroup.chart.generatedChartConfig.yAxis.tickPosition = 'inside';
          chartGroup.chart.generatedChartConfig.legend.enabled = false;

          angular.forEach(variableTypes, function(eVariableType, iVariableType) {
            var index = chartGroup.availableVariableTypes.findIndex(function(vt) {
              return vt.id === eVariableType.id;
            });

            if (index >= 0) {
              chartGroup.availableVariableTypes.splice(index, 1);
            }
          });

          chartGroups.push(chartGroup);
          
          // Add pressure graphs for corresponding pressure sensor
          if (eSensor.sensor_type == 'aquacheck_soil' || eSensor.sensor_type == 'pixl_soil') {
            var pressureSensors = eSensor.getParent().sensors.filter((sensor) => sensor.sensor_type === 'water_pressure');
            var pressureVariables = [
              $farmXSensorInfo.sensorInfo.byVariableType.water_pressure,
            ];
            var pressureChartGroup = _buildChartGroupForSensors(pressureSensors, pressureVariables, graphForCapabilitiesByUnits, heightFactor);
            if (pressureChartGroup) {
              chartGroups = chartGroups.concat(pressureChartGroup);
              
            }
          }
        });

        return chartGroups;
      };

      this.buildChartGroupForSensors = _buildChartGroupForSensors;

      this.buildChartGroup = function(variableTypesGroup, graphForCapabilitiesByUnits, heightFactor, sensors) {
        var chartGroups = [];
        angular.forEach(variableTypesGroup, function(eVariableTypes, iVariableTypes) {
          var units = eVariableTypes[0].units;
          var chartGroup = {
            "availableVariableTypes" : angular.copy(graphForCapabilitiesByUnits[units] === undefined ? [] : graphForCapabilitiesByUnits[units]),
            "id": uuid.v4(),
            "expanded": false,
            "loading": true,
            "height": 0,
            "heightFactor": heightFactor !== undefined && heightFactor !== null ? heightFactor[iVariableTypes] : 1,
            "units": units,
            "variableTypes": eVariableTypes,
            "chart": {
              "generatedChartConfig": $farmXSensorInfo.getChartConfigForUnits(units),
              "id": uuid.v4(),
            }
          };

          if (sensors && sensors.length) {
            var chartSensors = sensors[iVariableTypes];
            if (chartSensors) {
              chartGroup.sensors = chartSensors;
            }
          }

          chartGroup.chart.generatedChartConfig.series.push([{"id":"air_temp-0308c863-0e7c-413e-819c-e0ac51f5857e","name": eVariableTypes[0].title,"data":[],"pointStart":1522886400000,"pointInterval":86400000,"marker":{"enabled":false},"className":"graph-filter-series-color-default"}])
          chartGroup.chart.generatedChartConfig.yAxis.title.text = $i18next.t(eVariableTypes[0].title) + " (" + eVariableTypes[0].units + ")";
          //chartGroup.chart.generatedChartConfig.yAxis.title.textAlign = 'left';
          //chartGroup.chart.generatedChartConfig.yAxis.title.rotation = 0;
          chartGroup.chart.generatedChartConfig.yAxis.title.x = 10;
          //chartGroup.chart.generatedChartConfig.yAxis.title.x = 55;
          //chartGroup.chart.generatedChartConfig.yAxis.title.y = 79;
          chartGroup.chart.generatedChartConfig.yAxis.title.reserveSpace = false;
          // chartGroup.chart.generatedChartConfig.yAxis.title.zIndex = 1000001;
          chartGroup.chart.generatedChartConfig.yAxis.labels = {};
          chartGroup.chart.generatedChartConfig.yAxis.labels.align = 'left';
          chartGroup.chart.generatedChartConfig.yAxis.labels.x = 10;
          // chartGroup.chart.generatedChartConfig.yAxis.labels.zIndex = 100;
          chartGroup.chart.generatedChartConfig.yAxis.min = 0;
          chartGroup.chart.generatedChartConfig.yAxis.max = 0;
          chartGroup.chart.generatedChartConfig.yAxis.tickPosition = 'inside';
          chartGroup.chart.generatedChartConfig.legend.enabled = false;

          angular.forEach(eVariableTypes, function(eVariableType, iVariableType) {
            var index = chartGroup.availableVariableTypes.findIndex(function(vt) {
              return vt.id === eVariableType.id;
            });

            if (index >= 0) {
              chartGroup.availableVariableTypes.splice(index, 1);
            }
          });

          chartGroups.push(chartGroup);
        });

        return chartGroups;
      };
    }
  ]);
})(angular);
