try {
  angular.module('farmx-directives-sidenav');
} catch (err) {
  angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
}

FarmXGraphViewController.$inject = ["$window", "$rootScope", "$scope", "$timeout", "$farmXApi", "$farmXEntitiesCache", "$farmXGraphService", "$farmXSensorInfo", "$farmXUtilities", "$log", "$i18next"];

angular
  .module('farmx-directives-sidenav')
  .directive('farmxGraphView', FarmXGraphViewDirective)
  .controller('fzGraphViewController', FarmXGraphViewController)


function FarmXGraphViewDirective() {
  return {
    restrict: 'E',
    scope: {
      config:     '=config',
      settings:   '=settings'
    },
    templateUrl: 'graph/graph.template.html',
    controller: 'fzGraphViewController',
    controllerAs: 'ctrl',
    link: function link(scope, element, attrs, controller, transcludeFn) {
    },
  };
}

function FarmXGraphViewController($window, $rootScope, $scope, $timeout, $farmXApi, $farmXEntitiesCache, $farmXGraphService, $farmXSensorInfo, $farmXUtilities, $log, $i18next) {
  var ctrl = this;

  ctrl.loadData = [];
  ctrl.loadingData = [];
  ctrl.settings = $scope.settings ? $scope.settings : {
    showAddButton: true,
    defaultGraphSize: 200,
    defaultSmallGraphSize: 100
  };
  ctrl.graphHeight = $window.innerHeight >= 900 ? ctrl.settings.defaultGraphSize : ctrl.settings.defaultSmallGraphSize;
  ctrl.height = 200;
  ctrl.marginRight = 0;
  ctrl.alreadyUpdated = false;
  ctrl.configModified = false;
  ctrl.cgToUpdate = {};

  ctrl.addChartGroup = _addChartGroup;
  ctrl.removeChartGroup = _removeChartGroup;
  ctrl.scaleUpGraph = _scaleUpGraph;
  ctrl.scaleDownGraph = _scaleDownGraph;
  ctrl.downloadCsv = _downloadCsv;
  ctrl.getChartGroupTitle = _getChartGroupTitle;

  angular.element($window).bind('resize', function(){
    ctrl.graphHeight = $window.innerHeight >= 900 ? ctrl.settings.defaultGraphSize : ctrl.settings.defaultSmallGraphSize;

    angular.forEach($scope.config.chartGroups, function(eChartGroup, iChartGroup) {
      $updateHeight(eChartGroup);

      $generatedChartConfig(eChartGroup);
    });
  });

  $scope.$on("farmx.graph.redraw", function(event, configId) {
    if ($scope.config.id === configId) {
      if ($("#" + configId + " highchart").length > 0) {
        angular.forEach($('#' + configId + " highchart"), function(element, index) {
          var eChartGroupIndex = $scope.config.chartGroups.findIndex(function(eChartGroup) {
            return eChartGroup.id === element.id;
          });

          if (eChartGroupIndex >= 0) {
            var width = $(element).width() - 2;
            var height = $scope.config.chartGroups[eChartGroupIndex].height;
            if ($(element).highcharts != null) {
              $(element).highcharts().setSize(width, height, false);
            }
          }
        });
      }
    }
  });

  $scope.$on("farmx.sidenav.open", function(event, status) {
    angular.forEach($scope.config.chartGroups, function(eChartGroup, iChartGroup) {
      var eChartGroupId = eChartGroup.id;

      if ($("highchart#" + eChartGroupId).length > 0) {
        angular.forEach($("highchart#" + eChartGroupId), function(element, index) {
          var width = $(element).width() - 2;
          var height = $(element).height();

          if ($(element).highcharts != null) {
            $(element).highcharts().setSize(width, height, false);
          }
        });
      }
    });
  });

  $scope.$on('farmx.graph.internal.updated', function(event, ignored) {
    ctrl.loadData = [];
    ctrl.loading = true;
    $scope.config.chartGroups.modified = false;

    angular.forEach($scope.config.chartGroups, function(eChartGroup, iChartGroup) {
      eChartGroup.height = ctrl.graphHeight * eChartGroup.heightFactor;
      ctrl.height += eChartGroup.height;
      eChartGroup.chart.generatedChartConfig.chart.height = eChartGroup.height;
      eChartGroup.loading = true;
      if ($("#" + $scope.config.id + " highchart").length > 0) {
        if ($("#" + $scope.config.id + " highchart").highcharts() != null && $("#" + $scope.config.id + " highchart").highcharts().series > 0) {
          $("#" + $scope.config.id + " highchart").highcharts().series[0].remove();
        }
      }

      angular.forEach(eChartGroup.variableTypes, function(eVariableType, iVariableType) {
        if ($scope.config.graphForType === "Ranch") {
          ctrl.loadData.push({
            "chartGroup": eChartGroup,
            "graphFor": $scope.config.graphFor,
            "graphForType": $scope.config.graphForType,
            "variableType": eVariableType,
            "sensors": eChartGroup.sensors != null ? eChartGroup.sensors : undefined
          });
        } else if ($scope.config.graphForType === "Block") {
          ctrl.loadData.push({
            "chartGroup": eChartGroup,
            "graphFor": $scope.config.graphFor.blocks[0],
            "graphForType": $scope.config.graphForType,
            "variableType": eVariableType,
            "sensors": eChartGroup.sensors != null ? eChartGroup.sensors : undefined
          });
        }
      });
    });

    ctrl.loadingData = angular.copy(ctrl.loadData);

    $loadData(ctrl.loadData);
  });

  $scope.$watch("$scope.config.chartGroups", function(newValue, oldValue) {
    $rootScope.$broadcast('farmx.graph.internal.updated', 'changed');
  });

  $scope.$on('farmx.graph.internal.data.loaded', function(event, objectAdded) {
    var data = null;

    if (objectAdded.id === $scope.id) {
      data = objectAdded.data;
    } else {
      return;
    }

    if (data === null && ctrl.loadingData.length === 0) {
      angular.forEach($scope.config.chartGroups, function(eChartGroup, iChartGroup) {
        eChartGroup.loading = false;
        $generatedChartConfig(eChartGroup);
      });
    } else {
      var index = ctrl.loadingData.findIndex(function(element) {
        return (element.graphFor.id === data.graphFor.id && element.variableType.id === data.variableType.id);
      });

      if (index >= 0) {
        if (ctrl.cgToUpdate[ctrl.loadingData[index].chartGroup.id] === undefined) {
          ctrl.cgToUpdate[ctrl.loadingData[index].chartGroup.id] = ctrl.loadingData[index].chartGroup;
        }

        ctrl.loadingData.splice(index, 1);
      }

      if (ctrl.loadingData.length === 0) {
        angular.forEach($scope.config.chartGroups, function(eChartGroup, iChartGroup) {
          if (ctrl.cgToUpdate[eChartGroup.id] !== undefined) {
            eChartGroup.loading = false;
            $generatedChartConfig(eChartGroup);
          }
        });

        ctrl.cgToUpdate = {};
      }
    }
  });

  $scope.$on('farmx.graph.config.changed', function(event, data) {
    if (data === $scope.config.id) {
      $timeout(function() {
        $rootScope.$broadcast('farmx.graph.internal.updated', 'changed');
      },10);
    }
  });

  function _addChartGroup() {
    var sensors = [];
    $scope.config.graphFor.blocks.forEach(function(eBlock) {
      sensors = sensors.concat(eBlock.sensors);
    });

    $farmXGraphService.addGraphVariable($scope.config.graphForCapabilitiesByCategory, sensors)
      .then(function(result) {
        var variableTypesGroupByUnits = {};

        result.variableTypes.forEach(function(variableType) {
          if (variableTypesGroupByUnits[variableType.units] === undefined) {
            variableTypesGroupByUnits[variableType.units] = [];
          }

          variableTypesGroupByUnits[variableType.units].push(variableType);
        });

        var newChartGroups = $farmXGraphService.buildChartGroup(Object.values(variableTypesGroupByUnits), $scope.config.graphForCapabilitiesByUnits);

        angular.forEach(newChartGroups, function(eChartGroup, iChartGroup) {
          eChartGroup.height = ctrl.graphHeight * eChartGroup.heightFactor;
          var sensors = [];

          if (result.sensors != null) {
            angular.forEach(result.sensors, function(sensor) {
              if (eChartGroup.variableTypes != null && eChartGroup.variableTypes.length > 0) {
                if (eChartGroup.variableTypes[0].category.name === sensor.category.name) {
                  sensors.push(sensor);
                }
              }
            });
          }

          if (sensors.length > 0) {
            eChartGroup.sensors = sensors;
          }

          $scope.config.modified = true;

          $scope.config.chartGroups.push(eChartGroup);
        });

        $rootScope.$broadcast('farmx.graph.internal.updated', 'changed');
        $rootScope.$broadcast('farmx.graph.updated', 'changed');
      }, function(cancelled) {
      });
  }

  function _removeChartGroup(chartGroup) {
    var index = $scope.config.chartGroups.findIndex(function(eChartGroup) {
      return chartGroup.id === eChartGroup.id;
    });

    $scope.config.chartGroups.splice(index, 1);
    $scope.config.modified = true;

    $rootScope.$broadcast('farmx.graph.internal.updated', 'changed');
    $rootScope.$broadcast('farmx.graph.updated', 'changed');
  }

  function _scaleUpGraph(chartGroup) {
    chartGroup.height *= 2;
    chartGroup.heightFactor += 1;

    $scope.config.modified = true;
    $rootScope.$broadcast('farmx.graph.internal.updated', 'changed');
    $rootScope.$broadcast('farmx.graph.updated', 'changed');

    $generatedChartConfig(chartGroup);
  }

  function _scaleDownGraph(chartGroup) {
    if (chartGroup.height > ctrl.graphHeight) {
      chartGroup.heightFactor -= 1;

      $scope.config.modified = true;

      $rootScope.$broadcast('farmx.graph.internal.updated', 'changed');
      $rootScope.$broadcast('farmx.graph.updated', 'changed');

      chartGroup.height /= 2;
    }

    $generatedChartConfig(chartGroup);
  }

  function _downloadCsv(chartGroup) {
    var url = "#";
    if ($scope.config.graphForType == "Block") {
      url = $farmXApi.getBlockDataCsvUrl($scope.config.graphFor.blocks[0], $scope.config.dateRange.startDate, $scope.config.dateRange.endDate, chartGroup.variableTypes);
    } else {
      url = $farmXApi.getRanchDataCsvUrl($scope.config.graphFor, $scope.config.dateRange.startDate, $scope.config.dateRange.endDate, chartGroup.variableTypes);
    }

    $window.open(url, '_blank');
  }

  function _getChartGroupTitle(chartGroup) {
    if (chartGroup.sensors) {
      var sensorNames = chartGroup.sensors.map(function (sensor) { 
        return sensor.name;
      });
      return sensorNames.join(', ');
    }
    return "";
  }

  function $loadData(objectsAdded) {
    if (objectsAdded.length > 0) {
      angular.forEach(objectsAdded, function(objectAdded) {
        objectAdded.chartGroup.data = {};
        objectAdded.chartGroup.variableName = {};
        objectAdded.chartGroup.rawData = {};

        if (objectAdded.graphForType === "Ranch") {
          $farmXApi.getRanchData(objectAdded.graphFor, $scope.config.dateRange.startDate, $scope.config.dateRange.endDate, objectAdded.variableType, objectAdded.sensors).then(function(data) {
            $processData(objectAdded, data);
          }, function(error) {
            $processDataFailure(objectAdded, error);
          });
        } else if (objectAdded.graphForType === "Block") {
          $farmXApi.getBlockData(objectAdded.graphFor, $scope.config.dateRange.startDate, $scope.config.dateRange.endDate, objectAdded.variableType, objectAdded.sensors).then(function(data) {
            $processData(objectAdded, data);
          }, function(error) {
            $processDataFailure(objectAdded, error);
          });
        }
      });
    } else {
      $rootScope.$broadcast("farmx.graph.internal.data.loaded", {id: $scope.id, data: null});
    }
  }

  function $processData(objectAdded, data) {
    var sortedData = [];
    var entry;

    if (data[objectAdded.variableType.id] !== undefined && data[objectAdded.variableType.id].length > 0) {
      angular.forEach(data[objectAdded.variableType.id], function(eData, iData) {
        sortedData = eData.data.filter(function(a) {
          return a[0] >= $scope.config.dateRange.startDate.valueOf() && a[0] <= $scope.config.dateRange.endDate.valueOf();
        });

        sortedData = sortedData.sort(function(a, b) {
          return a[0] - b[0];
        });

        if (sortedData.length > 0) {
          if (sortedData[0][0] > $scope.config.dateRange.startDate.valueOf()) {
            entry = [
              $scope.config.dateRange.startDate.valueOf(),
              null
            ];

            sortedData.splice(0, 0, entry);
          }

          if (sortedData[sortedData.length-1][0] < $scope.config.dateRange.endDate.valueOf()) {
            entry = [
              $scope.config.dateRange.endDate.valueOf(),
              null
            ];

            sortedData.push(entry);
          }

          if (objectAdded.chartGroup.data === undefined || objectAdded.chartGroup.variableName === undefined) {
            objectAdded.chartGroup.data = {};
            objectAdded.chartGroup.variableName = {};
            objectAdded.chartGroup.rawData = {};
          }

          if (objectAdded.chartGroup.data[objectAdded.variableType.id] === undefined) {
            objectAdded.chartGroup.data[objectAdded.variableType.id] = [];
            objectAdded.chartGroup.variableName[objectAdded.variableType.id] = [];
            objectAdded.chartGroup.rawData[objectAdded.variableType.id] = [];
          }

          objectAdded.chartGroup.data[objectAdded.variableType.id].push(sortedData);
          objectAdded.chartGroup.variableName[objectAdded.variableType.id].push(eData.name);
          objectAdded.chartGroup.rawData[objectAdded.variableType.id].push(eData);
        } else {
          if (objectAdded.chartGroup.data === undefined || objectAdded.chartGroup.variableName === undefined) {
            objectAdded.chartGroup.data = {};
            objectAdded.chartGroup.variableName = {};
            objectAdded.chartGroup.rawData = {};
          }

          if (objectAdded.chartGroup.data[objectAdded.variableType.id] === undefined) {
            objectAdded.chartGroup.data[objectAdded.variableType.id] = [];
            objectAdded.chartGroup.variableName[objectAdded.variableType.id] = [];
            objectAdded.chartGroup.rawData[objectAdded.variableType.id] = [];
          }

          objectAdded.chartGroup.data[objectAdded.variableType.id].push([]);
          objectAdded.chartGroup.variableName[objectAdded.variableType.id].push(eData.name);
          objectAdded.chartGroup.rawData[objectAdded.variableType.id].push(eData);
        }
      });
    }

    $rootScope.$broadcast("farmx.graph.internal.data.loaded", {id: $scope.id, data: objectAdded});
  }

  function $processDataFailure(objectAdded, error) {
    objectAdded.chartGroup.data = {};
    objectAdded.chartGroup.variableName = {};
    objectAdded.chartGroup.rawData = {};

    objectAdded.chartGroup.data[objectAdded.variableType.id] = [];
    objectAdded.chartGroup.variableName[objectAdded.variableType.id] = null;
    objectAdded.chartGroup.rawData[objectAdded.variableType.id] = null;

    $rootScope.$broadcast("farmx.graph.internal.data.loaded", {id: $scope.id, data: objectAdded});
  }

  function $generatedChartConfig(chartGroup) {
    var eRanch = chartGroup.chart.ranch;
    ctrl.marginRight = 0;
    chartGroup.chart.generatedChartConfig = undefined;

    // {{tab.id}}-chart-area-{{chartGroup.id}}
    if (chartGroup.expanded === false) {
      chartGroup.chart.generatedChartConfig = $farmXSensorInfo.getChartConfigForUnits(chartGroup.units);
      chartGroup.chart.generatedChartConfig.chart.width = $('#' + $scope.config.id).width() - 2;
      chartGroup.chart.generatedChartConfig.chart.height = chartGroup.height;
      chartGroup.chart.generatedChartConfig.chart.marginRight = ctrl.marginRight;
      if (ctrl.marginRight === 0)
        chartGroup.chart.generatedChartConfig.legend.enabled = false;
      chartGroup.chart.generatedChartConfig.farmxDataLength = 0;

      if ($window.innerHeight < 900)
        chartGroup.chart.generatedChartConfig.yAxis.className = "highcharts-yaxis-mobile";
      else
        chartGroup.chart.generatedChartConfig.yAxis.className = "";

      if ($scope.config.graphForType === "Ranch") {
        $generatedSeries(chartGroup, $scope.config.graphFor, chartGroup.variableTypes, chartGroup.chart.generatedChartConfig);
      } else if ($scope.config.graphForType === "Block") {
        angular.forEach($scope.config.graphFor.blocks, function(eBlock, iBlock) {
          $generatedSeries(chartGroup, eBlock, chartGroup.variableTypes, chartGroup.chart.generatedChartConfig);
        });
      }
    } else {
      // Fix it later
      chartGroup.chart.generatedChartConfig = {};

      angular.forEach(chartGroup.chart.ranch.blocks, function(eBlock, iBlock) {
        chartGroup.chart.generatedChartConfig[eBlock.id] = $farmXSensorInfo.getChartConfigForUnits(chartGroup.units);
        chartGroup.chart.generatedChartConfig[eBlock.id].chart.width = $('#' + $scope.config.id).width() - 2;
        chartGroup.chart.generatedChartConfig[eBlock.id].chart.height = chartGroup.height;
        chartGroup.chart.generatedChartConfig[eBlock.id].chart.marginRight = ctrl.marginRight;
        if (ctrl.marginRight === 0)
          chartGroup.chart.generatedChartConfig[eBlock.id].legend.enabled = false;

        chartGroup.chart.generatedChartConfig[eBlock.id].farmxDataLength = 0;
        if ($window.innerWidth < 415)
          chartGroup.chart.generatedChartConfig[eBlock.id].yAxis.className = "highcharts-yaxis-mobile";
        else
          chartGroup.chart.generatedChartConfig[eBlock.id].yAxis.className = "";

        angular.forEach(chartGroup.chart.ranch.stations, function(eRanchStation, iRanchStation) {
          $generatedSeries(chartGroup, eRanchStation, chartGroup.chart.variableTypes, chartGroup.chart.generatedChartConfig[eBlock.id]);
        });

        angular.forEach(eBlock.stations, function(eBlockStation, iBlockStation) {
          $generatedSeries(chartGroup, eBlockStation, chartGroup.chart.variableTypes, chartGroup.chart.generatedChartConfig[eBlock.id]);
        });
      });
    }

    $log.log(chartGroup.chart.generatedChartConfig);
  }

  function $generatedSeries(chartGroup, graphFor, variableTypes, generatedChartConfig) {
    var isSoilMoisture = false;
    var isAirTemp = false;
    var lower = null;
    var middle = null;
    var middle_sum = 0;
    var upper = null;
    var middle_count = 0;
    var min = null;
    var max = null;

    var plotBands = [];
    var plotLines = [];

    angular.forEach(variableTypes, function(eVariableType, iVariableType) {
      if (chartGroup.data !== undefined && chartGroup.data[eVariableType.id] !== undefined && chartGroup.data[eVariableType.id].length > 0) {
        if (chartGroup.data[eVariableType.id] !== null) {
          angular.forEach(chartGroup.data[eVariableType.id], function(eData, iData) {
            var block = $farmXEntitiesCache.findBlockById(chartGroup.rawData[eVariableType.id][iData].block_id);
            generatedChartConfig.farmxDataLength += eData.length;            
            var newTitle = $i18next.t(eVariableType.title) + " (" + eVariableType.units + ")";
            $log.info("Old title", generatedChartConfig.yAxis.title.text);
            $log.info("New title", newTitle);
            generatedChartConfig.yAxis.title.text = newTitle;

            if (block !== null) {
              var className = eVariableType.className;

              if (eVariableType.id === "soil_moisture_rootzone_vwc") {
                className = "graph-filter-series-selected-soil-moisture";
              }

              generatedChartConfig.series.push({
                "id": chartGroup.id + "-" + eVariableType.id + "-" + chartGroup.rawData[eVariableType.id][iData].sensor_id,
                "name": chartGroup.rawData[eVariableType.id][iData].sensor_name + " " + $i18next.t(chartGroup.rawData[eVariableType.id][iData].name) + " (" + chartGroup.rawData[eVariableType.id][iData].sensor_id + ")",
                "data": eData,
                "pointStart": Date.UTC($scope.config.dateRange.startDate.year(), $scope.config.dateRange.startDate.month(), $scope.config.dateRange.startDate.date(),0,0,0,0),
                "pointInterval": 24 * 3600 * 1000,
                "marker": {
                  "enabled": false
                },
                "className": className
              });

              if (min !== null) {
                if (chartGroup.rawData[eVariableType.id][iData].min !== null) {
                  min = Math.min(min, chartGroup.rawData[eVariableType.id][iData].min);
                }
              } else {
                min = chartGroup.rawData[eVariableType.id][iData].min;
              }

              if (max !== null) {
                if (chartGroup.rawData[eVariableType.id][iData].max !== null) {
                  max = Math.max(max, chartGroup.rawData[eVariableType.id][iData].max);
                }
              } else {
                max = chartGroup.rawData[eVariableType.id][iData].max;
              }

              if (eVariableType.id === 'air_temp') {
                isAirTemp = true;
              }

              if (eVariableType.id === 'soil_moisture_rootzone_vwc') {
                isSoilMoisture = true;

                // these aggregates are kept from graphs with more than one rootzone_vwc (or multiple sensors)
                if (lower !== null) {
                  if (chartGroup.rawData[eVariableType.id][iData].moisture_zone_lower !== null) {
                    lower = Math.min(lower, chartGroup.rawData[eVariableType.id][iData].moisture_zone_lower);
                  }
                } else {
                  lower = chartGroup.rawData[eVariableType.id][iData].moisture_zone_lower;
                }

                if (middle !== null) {
                  if (chartGroup.rawData[eVariableType.id][iData].moisture_zone_middle !== null) {
                    middle_sum += chartGroup.rawData[eVariableType.id][iData].moisture_zone_middle;
                  }
                } else {
                  middle_sum = Math.max(middle, chartGroup.rawData[eVariableType.id][iData].moisture_zone_middle);
                }

                middle_count += 1.0;
                middle = middle_sum / middle_count;

                if (upper !== null) {
                  if (chartGroup.rawData[eVariableType.id][iData].moisture_zone_upper !== null) {
                    upper = Math.min(upper, chartGroup.rawData[eVariableType.id][iData].moisture_zone_upper);
                  }
                } else {
                  upper = chartGroup.rawData[eVariableType.id][iData].moisture_zone_upper;
                }
              }
            }
          });

          if (generatedChartConfig.xAxis.plotBands.length === 0) {
            // for(var i=0;i <= $scope.config.dateRange.shiftBy.number;i++) {
            let end = moment($scope.config.dateRange.endDate);
            let start = moment($scope.config.dateRange.startDate);
            let days = end.diff(start, 'days') + 1;
            for(var i=0;i <= days;i++) {
              generatedChartConfig.xAxis.plotBands.push({
                from: new Date($scope.config.dateRange.startDate.year(), $scope.config.dateRange.startDate.month(), $scope.config.dateRange.startDate.date() + i),
                to: new Date($scope.config.dateRange.startDate.year(), $scope.config.dateRange.startDate.month(), $scope.config.dateRange.startDate.date() + i + 1)
              });
            }
          }

          var range = max - min;

          var graphMin = min == null ? lower : min;
          var graphMax = max == null ? upper : max;

          if (generatedChartConfig.yAxis.min != null) {
            generatedChartConfig.yAxis.min = generatedChartConfig.yAxis.min < graphMin ? generatedChartConfig.yAxis.min : graphMin;
          } else if (graphMin != null) {
            generatedChartConfig.yAxis.min = graphMin;
          }

          generatedChartConfig.yAxis.max = generatedChartConfig.yAxis.max > graphMax ? generatedChartConfig.yAxis.max : graphMax;

          if (isSoilMoisture) {
            plotLines = [];
            plotBands = [];

            plotBands.push({
              from: 0.0,
              to: lower,
              className: 'farmxRangeBand-red'
            });

            plotLines.push({
              value: lower,
              className: 'plotline-lower-bound'
            });

            plotLines.push({
              value: middle,
              className: 'plotline-middle-bound'
            });

            plotLines.push({
              value: upper,
              className: 'plotline-upper-bound'
            });

            plotBands.push({
              from: upper,
              to: 1.0,
              className: 'farmxRangeBand-light-blue'
            });

            generatedChartConfig.yAxis.min = generatedChartConfig.yAxis.min < lower ? generatedChartConfig.yAxis.min : lower;
            generatedChartConfig.yAxis.max = generatedChartConfig.yAxis.max > upper ? generatedChartConfig.yAxis.max : upper;
          }

          if (isAirTemp) {
            plotLines.push({
              value: 32,
              className: 'plotline-upper-bound'
            });

            plotLines.push({
              value: 45,
              className: 'plotline-upper-bound'
            });

            if (generatedChartConfig.yAxis.min != null) {
              generatedChartConfig.yAxis.min = generatedChartConfig.yAxis.min < 30 ? generatedChartConfig.yAxis.min : 30;
            } else {
              generatedChartConfig.yAxis.min = 30;
            }

            generatedChartConfig.yAxis.max = generatedChartConfig.yAxis.max > 47 ? generatedChartConfig.yAxis.max : 47;
          }
        }
      } else {
        generatedChartConfig.yAxis.title.text = $i18next.t(eVariableType.title) + " (" + eVariableType.units + ")";
        var className = eVariableType.className;

        generatedChartConfig.series.push({
          "id": eVariableType.id + "-" + uuid.v4(),
          "name": $i18next.t(eVariableType.title),
          "data": [],
          "pointStart": Date.UTC($scope.config.dateRange.startDate.year(), $scope.config.dateRange.startDate.month(), $scope.config.dateRange.startDate.date(),0,0,0,0),
          "pointInterval": 24 * 3600 * 1000,
          "marker": {
            "enabled": false
          },
          "className": className
        });

        if (generatedChartConfig.xAxis.plotBands.length === 0) {
          // for(var i=0;i <= $scope.config.dateRange.shiftBy.number;i++) {
          let end = moment($scope.config.dateRange.endDate);
          let start = moment($scope.config.dateRange.startDate);
          let days = end.diff(start, 'days') + 1;
          for(var i=0;i <= days;i++) {
            generatedChartConfig.xAxis.plotBands.push({
              from: new Date($scope.config.dateRange.startDate.year(), $scope.config.dateRange.startDate.month(), $scope.config.dateRange.startDate.date() + i),
              to: new Date($scope.config.dateRange.startDate.year(), $scope.config.dateRange.startDate.month(), $scope.config.dateRange.startDate.date() + i + 1)
            });
          }
        }
      }
    });

    var currentTitle = generatedChartConfig.yAxis.title.text;
    if (currentTitle.includes('Soil Moisture') && variableTypes.length > 1) {
      generatedChartConfig.yAxis.title.text = "Soil Moisture";

      if (chartGroup.sensors != null && chartGroup.sensors.length == 1) {
        generatedChartConfig.yAxis.title.text += " (" + chartGroup.sensors[0].name + ")";
      }
    }

    // generatedChartConfig.yAxis.title.x = 35;
    generatedChartConfig.yAxis.plotBands = plotBands;
    generatedChartConfig.yAxis.plotLines = plotLines;
    generatedChartConfig.yAxis.startOnTick = false;
  }

  function $updateHeight(chartGroup) {
    if (chartGroup.expanded) {
      ctrl.height -= chartGroup.height;
      chartGroup.height = Object.keys(chartGroup.chart.generatedChartConfig).length * ctrl.graphHeight * chartGroup.heightFactor;
      ctrl.height += chartGroup.height;
    } else {
      ctrl.height -= chartGroup.height;
      chartGroup.height = ctrl.graphHeight * chartGroup.heightFactor;
      ctrl.height += chartGroup.height;
    }
  }

  this.$onInit = function() {
    $scope.id = uuid.v4();
  };
}
