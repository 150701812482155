(function(angular){
    'use strict';

    try {
      angular.module('farmx-directives-sidenav');
    } catch (err) {
      angular.module('farmx-directives-sidenav', [ 'ngMaterial' ]);
    }

    angular.module('farmx-directives-sidenav')
    .directive('farmxSatelliteMapView', function($compile) {
      // var mapId = global.uuid.v4();

      return {
        restrict: 'E',
        scope: {
          selected:   '=selected',
          scrollWheelZoom: '=scrollwheelzoom',
          soilLayer: '=soillayer',
          viewerId: '@viewerid',
          drawable: '='
        },
        templateUrl: 'satelliteBrowser/satelliteMap.template.html',
        controller: 'fzSatelliteMapViewController',
        controllerAs: 'ctrl',
      };
    }).controller('fzSatelliteMapViewController', function ($rootScope, $scope, $element, $timeout, leafletData, $window, $farmXEntitiesCache, $farmXUtilities, $farmXSensorInfo, $log) {
      var ctrl = this;

      ctrl.mapViewConfig = {
        controls: {
          soilMode: {
            control: SoilModeControl
          },
          opacityControl: {
            control: OverlayOpacityControl
          },
        }
      };

      $scope.mapId = global.uuid.v4();
      $scope.events = {
        map: {
          enable: [ 'click', 'dblclick', 'mousedown', 'mouseup', 'mouseover', 'mouseout', 'mousemove', 'contextmenu', 'focus', 'blur', 'preclick', 'load', 'unload', 'viewreset', 'movestart', 'move', 'moveend', 'dragstart', 'drag', 'dragend', 'zoomstart', 'zoomend', 'zoomlevelschange', 'resize', 'autopanstart', 'layeradd', 'layerremove', 'baselayerchange', 'overlayadd', 'overlayremove', 'locationfound', 'locationerror', 'popupopen', 'popupclose' ],
          logic: 'emit'
        }
      };
      $scope.mapDefaults = {
        maxZoom:20,
        maxNativeZoom:20,
        showOnSelector: false,
        editable: true,
        attributionControl: false,
        zoomControl: false,
        zoomControlPosition: 'bottomright',
        scrollWheelZoom: false,
        touchZoom: false,
        dragging: false,
        doubleClickZoom: false,
      };
      $scope.mapControls = {};
      $scope.mapLayers = {
        baselayers: {
          mapbox_light: {
            name: 'FarmMap',
            url:'https://api.mapbox.com/styles/v1/farmx/cinjdgdic0015adnjk1bl7775/tiles/{z}/{x}/{y}?access_token={apikey}',
            type: 'xyz',
            layerOptions: {
              apikey: 'pk.eyJ1IjoiZmFybXgiLCJhIjoiY2lnODkydzJpMDFlanR6bTdjOXB6MDJyMSJ9.w2lYGZBvj_OP5jbiK4KRAw',
              mapid: 'farmx.cinjdgdic0015adnjk1bl7775',
              maxNativeZoom:18,
              maxZoom:20,
            },
            layerParams: {
              showOnSelector: false
            }
          },
        },
        controls: {
          draw: {}
        },
        overlays: {
          satData: {
            name: 'satData',
            type: 'group',
            visible: true,
            layerParams: {
              showOnSelector: false
            }
          },
          boundaries: {
            name: 'boundaries',
            type: 'group',
            visible: true,
            layerParams: {
              showOnSelector: false
            }
          },
          anomalies: {
            name: 'anomalies',
            type: 'featureGroup',
            visible: true,
            layerParams: {
              showOnSelector: false
            }
          }
        }
      };

      ctrl.getYieldColor = function(value) {
        if (value < 8) {
          return { color: "rgb(226, 53, 73)" };
        } else if (value < 12) {
          return { color: "rgb(255, 204, 0)" };
        } else {
          return { color: "rgb(61, 200, 115)" };
        }
      };

      ctrl.getYieldData = function() {
        if (!ctrl.selected || ctrl.selected.type != "Block") return null;
        let block = ctrl.selected.value[1];
        let yieldData = block.yieldData;

        if (!ctrl.dateSelected) return null;
        if (!block.yieldData) return null;

        let date = new Date(ctrl.dateSelected * 1000);
        let month = date.toLocaleString('en-us', { month: 'long' });
        let year = date.getFullYear();

        let yearPrediction = yieldData[year];
        if (!yearPrediction) return null;

        let monthPrediction = yearPrediction[month]
        if (!monthPrediction) return null;
        if (!monthPrediction[0]) return null;

        let yieldStr = monthPrediction[0] + '-' + monthPrediction[1];
        let yieldAvg = (monthPrediction[0] + monthPrediction[1]) / 2.0;
        let yieldColor = ctrl.getYieldColor(yieldAvg);
        let yieldActual = yearPrediction["Actual"];
        let yieldActualColor = "";
        if (yieldActual)
          yieldActualColor = ctrl.getYieldColor(yieldActual);
        return {
          yieldPrediction: yieldStr,
          yieldActual: yieldActual,
          yieldStyle: yieldColor,
          yieldActualStyle: yieldActualColor
        };
      }

      ctrl.map = null;
      ctrl.layers = null;
      ctrl.zoomLevel = 1;
      ctrl.selected = {};
      ctrl.viewModel = "Ranch";
      ctrl.homeBound = [];
      ctrl.satelliteImageryOpacity = 0.8;
      ctrl.previousSelectedType = null;
      ctrl.imageLayers = [];
      ctrl.anomaly = null;
      ctrl.anomalyZoom = false;

      ctrl.dateSelected = null;

      ctrl.drawHandler = null;
      ctrl.editHandler = null;

      ctrl.onDrawEventCreated = _onDrawEventCreated;
      ctrl.onDrawEventEdited = _onDrawEventEdited;
      ctrl.onDrawEventDeleted = _onDrawEventDeleted;
      ctrl.onDrawEventDrawStart = _onDrawEventDrawStart;
      ctrl.onDrawEventDrawStop = _onDrawEventDrawStop;
      ctrl.onDrawEventDrawVertex = _onDrawEventDrawVertex;
      ctrl.onDrawEventEditStart = _onDrawEventEditStart;
      ctrl.onDrawEventEditMove = _onDrawEventEditMove;
      ctrl.onDrawEventEditResize = _onDrawEventEditResize;
      ctrl.onDrawEventEditVertex = _onDrawEventEditVertex;
      ctrl.onDrawEventEditStop = _onDrawEventEditStop;
      ctrl.onDrawEventDeleteStart = _onDrawEventDeleteStart;
      ctrl.onDrawEventDeleteStop = _onDrawEventDeleteStop;
      ctrl.onDrawEventToolbarOpened = _onDrawEventToolbarOpened;
      ctrl.onDrawEventToolbarClosed = _onDrawEventToolbarClosed;
      ctrl.onDrawEventMarkerContext = _onDrawEventMarkerContext;

      angular.element($window).bind('resize', function() {
        $scope.$apply(function () {
          leafletData.getMap($scope.mapId).then(function(map) {
            map.invalidateSize();
            redrawMap();
          });
        });
      });

      // $scope.$on("leafletDirectiveMap." + $scope.mapId + ".click", function(eventName, toSend) {
      //   var clickHandler = $(toSend.leafletEvent.originalEvent.target.closest('.leaflet-clickable')).data('control-id');
      //
      //   if (clickHandler != null) {
      //     angular.forEach(ctrl.mapViewConfig.controls, function(value, key) {
      //       if (key === clickHandler) {
      //         value.widgetConfig.onClick(ctrl.map, toSend.leafletEvent);
      //       }
      //     });
      //   }
      // });

      $scope.$on('farmx.sidenav.selected', function(event, selected) {
        ctrl.selected = selected;
      });

      $scope.$on('farmx.map.selected', function(event, selected) {
        ctrl.selected = selected;
      });

      $scope.$on('farmx.anomaly.selected', function(event, anomaly) {
        ctrl.anomaly = anomaly.value;
        ctrl.anomalyZoom = true;
        redrawMap();
      });

      $scope.$on('farmx.anomaly.deleted', function(event, anomaly) {
        var index = anomaly.blockObject.anomalies.findIndex(function(ele) {
          return ele.id === anomaly.id
        });

        if (anomaly.id === ctrl.anomaly.id) {
          ctrl.anomaly = null;
        }

        if (index >= 0) {
          anomaly.blockObject.anomalies.splice(index, 1);
        }

        redrawMap();
      });

      $scope.$on('farmx.anomaly.edit.start', function(event, anomaly) {
        if ($scope.drawable) {
          try {
            ctrl.editHandler.enable();
          } catch (e) {
          }
        }
      });

      $scope.$on('farmx.anomaly.edit.stop', function(event, anomaly) {
        if ($scope.drawable) {
          ctrl.editHandler.save();
          ctrl.editHandler.disable();
        }
      });

      $scope.$on('farmx.anomaly.edit.cancel', function(event, anomaly) {
        if ($scope.drawable) {
          ctrl.editHandler.revertLayers();
          ctrl.editHandler.disable();
        }
      });

      $scope.$on('farmx.anomaly.draw.start', function(event, anomaly) {
        try {
          ctrl.drawHandler.enable();
        } catch (e) {
        }
      });

      $scope.$on('farmx.anomaly.draw.stop', function(event, anomaly) {
        ctrl.drawHandler.disable();
      });

      $scope.$on('farmx.anomaly.draw.cancel', function(event, anomaly) {
        ctrl.drawHandler.disable();
      });

      $scope.$on('farmx.anomaly.zoom', function(event, anomaly) {
        ctrl.anomalyZoom = !ctrl.anomalyZoom;
        redrawMap();
      });

      $scope.$on('farmx.anomaly.loaded', function(event, anomaly) {
        drawAnomalies();
      });

      $scope.$on('farmx.map.tab.toggle', function(event, anomaly) {
        $timeout(function() {
          leafletData.getMap($scope.mapId).then(function(map) {
            map.invalidateSize();
            redrawMap();
          });
        }, 100);
      });

      $scope.$on('farmx.satellite.selected', function(event, satelliteObject) {
        var satelliteDataArray = satelliteObject.value;

        if (satelliteObject.viewerId !== undefined && satelliteObject.viewerId != $scope.viewerId) {
            return;
        }

        if (ctrl.layers != undefined)
          ctrl.layers.overlays.satData.clearLayers();

        ctrl.dateSelected = null;

        if (satelliteDataArray === null) return;

        if (satelliteDataArray.length) {
          ctrl.dateSelected = satelliteDataArray[0].date;
        }

        ctrl.imageLayers = [];
        angular.forEach(satelliteDataArray, function(satelliteData, satelliteDataIndex) {
          var latlng = L.GeoJSON.coordsToLatLngs(JSON.parse(satelliteData.bounds).coordinates,1);
          var bounds = new L.LatLngBounds(latlng);
          var imageUrl = satelliteData.png_url;
          var options = {
            opacity: ctrl.satelliteImageryOpacity,
            alt: "satellite imagery",
          };
          var imageLayer = new L.imageOverlay(imageUrl, bounds, options);
          ctrl.imageLayers.push(imageLayer);
        });
        redrawMap();

      });

      $scope.$watch('ctrl.selected', function () {
        redrawMap();
      });

      function redrawMap () {
        $timeout(function() {
          _redrawMap();
        }, 0);
      }

      function _redrawMap() {
        ctrl.yieldData = ctrl.getYieldData();

        if (ctrl.map == null) {
          return;
        }

        drawBoundaries();
        drawImagery();
        drawAnomalies();

        var options = { animate: false };
        if (ctrl.anomaly == null || ctrl.anomalyZoom == false) {
          if (ctrl.selected != null && ctrl.selected.value != null) {
            ctrl.map.fitBounds(new L.LatLngBounds(L.GeoJSON.coordsToLatLngs(ctrl.selected.value[ctrl.selected.value.length - 1].bounds.coordinates,1)), options);
          }
        } else {
            ctrl.map.fitBounds(new L.LatLngBounds(L.GeoJSON.coordsToLatLngs(ctrl.anomaly.bounds.coordinates,1)), options);
        }
      }

      function drawImagery() {
        ctrl.layers.overlays.satData.clearLayers();
        angular.forEach(ctrl.imageLayers, function(imageLayer, index) {
            ctrl.layers.overlays.satData.addLayer(imageLayer);
        });
      }

      function drawBoundaries() {
        if (ctrl.selected.value === undefined) return;
        var selected = ctrl.selected.value[ctrl.selected.value.length - 1];
        var layer = new L.GeoJSON(selected.layerGeoJSON);
        layer.setStyle($layerStyle(selected.state, 3, 1, 0.0));

        ctrl.layers.overlays.boundaries.clearLayers();
        ctrl.layers.overlays.boundaries.addLayer(layer);
      }

      function _getAnomalies() {
          if (!ctrl.selected) return [];
          if (ctrl.selected.type == "Block") {
            if (ctrl.selected.value[1].anomalies !== undefined)
                return ctrl.selected.value[1].anomalies;
            return [];
          } else if (ctrl.selected.type == "Ranch") {
            var anomalies = [];
            angular.forEach(ctrl.selected.value[0].blocks, function(block) {
                if (block.anomalies !== undefined)
                    anomalies = anomalies.concat(block.anomalies);
            });
            return anomalies;
          }
          return [];
      }

      function drawAnomalies() {
        ctrl.layers.overlays.anomalies.clearLayers();

        if (ctrl.anomaly == null) {
            var anomalies = _getAnomalies();

            if (anomalies.length) {
                angular.forEach(anomalies, function (anomaly) {
                  var latlng = new L.LatLngBounds(L.GeoJSON.coordsToLatLngs(anomaly.bounds.coordinates,1)).getCenter();
                  var marker = _anomalyMarker(latlng, "icon-ranch", anomaly.anomaly_type);
                  marker.on('click', function(e) {
                      $rootScope.$broadcast('farmx.anomaly.clicked', {
                        "value": anomaly,
                      });
                  });
                  ctrl.layers.overlays.anomalies.addLayer(marker);
                });
            }
        } else {
            var anomaly = ctrl.anomaly;
            var layer = new L.Polygon(L.GeoJSON.coordsToLatLngs(anomaly.bounds.coordinates,1)[0], {
              "color":"#3388ff",
              "dashArray": "8 8",
              "weight": 3,
              "opacity": 1.0,
              "fillColor": "#000000",
              "fillOpacity":0.0,
              "editing": {
                "dashArray":"10, 10",
                "fill":true,
                "fillColor":"#fe57a1",
                "fillOpacity":0.1,
                "maintainColor":false
              }
            });
            layer._bounds = layer.getBounds();
            ctrl.layers.overlays.anomalies.addLayer(layer);
        }
      }

      function $layerStyle(state, weight, opacity, fillOpacity) {
        return function(feature) {
          return {
            "color": $farmXUtilities.stateColorCode[state.state].fillColor,
            "fillColor": $farmXUtilities.stateColorCode[state.state].fillColor,
            "opacity": opacity === undefined ? 1 : opacity,
            "fillOpacity": 0.0,
            "weight": weight,
            "dashArray": [],
          };
        };
      }

      function _anomalyStyle(weight, opacity, fillOpacity) {
        return function(feature) {
          return {
            "color": '#000000',
            "fillColor": '#000000',
            "opacity": 1.0,
            "fillOpacity": 0.0,
            "weight": weight,
            "dashArray": "8 8",
          };
        };
      }

      function _getAnomalyIconColor(type) {
        if (type == "disease")
          return '#000000';
        if (type == "missing")
          return '#3dc873';
        if (type == "irrigation")
          return '#429aff';
        return '#999999';
      }

      function _getAnomalyIcon(type) {
        if (type == "disease")
          return 'icon-air';
        if (type == "missing")
          return 'icon-tree';
        if (type == "irrigation")
          return 'icon-waterdrop';
        return 'icon-sound';
      }

      function _anomalyMarker(latlng, icon, type) {
        var markerIcon;

        markerIcon = L.VectorMarkers.icon({
            "prefix": 'icon',
            "icon": _getAnomalyIcon(type),
            "iconColor": '#ffffff',
            "markerColor": _getAnomalyIconColor(type),
        });

        var marker = new L.marker(latlng, { "icon": markerIcon });
        return marker;
      }

      this.$onInit = function() {
        var _this = this;

        ctrl.selected = $scope.selected;

        if (ctrl.selected.type !== undefined)
          ctrl.viewMode = ctrl.selected.type;
        else
          ctrl.viewMode = "Ranch";

        leafletData.getMap($scope.mapId).then(function(map) {
          $timeout(function() {
            ctrl.map = map;

            // if (ctrl.mapViewConfig && ctrl.mapViewConfig.controls) {
            //   angular.forEach(ctrl.mapViewConfig.controls, function(value, key) {
            //     value.widgetConfig = value.control(value);
            //
            //     if (value.widgetConfig.beforeRender)
            //       value.widgetConfig.beforeRender(ctrl.map);
            //
            //     map.addControl(value.widgetConfig.control);
            //
            //     if (value.widgetConfig.afterRender)
            //       value.widgetConfig.afterRender(ctrl.map);
            //   });
            // }

            ctrl.map.on(L.Draw.Event.CREATED, (event) => _this.onDrawEventCreated(event));
            ctrl.map.on(L.Draw.Event.EDITED, (event) => _this.onDrawEventEdited(event));
            ctrl.map.on(L.Draw.Event.DELETED, (event) => _this.onDrawEventDeleted(event));
            ctrl.map.on(L.Draw.Event.DRAWSTART, (event) => _this.onDrawEventDrawStart(event));
            ctrl.map.on(L.Draw.Event.DRAWSTOP, (event) => _this.onDrawEventDrawStop(event));
            ctrl.map.on(L.Draw.Event.DRAWVERTEX, (event) => _this.onDrawEventDrawVertex(event));
            ctrl.map.on(L.Draw.Event.EDITSTART, (event) => _this.onDrawEventEditStart(event));
            ctrl.map.on(L.Draw.Event.EDITMOVE, (event) => _this.onDrawEventEditMove(event));
            ctrl.map.on(L.Draw.Event.EDITRESIZE, (event) => _this._onDrawEventEditResize(event));
            ctrl.map.on(L.Draw.Event.EDITVERTEX, (event) => _this.onDrawEventEditVertex(event));
            ctrl.map.on(L.Draw.Event.EDITSTOP, (event) => _this.onDrawEventEditStop(event));
            ctrl.map.on(L.Draw.Event.DELETESTART, (event) => _this.onDrawEventDeleteStart(event));
            ctrl.map.on(L.Draw.Event.DELETESTOP, (event) => _this.onDrawEventDeleteStop(event));
            ctrl.map.on(L.Draw.Event.TOOLBAROPENED, (event) => _this.onDrawEventToolbarOpened(event));
            ctrl.map.on(L.Draw.Event.TOOLBARCLOSED, (event) => _this.onDrawEventToolbarClosed(event));
            ctrl.map.on(L.Draw.Event.MARKERCONTEXT, (event) => _this.onDrawEventMarkerContext(event));

            map.invalidateSize();

            if ($scope.soilLayer == null || $scope.soilLayer === true) {
              ctrl.soilLayer = new L.TileLayer('https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}', {
                  "name":             "soil",
                  "pane":             "overlays",
                  "maxZoom":          19,
                  "minZoom":          0,
                  "showOnSelector":   false
              });

              ctrl.soilLayer.addTo(map);
            }

            ctrl.drawHandler = new L.Draw.Polygon(ctrl.map, {
              "showArea": true,
              "allowIntersection": false,
              "color":"#3388ff",
              "dashArray": "8 8",
              "weight": 3,
              "opacity": 1.0,
              "fillColor": "#000000",
              "fillOpacity":0.0,
              "shapeOptions": {
                "stroke": true,
          			"color": '#3388ff',
          			"weight": 3,
          			"opacity": 1.0,
                "fillColor": "#000000",
                "fillOpacity": 0.0,
                "dashArray": "8 8",
          			"fill": false,
          			"clickable": false,
                "editing": {
                  "dashArray":"10, 10",
                  "fill":true,
                  "fillColor":"#fe57a1",
                  "fillOpacity":0.1,
                  "maintainColor":false
                }
              },
            });

            ctrl.editHandler = new L.EditToolbar.Edit(ctrl.map, {
              "featureGroup": ctrl.layers.overlays.anomalies,
              "dashArray": "10, 10",
              "fill": true,
              "fillColor": "#fe57a1",
              "fillOpacity" :0.1,
              "maintainColor": false,
              "selectedPathOptions": {
                "dashArray":"10, 10",
                "fill":true,
                "fillColor":"#fe57a1",
                "fillOpacity":0.1,
                "maintainColor":false
              },
              "shapeOptions": {
                "stroke": true,
          			"color": '#3388ff',
          			"weight": 3,
          			"opacity": 1.0,
                "fillColor": "#000000",
                "fillOpacity": 0.0,
                "dashArray": "8 8",
          			"fill": false,
          			"clickable": false,
              },
            });

            $timeout(function() {
              redrawMap();
            }, 100);
          }, 0);

          leafletData.getLayers($scope.mapId).then(function(layers) {
            console.log("getLayers successful");
            ctrl.layers = layers;
          });
        });
      };

      function _onDrawEventCreated(event) {
        var layer = event.layer;

        ctrl.layers.overlays.anomalies.addLayer(layer);

        $rootScope.$broadcast('farmx.anomaly.draw.complete', layer );
      }

      function _onDrawEventEdited(event) {
        var layers = event.layers;
        layers.eachLayer(function(layer) {
          ctrl.anomaly.bounds.coordinates[0] = layer.toGeoJSON().geometry.coordinates;
          ctrl.anomaly.center.coordinates[0] = layer.getBounds().getCenter().lng;
          ctrl.anomaly.center.coordinates[1] = layer.getBounds().getCenter().lat;

          $rootScope.$broadcast('farmx.anomaly.edit.complete', ctrl.anomaly);
        });
      }

      function _onDrawEventDeleted(event) {
        console.log("onDrawEventDeleted", event);
      }

      function _onDrawEventDrawStart(event) {
        console.log("onDrawEventDrawStart", event)
      }

      function _onDrawEventDrawStop(event) {
        console.log("onDrawEventDrawStop", event)
      }

      function _onDrawEventDrawVertex(event) {
        console.log("onDrawEventDrawVertex", event)
      }

      function _onDrawEventEditStart(event) {
        console.log("onDrawEventEditStart", event)
      }

      function _onDrawEventEditMove(event) {
        console.log("onDrawEventEditMove", event)
      }

      function _onDrawEventEditResize(event) {
        console.log("_onDrawEventEditResize", event)
      }

      function _onDrawEventEditVertex(event) {
        console.log("onDrawEventEditVertex", event)
      }

      function _onDrawEventEditStop(event) {
        console.log("onDrawEventEditStop", event)
      }

      function _onDrawEventDeleteStart(event) {
        console.log("onDrawEventDeleteStart", event)
      }

      function _onDrawEventDeleteStop(event) {
        console.log("onDrawEventDeleteStop", event)
      }

      function _onDrawEventToolbarOpened(event) {
        console.log("onDrawEventToolbarOpened", event)
      }

      function _onDrawEventToolbarClosed(event) {
        console.log("onDrawEventToolbarClosed", event)
      }

      function _onDrawEventMarkerContext(event) {
        console.log("onDrawEventMarkerContext", event)
      }

      function SoilModeControl(value) {
        var Control = L.Control.extend({
          options: {
            position: 'topright'
          },

          onAdd: function(map) {
            var container = L.DomUtil.create("div","leaflet-control-zoom leaflet-bar leaflet-control farmx-leaflet-control farmx-map-soil-mode leaflet-clickable");
            $(container).data('control-id', 'soilMode');

            $(container).append('<div class="layout-row layout-align-space-between-center flex-100">' +
              '<input style="margin: 5px" type="checkbox" id="soilModeCheck">' +
              '<div style="margin-right: 5px">' +
                '<img width="18px" height="18px" style="margin-right: 5px;" src="images/soil.svg"/><span>Soil</span>' +
              '</div>' +
            '</div>');

            return container;
          }
        });

        function beforeRender(map) {
        }

        function afterRender(map) {
        }

        function onClick(map) {
          if ($('#soilModeCheck').prop('checked') === false) {
            if (ctrl.soilLayer !== undefined && ctrl.soilLayer !== null) {
              map.removeLayer(ctrl.soilLayer);
              ctrl.soilLayer = null;
            }
          } else {
            ctrl.soilLayer = new L.TileLayer('https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}', {
              "name":             "soil",
              "pane":             "overlays",
              "maxZoom":          19,
              "minZoom":          0,
              "showOnSelector":   false
            });
            ctrl.soilLayer.addTo(map);
          }
        }

        return {
          control: new Control(),
          beforeRender: beforeRender,
          afterRender: afterRender,
          onClick: onClick
        };
      }

      function OverlayOpacityControl(value) {
        var Control = L.Control.extend({
          options: {
            position: 'topright'
          },

          onAdd: function(map) {
            var container = L.DomUtil.create("div","leaflet-bar leaflet-control leaflet-slide-control");
            L.DomEvent.disableClickPropagation(container);

            if ($('.satellite-data-item.selected').length > 0) {
              var sliderControl = angular.element('<md-slider ng-model="ctrl.opacity" min="0" max="100" aria-label="opacity" id="opacity-slider" class="md-accent" md-vertical="" md-range=""></md-slider>');

              $(container).append(sliderControl);
              $compile(sliderControl)($scope);
            }

            return container;
          }
        });

        function beforeRender(map) {
        }

        function afterRender(map) {
          var _map = map;
          _update($scope.$parent.ctrl.selected);

          $scope.$on('farmx.sidenav.selected', function(event, value) {
            _update(value);
          });

          $scope.$on('farmx.map.selected', function(event, value) {
            _update(value);
          });

          $scope.$on('farmx.satellite.selected', function(event, value) {
            var controlWidget = $('.leaflet-slide-control');
            controlWidget.empty();

            if (value.value !== undefined && value.value !== null) {
              var sliderControl = angular.element('<md-slider ng-model="ctrl.opacity" min="0" max="100" aria-label="opacity" id="opacity-slider" class="md-accent" md-vertical="" md-range=""></md-slider>');

              controlWidget.append(sliderControl);
              $compile(sliderControl)($scope);
            }
          });
        }

        function _update(value) {
          if (value.type === "Block" || value.type === "Ranch") {
            $('.leaflet-slide-control').css('display','block');
          } else {
            $('.leaflet-slide-control').css('display','none');
          }
        }

        function onClick(map, event) {
        }

        return {
          control: new Control(),
          beforeRender: beforeRender,
          afterRender: afterRender,
          onClick: onClick
        };
      }
    });
  }(angular));
